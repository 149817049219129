<div class="response-cutoff-container">
  <header class="response-cutoff-header">
    <p class="response-cutoff-title">Are you sure you want to apply this contract?</p>
    <button mat-icon-button mat-dialog-close class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <hr class="header-bottom" />
  <p class="response-cutoff-message">Response Cutoff</p>
  <form [formGroup]="cutoffForm">
    <mat-radio-group formControlName="selectedOption">
      <div class="input-container input-container--period">
        <mat-radio-button value="VALIDITY_PERIOD"></mat-radio-button>
        <div>
          <label for="from">From</label>
          <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <input
              matInput
              [owlDateTime]="dt1"
              [owlDateTimeTrigger]="dt1"
              formControlName="fromDate"
              placeholder="DD/MM/YY HH:MM" />
            <mat-icon matSuffix [owlDateTimeTrigger]="dt1">calendar_month</mat-icon>
            <owl-date-time #dt1></owl-date-time>
          </mat-form-field>
        </div>
        <div>
          <label for="to">To</label>
          <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <input
              matInput
              [owlDateTime]="dt2"
              [owlDateTimeTrigger]="dt2"
              formControlName="toDate"
              [min]="cutoffForm.get('fromDate')?.value"
              placeholder="DD/MM/YY HH:MM" />
            <mat-icon matSuffix [owlDateTimeTrigger]="dt2">calendar_month</mat-icon>
            <owl-date-time #dt2></owl-date-time>
          </mat-form-field>
        </div>
      </div>
      <div class="input-container input-container--duration">
        <mat-radio-button value="DURATION"></mat-radio-button>
        <label for="duration" class="duration-label">Duration</label>
        <div class="invoice-card__amount-section-input">
          <mat-form-field appearance="outline" id="invoice-amount" name="invoice-amount" subscriptSizing="dynamic">
            <input matInput formControlName="duration" type="number" />
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="invoice-card__amount-section-input-currency"
            subscriptSizing="dynamic">
            <mat-select formControlName="durationType">
              <mat-option value="HOURS">Hours</mat-option>
              <mat-option value="DAYS">Days</mat-option>
              <mat-option value="WEEKS">Weeks</mat-option>
              <mat-option value="MONTHS">Months</mat-option>
              <mat-option value="YEARS">Years</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-radio-group>
  </form>
  <div class="response-cutoff-actions">
    <button mat-stroked-button (click)="dialogRef.close()">Cancel</button>
    <button color="primary" mat-flat-button (click)="submit()" [disabled]="!cutoffForm.valid">Confirm</button>
  </div>
</div>
