import { JobContract } from 'app/modules/contract-management/models/job-contract.model';

export interface JobContractViewStateModel {
  jobContract: JobContract;
  loading: boolean;
  error: string | null;
  pdfReadyToDownload: boolean;
}

export const initialJobContractViewState: JobContractViewStateModel = {
  jobContract: null,
  loading: false,
  error: null,
  pdfReadyToDownload: false,
};
