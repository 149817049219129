<div class="uploaded-document">
  <div class="uploaded-document-details">
    <div class="uploaded-document-left">
      <div class="uploaded-document-icon">
        <img ngSrc="../../../../assets/icons/file-icon.svg" alt="" height="36" width="36" />
      </div>
      <div class="text-details">
        <p class="uploaded-document-name">{{ file.fileName }}</p>
        <p class="uploaded-date">{{ file.uploadedDate | date: 'dd MMM yyyy, hh:mm a' }}</p>
        <p class="uploaded-user"><b>Uploaded by: </b> {{ file.uploadedByName }}</p>
      </div>
    </div>
    <div class="custom-name-input">
      <input type="text" [(ngModel)]="file.customFileName" placeholder="Custom Name" />
    </div>
    <div class="uploaded-document-right">
      @if (!saveFileCallback) {
        <p>Active Version</p>
        <mat-slide-toggle (change)="toggleActive()"> </mat-slide-toggle>
      } @else {
        <button mat-flat-button color="primary" size="small" (click)="onSaveButtonClicked($event)">Save</button>
      }
      <button mat-icon-button (click)="openDocumentPreviewDialog(file)" class="preview-button" color="primary">
        <mat-icon>visibility</mat-icon>
      </button>
    </div>
  </div>
  <p *ngIf="file.isActive" class="alert-message">
    Alert! This is a red long alert about something that is very important and you should take action on it.
  </p>
</div>
