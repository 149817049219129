import { PageInfo } from '@shared/models/paginated-response.model';
import { gql } from 'apollo-angular';
import { LongTermRatesContract } from '../models/long-term-contract.model';
import { LongTermRatesContractCategory } from '../types/long-term-contract-category.type';
import { LongTermRatesContractStatus } from '../types/long-term-rates-contract-status.type';
import { LongTermRatesContractType } from '../types/long-term-rates-contract-type.type';

export const FIND_SALES_LONG_TERM_RATES_CONTRACTS = gql`
  query FindSalesLongTermRatesContracts(
    $offset: Int!
    $limit: Int!
    $longTermContractFilterInput: LongTermRatesContractFilterInput!
  ) {
    findSalesLongTermRatesContracts(
      offset: $offset
      limit: $limit
      longTermContractFilterInput: $longTermContractFilterInput
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageSize
        total
      }
      items {
        _id
        contractRef
        contractType
        createdAt
        createdBy
        onBehalfOfSomeOneElse
        metaData {
          data
          metaDataType
          serviceMetaData {
            serviceId
          }
        }
        parties {
          buyer {
            companyReg
            jobFileInfo {
              createdAt
              customerReferenceNo
              initiatorName
              jobGroupId
              jobGroupName
              jobRefId
              title
              workflowId
              workflowName
            }
            logo
            managedByOrgId
            onBehalfOf
            orgName
            partyId
            partyName
            partyType
            pdfFileInfo {
              contentType
              heading
              id
              name
              originalFileName
              version
            }
            principalContact {
              email
              firstName
              lastName
              mobile
            }
            registeredAddress {
              addressLine
              city
              country
              state
            }
            tin
            vat
          }
          fourPL {
            companyReg
            jobFileInfo {
              createdAt
              customerReferenceNo
              initiatorName
              jobGroupId
              jobGroupName
              jobRefId
              title
              workflowId
              workflowName
            }
            logo
            managedByOrgId
            onBehalfOf
            orgName
            partyId
            partyName
            partyType
            pdfFileInfo {
              contentType
              heading
              id
              name
              originalFileName
              version
            }
            principalContact {
              email
              firstName
              lastName
              mobile
            }
            registeredAddress {
              addressLine
              city
              country
              state
            }
            tin
            vat
          }
          others {
            companyReg
            jobFileInfo {
              createdAt
              customerReferenceNo
              initiatorName
              jobGroupId
              jobGroupName
              jobRefId
              title
              workflowId
              workflowName
            }
            logo
            managedByOrgId
            onBehalfOf
            orgName
            partyId
            partyName
            partyType
            pdfFileInfo {
              contentType
              heading
              id
              name
              originalFileName
              version
            }
            principalContact {
              email
              firstName
              lastName
              mobile
            }
            registeredAddress {
              addressLine
              city
              country
              state
            }
            tin
            vat
          }
          seller {
            companyReg
            jobFileInfo {
              createdAt
              customerReferenceNo
              initiatorName
              jobGroupId
              jobGroupName
              jobRefId
              title
              workflowId
              workflowName
            }
            logo
            managedByOrgId
            onBehalfOf
            orgName
            partyId
            partyName
            partyType
            pdfFileInfo {
              contentType
              heading
              id
              name
              originalFileName
              version
            }
            principalContact {
              email
              firstName
              lastName
              mobile
            }
            registeredAddress {
              addressLine
              city
              country
              state
            }
            tin
            vat
          }
        }
        ratesData {
          data
          metaDataType
          rateCards {
            _id
          }
        }
        ratesValidityPeriod {
          effectiveDate
          from
          to
        }
        status
        temporalRunId
        temporalWorkflowId
        tenderId
        tenderType
        updatedAt
        updatedBy
        usage {
          unlimited
          usageCount {
            appliedCount
            usableCount
          }
        }
      }
    }
  }
`;

export interface FindSalesLongTermRatesContractsQueryResponse {
  findSalesLongTermRatesContracts: {
    pageInfo: PageInfo;
    items: LongTermRatesContract[];
  };
}

export interface FindSalesLongTermRatesContractsQueryVariables {
  offset: number;
  limit: number;
  longTermContractFilterInput: LongTermRatesContractFilterInput;
}

export interface LongTermRatesContractFilterInput {
  orgId?: string;
  searchKey?: string;
  filterBy?: string;
  from?: string;
  to?: string;
  productType?: string;
  contractType: LongTermRatesContractType;
  contractCategory?: LongTermRatesContractCategory;
  status?: LongTermRatesContractStatus;
}
