<div class="actions-renderer">
  @if (params.enableVersionHistory) {
    <button mat-icon-button (click)="onVersionHistoryButtonClick()" color="primary">
      <mat-icon>history</mat-icon>
    </button>
  }
  <div
    [matTooltip]="params.isDeletable ? params.deleteMessage : params.cannotDeleteMessage"
    matTooltipPosition="above"
    class="delete-btn-container"
    [class.disabled]="!params.isDeletable">
    <button mat-icon-button (click)="onDeleteButtonClick()" class="delete-btn" [disabled]="!params.isDeletable">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
