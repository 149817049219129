import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SetYouAreHere } from '@core/store/app/app.actions';
import { Step, YouAreHere } from '@core/store/app/app.model';
import { AppState } from '@core/store/app/app.state';
import { JobUpdatesState } from '@core/store/job-updates/job-updates.state';
import { Select, Store } from '@ngxs/store';
import { YouAreHereUpdate } from '@shared/models/you-are-here-update.model';
import { JobFile } from 'app/modules/wizard/models/job-file.model';
import { WizardState } from 'app/modules/wizard/store/wizard.state';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'hmt-you-are-here',
  templateUrl: './you-are-here.component.html',
  styleUrls: ['./you-are-here.component.scss'],
})
export class YouAreHereComponent implements OnInit, OnDestroy {
  private cdr = inject(ChangeDetectorRef);

  @Select(AppState.getYouAreHere) youAreHere$: Observable<YouAreHere>;
  @Select(WizardState.getJobFile) jobFile$: Observable<JobFile>;
  @Select(JobUpdatesState.getYouAreHereUpdates) updates$: Observable<YouAreHereUpdate>;

  steps: Step[] = [];
  bar: Step[] = [];
  youAreHere: YouAreHere = null;
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscribeToJobFile();
    this.subscribeToYouAreHere();
    this.updates$.subscribe(update => {
      if (update) {
        this.youAreHere = update.data;
        this.steps = this.youAreHere?.step;
        this.bar = this.steps.slice(0, this.steps.length - 1);
      }
    });
  }

  private subscribeToYouAreHere(): void {
    this.youAreHere$
      .pipe(
        filter(youAreHere => !!youAreHere),
        tap(youAreHere => {
          this.youAreHere = youAreHere;
          this.steps = youAreHere?.step;
          this.bar = this.steps.slice(0, this.steps.length - 1);
          this.cdr.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private subscribeToJobFile(): void {
    this.jobFile$
      .pipe(
        filter(jobFile => !!jobFile),
        takeUntil(this.destroy$),
        tap((jobFile: JobFile) => {
          this.store.dispatch(new SetYouAreHere(jobFile.workflow?.workflowId, jobFile.jobRefId, jobFile.orgId));
        })
      )
      .subscribe();
  }

  navigateToUrl(url: string): void {
    this.router.navigateByUrl(url).then(() => {
      // location.reload();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
