import { gql } from 'apollo-angular';

export const ORDER_CREATE_UI = '801cc7cd-971d-4e6b-b681-3e476a7e3524';
export const DASHBOARD_UI_CONFIG_ID = '47df2d70-0f11-40d7-9964-f6974e17f903';
export const BIDDING_FLOW_JOB_SUMMARY_UI_CONFIG_ID = '5e7e6234-8d2b-4d12-b64a-caf30c35267d';
export const BIDDING_FLOW_JOB_CREATION_CONFIG_ID = '8d0a45b4-4bb0-404d-aad3-bf12ca235b27';
export const LCT_JOB_SUMMARY_UI_CONFIG_ID = '8df65955-8d7e-4869-971f-b154e6ba9a7d';

export const SHIPMENT_MODES_QUERY = `
  query {
    getShipmentModes {
      key
      value
      transhipmentAllowed
    }
  }
`;

export const CREATE_JOB_FILE_MUTATION = gql`
  mutation CreateJobFile($input: CreateJobFileInput!) {
    createJobFile(createJobFileInput: $input) {
      _id
      jobRefId
      operationId
      jobGroupId
      title
      customerReferenceNo
      workflow {
        _id
        name
        workflowId
      }
    }
  }
`;

export const CREATE_JOB_FILE_WITH_TENDER_REQUEST_MUTATION = gql`
  mutation CreateJobFileWithTenderRequest($input: CreateJobFileWithTenderRequestInput!) {
    createJobFileWithTenderRequest(createJobFileWithTenderRequestInput: $input) {
      _id
      jobRefId
      operationId
      jobGroupId
      title
      workflow {
        _id
        name
        workflowId
      }
      parentJobRefId
      tenderId
    }
  }
`;

export const UPDATE_SERVICE_REQUEST_MUTATION = gql`
  mutation UpdateNewServiceRequestStatusById($id: String!, $status: String!) {
    updateNewServiceRequestStatusById(id: $id, status: $status) {
      _id
      taskStatus
    }
  }
`;

export const FETCH_JOB_FILE_QUERY = `
  query FindByJobRefId($jobRefId: String!) {
    findByJobRefId(jobRefId: $jobRefId) {
      _id
      jobRefId
      operationId
      jobGroupId
      jobGroupName
      title
      orgId
      smartened
      onBehalfOf
      onBehalfOfCustomerId
      onBehalfOfContractId
      customerReferenceNo
      tenderId
      longTermContractId
      workflow {
        _id
        name
        workflowId
      }
      parentJobRefId
      referenceDocuments
    }
  }
`;

export const FETCH_JOB_FILE_BY_PARENT_JOB_REF_ID_AND_SERVICE_REQUEST_ID_QUERY = gql`
  query FindJobFileByParentJobRefIdAndServiceRequestId($parentJobRefId: String!, $serviceRequestId: String!) {
    findJobFileByParentJobRefIdAndServiceRequestId(
      parentJobRefId: $parentJobRefId
      serviceRequestId: $serviceRequestId
    ) {
      _id
      jobRefId
      operationId
      jobGroupId
      jobGroupName
      title
      orgId
      smartened
      onBehalfOf
      onBehalfOfCustomerId
      onBehalfOfContractId
      customerReferenceNo
      tenderId
      workflow {
        _id
        name
        workflowId
      }
      parentJobRefId
      referenceDocuments
    }
  }
`;

export const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(createOrderInput: $input)
  }
`;

export const EXTEND_TENDER_MUTATION = gql`
  mutation ExtendTender($tenderId: String!, $extendedToDate: DateTime!) {
    extendTender(tenderId: $tenderId, extendedToDate: $extendedToDate)
  }
`;

export const REJECT_ALL_BIDS_MUTATION = gql`
  mutation rejectTender($tenderId: String!, $reason: String!) {
    rejectTender(tenderId: $tenderId, reason: $reason)
  }
`;

export const FETCH_MY_BIDS_OF_TENDER_MUTATION = gql`
  mutation FetchBidsOfTender($tenderId: String!) {
    fetchMyBidsOfTender(tenderId: $tenderId)
  }
`;

export const REJECT_BID_MUTATION = gql`
  mutation rejectBid($bidId: String!, $reason: String!) {
    rejectBid(bidId: $bidId, reason: $reason)
  }
`;

export const COMPLETE_TENDER_MUTATION = gql`
  mutation completeTender($tenderId: String!) {
    completeTender(tenderId: $tenderId)
  }
`;

export const UPDATE_ORDER_MUTATION = gql`
  mutation UpdateOrder($id: String!, $input: UpdateOrderInput!) {
    updateOrder(id: $id, updateOrderInput: $input) {
      _id
      jobRefId
      orderRefNumber
      parties {
        notifyingParties
        buyerId
        sellerId
        buyer {
          _id
          orgName
        }
        seller {
          _id
          orgName
        }
      }
      keyDates
      shipmentMethod
      shipmentDetails {
        originLocation
        destinationLocation
        cargoPickupLocation
        cargoDropOffLocation
        countryOfDestination
        countryOfOrigin
        partialShipment
        transhipment
        valueOfCargo
        incoterm
        preferredShipmentMode
        cargoPickupCountryCode
        cargoDropOffCountryCode
        paymentMethod
      }
      cargoDetails {
        grossWeight
        netWeight
        chargeableWeight
        specialCargo
        dangerousCargo
        packingList {
          itemRefId
          commodity
          hsCodes
          descriptionOfCargo
          packagingType
          noOfUnits
          dimensionH
          dimensionW
          dimensionL
          weightPerUnit
          grossWeight
          netWeight
          chargeableWeight
          volume
          dangerousCargo
          specialRequirements
        }
      }
      loadDetails {
        containerType
        noOfLoads
        volumeWeight
      }
      shippingDocuments {
        category
        documentDetails {
          customFileName
          fileId
          fileName
          fileType
          isActive
          isUploaded
          uploadedDate
          orgUserId
          key
          uploadedBy
          uploadedByName
        }
      }
    }
  }
`;

export const FETCH_ALL_ORDERS_QUERY = gql`
  query FindOrdersByJobRefId($jobRefId: String!) {
    findOrdersByJobRefIdFromReferenceDocuments(jobRefId: $jobRefId) {
      _id
      jobRefId
      orderRefNumber
      assigned
      parties {
        notifyingParties
        buyerId
        sellerId
        buyer {
          _id
          orgName
          countryCode
          logo
        }
        seller {
          _id
          orgName
          countryCode
          logo
        }
      }
      keyDates
      shipmentMethod
      shipmentDetails {
        originLocation
        destinationLocation
        cargoPickupLocation
        cargoDropOffLocation
        countryOfDestination
        countryOfOrigin
        partialShipment
        transhipment
        valueOfCargo
        incoterm
        preferredShipmentMode
        cargoPickupCountryCode
        cargoDropOffCountryCode
        paymentMethod
      }
      cargoDetails {
        grossWeight
        netWeight
        chargeableWeight
        specialCargo
        dangerousCargo
        packingList {
          itemRefId
          commodity
          hsCodes
          descriptionOfCargo
          packagingType
          noOfUnits
          dimensionH
          dimensionW
          dimensionL
          weightPerUnit
          grossWeight
          netWeight
          chargeableWeight
          volume
          dangerousCargo
          specialRequirements
        }
      }
      loadDetails {
        containerType
        noOfLoads
        volumeWeight
      }
      shippingDocuments {
        category
        documentDetails {
          customFileName
          fileId
          fileName
          fileType
          isActive
          isUploaded
          uploadedDate
          orgUserId
          key
          uploadedBy
          uploadedByName
        }
      }
    }
  }
`;

export const FETCH_ALL_ORDERS_BY_TENDER_ID_QUERY = gql`
  query FindOrdersByTenderId($tenderId: String!) {
    findOrdersByTenderId(tenderId: $tenderId) {
      _id
      jobRefId
      orderRefNumber
      assigned
      parties {
        notifyingParties
        buyerId
        sellerId
        buyer {
          _id
          orgName
          countryCode
          logo
        }
        seller {
          _id
          orgName
          countryCode
          logo
        }
      }
      keyDates
      shipmentMethod
      shipmentDetails {
        originLocation
        destinationLocation
        cargoPickupLocation
        cargoDropOffLocation
        countryOfDestination
        countryOfOrigin
        partialShipment
        transhipment
        valueOfCargo
        incoterm
        preferredShipmentMode
        cargoPickupCountryCode
        cargoDropOffCountryCode
        paymentMethod
      }
      cargoDetails {
        grossWeight
        netWeight
        chargeableWeight
        specialCargo
        dangerousCargo
        packingList {
          itemRefId
          commodity
          hsCodes
          descriptionOfCargo
          packagingType
          noOfUnits
          dimensionH
          dimensionW
          dimensionL
          weightPerUnit
          grossWeight
          netWeight
          chargeableWeight
          volume
          dangerousCargo
          specialRequirements
        }
      }
      loadDetails {
        containerType
        noOfLoads
        volumeWeight
      }
      shippingDocuments {
        category
        documentDetails {
          customFileName
          fileId
          fileName
          fileType
          isActive
          isUploaded
          uploadedDate
          orgUserId
          key
          uploadedBy
          uploadedByName
        }
      }
    }
  }
`;

export const FETCH_SPOT_BID_BY_ID_QUERY = gql`
  query FindBidById($bidId: String!) {
    findBidById(bidId: $bidId) {
      _id
      orgId
      jobRefId
      tenderId
      orgName
      bidMetaData {
        bidName
        accepted
        favorite
        shipmentsData {
          _id
          shipmentId
          voteMetaData {
            noOfVotes
            votes {
              votedBy {
                _id
                orgId
                firstName
                lastName
              }
              voted
            }
          }
          serviceMetaData {
            ratesSummary
            rateCard {
              rateCardData {
                currencyCode
              }
            }
          }
        }
      }
    }
  }
`;

export const FETCH_LONG_TERM_BID_BY_ID_QUERY = gql`
  query FindBidById($bidId: String!) {
    findBidById(bidId: $bidId) {
      _id
      bidMetaData {
        bidName
        longTermContractMetaData {
          serviceMetaData {
            serviceId
            locationPairs {
              _id
              currencyCode
              vesselsScheduleReferenceGroup {
                shipmentMode
                carrierReference {
                  carrierScheduleData(carrierType: "VESSEL-SCHEDULE")
                }
              }
              additionalCharges {
                description
                amount
              }
              flightScheduleReferenceGroup {
                shipmentMode
                carrierReference {
                  carrierScheduleData(carrierType: "FLIGHT-SCHEDULE")
                }
              }
              destinationLocation {
                countryId
                countryName
                locationId
                locationName
                locationType
              }
              favorite
              originLocation {
                countryId
                countryName
                locationId
                locationName
                locationType
              }
              shipmentMethodsData
              transhipmentAllowed
              voteMetaData {
                noOfVotes
                votes {
                  voteId
                  voted
                  votedBy {
                    _id
                    firstName
                    lastName
                    orgId
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const FETCH_LONG_TERM_TENDER_SUMMARIES_QUERY = gql`
  query FindTendersByFilter($offset: Int!, $limit: Int!, $tenderFilterInput: TenderFilterInput!) {
    findTendersByFilter(offset: $offset, limit: $limit, tenderFilterInput: $tenderFilterInput) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageSize
        total
      }
      items {
        _id
        jobRefId
        tenderType
        tenderStatus
        name
        createdAt
      }
    }
  }
`;

export const FETCH_SPOT_BIDS_BY_TENDER_ID_QUERY = gql`
  query FindPaginatedBidsByTenderId(
    $tenderId: String!
    $favorite: Boolean
    $accepted: Boolean!
    $offset: Int!
    $limit: Int!
    $sortOption: String
    $searchText: String
    $orgId: String
  ) {
    findPaginatedBidsByTenderId(
      tenderId: $tenderId
      favorite: $favorite
      accepted: $accepted
      offset: $offset
      limit: $limit
      sortOption: $sortOption
      searchText: $searchText
      orgId: $orgId
    ) {
      items {
        _id
        orgId
        jobRefId
        tenderId
        orgName
        createdAt
        bidRefNumber
        status
        bidMetaData {
          bidName
          accepted
          favorite
          rejectedBidMetaData {
            rejectionReason
            rejectedBy {
              _id
              firstName
              lastName
            }
          }
          voteMetaData {
            noOfVotes
            votes {
              votedBy {
                _id
                orgId
                firstName
                lastName
              }
              voted
            }
          }
          shipmentsData {
            shipmentId
            serviceMetaData {
              ratesSummary
              rateCard {
                rateCardData {
                  currencyCode
                }
              }
            }
          }
        }
      }
      pageInfo {
        total
        pageSize
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const FETCH_LONG_TERM_BIDS_BY_TENDER_ID_QUERY = gql`
  query FindPaginatedBidsByTenderId(
    $tenderId: String!
    $favorite: Boolean
    $accepted: Boolean!
    $offset: Int!
    $limit: Int!
    $sortOption: String
    $searchText: String
    $orgId: String
  ) {
    findPaginatedBidsByTenderId(
      tenderId: $tenderId
      favorite: $favorite
      accepted: $accepted
      offset: $offset
      limit: $limit
      sortOption: $sortOption
      searchText: $searchText
      orgId: $orgId
    ) {
      items {
        _id
        orgId
        tenderId
        orgName
        createdAt
        bidRefNumber
        status
        bidMetaData {
          bidName
          accepted
          favorite
          rejectedBidMetaData {
            rejectionReason
            rejectedBy {
              _id
              firstName
              lastName
            }
          }
          voteMetaData {
            noOfVotes
            votes {
              votedBy {
                _id
                orgId
                firstName
                lastName
              }
              voted
            }
          }
        }
      }
      pageInfo {
        total
        pageSize
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const GET_SPOT_TENDER_BY_ID = gql`
  query findTenderById($tenderId: String!) {
    findTenderById(tenderId: $tenderId) {
      _id
      name
      tenderRefNumber
      tenderType
      tenderStatus
      rejectedInfo {
        data
        reason
      }
      terminatedInfo {
        data
      }
      tenderTerm {
        data {
          revealBidders
          revealAmount
          decisionMaker
          validity {
            validityType
            timeSpanType
            timeSpan
            from
            to
          }
        }
      }
      tenderCommittee {
        committeeLead {
          _id
          firstName
          lastName
          orgId
          onBehalfOfOrgUser
        }
        committeeReviewers {
          _id
          firstName
          lastName
          onBehalfOfOrgUser
          orgId
        }
        financialExperts {
          _id
          firstName
          lastName
          onBehalfOfOrgUser
          orgId
        }
        logisticsExperts {
          _id
          firstName
          lastName
          onBehalfOfOrgUser
          orgId
        }
      }
      paymentTerm {
        paymentMethodId
        option
        text
      }
      smartened
      tenderMetaData {
        unSmartenedMetadata {
          serviceIds
        }
      }
    }
  }
`;

export const GET_LONG_TERM_TENDER_BY_ID = gql`
  query findTenderById($tenderId: String!) {
    findTenderById(tenderId: $tenderId) {
      _id
      name
      tenderRefNumber
      tenderType
      tenderStatus
      rejectedInfo {
        data
        reason
      }
      terminatedInfo {
        data
      }
      tenderTerm {
        data {
          revealBidders
          revealAmount
          decisionMaker
          validity {
            validityType
            timeSpanType
            timeSpan
            from
            to
          }
        }
      }
      tenderCommittee {
        committeeLead {
          _id
          firstName
          lastName
          orgId
          onBehalfOfOrgUser
        }
        committeeReviewers {
          _id
          firstName
          lastName
          onBehalfOfOrgUser
          orgId
        }
        financialExperts {
          _id
          firstName
          lastName
          onBehalfOfOrgUser
          orgId
        }
        logisticsExperts {
          _id
          firstName
          lastName
          onBehalfOfOrgUser
          orgId
        }
      }
      paymentTerm {
        paymentMethodId
        option
        text
      }
      smartened
      tenderMetaData {
        unSmartenedMetadata {
          serviceIds
        }
        longTermContractMetaData {
          contractName
          currency
          onBehalfOf
          onBehalfOfContractId
          onBehalfOfCustomerId
          serviceMetaData {
            serviceId
            locationPairs {
              _id
              destinationLocation {
                countryId
                countryName
                locationId
                locationName
                locationType
              }
              originLocation {
                countryId
                countryName
                locationId
                locationName
                locationType
              }
              shipmentMethodsData
              transhipmentAllowed
            }
          }
          usage {
            unlimited
            usageCount {
              appliedCount
              remainingCount
              usableCount
            }
          }
          rateValidityPeriod {
            effectiveDate
            from
            to
          }
        }
      }
    }
  }
`;

export const GET_TOTAL_BIDS_BY_TENDER_ID_QUERY = gql`
  query Query($tenderId: String!) {
    getTotalBidsByTenderId(tenderId: $tenderId)
  }
`;

export const FETCH_BIDS_BY_JOB_REF_ID_QUERY = gql`
  query FindBidsByJobRefId($jobRefId: String!) {
    findBidsByJobRefId(jobRefId: $jobRefId) {
      _id
      orgId
      jobRefId
      tenderId
      orgName
      createdAt
      bidMetaData {
        bidName
        accepted
        favorite
        voteMetaData {
          noOfVotes
        }
        shipmentsData {
          shipmentId
          serviceMetaData {
            ratesSummary
            rateCard {
              rateCardData {
                currencyCode
              }
            }
          }
        }
      }
    }
  }
`;

export const MARK_BID_AS_FAVORITE_MUTATION = gql`
  mutation MarkBidAsFavorite($bidId: String!, $metaData: JSONObject!) {
    markBidAsFavorite(bidId: $bidId, metaData: $metaData)
  }
`;

export const WITHDRAW_BID_MUTATION = gql`
  mutation WithdrawBid($bidId: String!) {
    withdrawBid(bidId: $bidId)
  }
`;

export const ACCEPT_BID_OPTION_MUTATION = gql`
  mutation AcceptBidOption($bidId: String!) {
    acceptBidOption(bidId: $bidId)
  }
`;

export const VOTE_BID_OPTION_MUTATION = gql`
  mutation voteForBidById($bidId: String!, $metaData: JSONObject!) {
    voteForBidById(bidId: $bidId, metaData: $metaData)
  }
`;

export const CREATE_SHIPMENT_MUTATION = gql`
  mutation CreateShipment($input: CreateShipmentInput!) {
    createShipment(createShipmentInput: $input)
  }
`;

export const FETCH_ALL_SHIPMENTS_QUERY = gql`
  query FindShipmentsByJobRefId($jobRefId: String!) {
    findShipmentsByJobRefIdFromReferenceDocuments(jobRefId: $jobRefId) {
      _id
      jobRefId
      shipmentRefNumber
      orders {
        _id
        jobRefId
        orderRefNumber
        assigned
        parties {
          notifyingParties
          buyerId
          sellerId
          buyer {
            _id
            orgName
            countryCode
            logo
          }
          seller {
            _id
            orgName
            countryCode
            logo
          }
        }
        keyDates
        shipmentMethod
        shipmentDetails {
          originLocation
          destinationLocation
          cargoPickupLocation
          cargoDropOffLocation
          countryOfDestination
          countryOfOrigin
          partialShipment
          transhipment
          valueOfCargo
          incoterm
          preferredShipmentMode
          cargoPickupCountryCode
          cargoDropOffCountryCode
          paymentMethod
        }
        cargoDetails {
          grossWeight
          netWeight
          chargeableWeight
          specialCargo
          dangerousCargo
          packingList {
            itemRefId
            commodity
            hsCodes
            descriptionOfCargo
            packagingType
            noOfUnits
            dimensionH
            dimensionW
            dimensionL
            weightPerUnit
            grossWeight
            netWeight
            chargeableWeight
            volume
            dangerousCargo
            specialRequirements
          }
        }
        loadDetails {
          containerType
          noOfLoads
          volumeWeight
        }
        shippingDocuments {
          category
          documentDetails {
            customFileName
            fileId
            fileName
            fileType
            isActive
            isUploaded
            uploadedDate
            orgUserId
            key
            uploadedBy
            uploadedByName
          }
        }
      }
      serviceOptions {
        _id
        shipmentMode
        servicesTenderStatus
        services {
          tenderStatus
          serviceId
          serviceName
          plannedServiceId
          serviceUIId
        }
      }
    }
  }
`;

export const FETCH_SERVICE_CARD_QUERY = gql`
  query FindServiceCardByShipmentIdAndServiceOptionId($id: String!, $shipmentId: String!, $serviceOptionId: String!) {
    findServiceCardByShipmentIdAndServiceOptionId(id: $id, shipmentId: $shipmentId, serviceOptionId: $serviceOptionId) {
      serviceId
      shipmentId
      plannedServiceId
      serviceOptionId
      orderShippingDocuments
      transhipment
      partialShipment
      order
      longTermContractId
      jobFile {
        jobRefId
        orgId
      }
      activities {
        _id
        activityName
        activityHeader
        activityId
        estimatedDuration
        canAddServiceAfter
        locationType
        locationId
        plannedStartDateTime
        plannedEndDateTime
        locationRelationshipType
        secondaryLocationType
        secondaryLocationId
        secondaryLocationRelationshipType
        workOrders {
          workOrderId
          workOrderName
          durationInMinutes
          required
          optional
          updateRequired
          override
          scopes
        }
      }
      routingDetails {
        transitTime
        shippingLine
        airline
        vessel
        flight
        voyage
        countryOfOrigin
        loadingLocation
        loadingLocationETA
        loadingLocationETD
        loadingTerminal
        loadingTerminalETA
        loadingTerminalETD
        countryOfDestination
        unloadingLocation
        unloadingLocationETA
        unloadingLocationETD
        unloadingTerminal
        unloadingTerminalETA
        unloadingTerminalETD
        cargoCutoff
        vgmCutoff
        cfsCutoff
        cyCutoff
        transitType
        bookingNumber
      }
      cargoDetails {
        packingList {
          orderRefNumber
          itemRefId
          commodity
          hsCodes
          descriptionOfCargo
          packagingType
          noOfUnits
          dimensionH
          dimensionW
          dimensionL
          weightPerUnit
          grossWeight
          netWeight
          chargeableWeight
          volume
          dangerousCargo
          specialRequirements
        }
        specialCargo
        dangerousCargo
        netWeight
        grossWeight
        chargeableWeight
      }
      shipmentMode
      shipmentMethod
      loadDetails {
        containerType
        noOfLoads
        volumeWeight
      }
      party {
        sellerId
        buyerId
        sellerId
        buyer {
          _id
          orgName
          tickerCode
          companyRegNum
          vatNum
          countryCode
          logo
          principalContact {
            firstName
            lastName
          }
          registeredAddress {
            addressLine
          }
        }
        seller {
          _id
          orgName
          tickerCode
          companyRegNum
          vatNum
          countryCode
          logo
          principalContact {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const ADD_ORDERS_TO_SHIPMENT_MUTATION = gql`
  mutation AddOrdersToShipment($id: String!, $input: AddOrdersToShipmentInput!) {
    addOrdersToShipment(id: $id, addOrdersToShipmentInput: $input)
  }
`;

export const FETCH_ASSIGNED_SERVICES_QUERY = gql`
  query FindServicesConfigByWorkflowIdAndShipmentMode($workflowId: String!, $shipmentMode: ShipmentModes!) {
    findServicesConfigByWorkflowIdAndShipmentMode(workflowId: $workflowId, shipmentMode: $shipmentMode)
  }
`;

export const CREATE_DEFAULT_SERVICES_MUTATION = gql`
  mutation CreateDefaultServices(
    $orgId: String!
    $jobRefId: String!
    $workflowDefIds: [String!]!
    $shipmentMode: ShipmentModes!
    $shipmentId: String!
  ) {
    createDefaultServices(
      createDefaultServices: {
        orgId: $orgId
        jobRefId: $jobRefId
        workflowDefIds: $workflowDefIds
        shipmentMode: $shipmentMode
        shipmentId: $shipmentId
      }
    )
  }
`;

export const UPDATE_DEFAULT_SERVICES_MUTATION = `
  mutation CreateDefaultServices(
    $orgId: String!
    $jobRefId: String!
    $workflowDefIds: [String!]!
    $shipmentMode: ShipmentModes!
    $shipmentId: String!
    $serviceOptionId: String!
    $previousPlannedServiceId: String!
  ) {
    createDefaultServices(
      createDefaultServices: {
        orgId: $orgId
        jobRefId: $jobRefId
        workflowDefIds: $workflowDefIds
        shipmentMode: $shipmentMode
        shipmentId: $shipmentId
        serviceOptionId: $serviceOptionId
        previousPlannedServiceId: $previousPlannedServiceId
      }
    )
  }
`;

export const UPDATE_SERVICE_CARD_MUTATION = gql`
  mutation UpdateServiceCard($updateServiceCard: UpdateServiceCardInput!) {
    updateServiceCard(updateServiceCard: $updateServiceCard)
  }
`;

export const FETCH_SHIPMENTS_BY_IDS_QUERY = gql`
  query FindShipmentsById($ids: [String!]!) {
    findShipmentsByIds(ids: $ids) {
      _id
      jobRefId
      shipmentRefNumber
      orders {
        _id
        jobRefId
        orderRefNumber
        assigned
        parties {
          notifyingParties
          buyerId
          sellerId
          buyer {
            _id
            orgName
            countryCode
            logo
          }
          seller {
            _id
            orgName
            countryCode
            logo
          }
        }
        keyDates
        shipmentMethod
        shipmentDetails {
          originLocation
          destinationLocation
          cargoPickupLocation
          cargoDropOffLocation
          countryOfDestination
          countryOfOrigin
          partialShipment
          transhipment
          valueOfCargo
          incoterm
          preferredShipmentMode
          cargoPickupCountryCode
          cargoDropOffCountryCode
          paymentMethod
        }
        cargoDetails {
          grossWeight
          netWeight
          chargeableWeight
          specialCargo
          dangerousCargo
          packingList {
            itemRefId
            commodity
            hsCodes
            descriptionOfCargo
            packagingType
            noOfUnits
            dimensionH
            dimensionW
            dimensionL
            weightPerUnit
            grossWeight
            netWeight
            chargeableWeight
            volume
            dangerousCargo
            specialRequirements
          }
        }
        loadDetails {
          containerType
          noOfLoads
          volumeWeight
        }
        shippingDocuments {
          category
          documentDetails {
            customFileName
            fileId
            fileName
            fileType
            isActive
            isUploaded
            uploadedDate
            orgUserId
            key
            uploadedBy
            uploadedByName
          }
        }
      }
      serviceOptions {
        _id
        shipmentMode
        servicesTenderStatus
        services {
          tenderStatus
          serviceId
          serviceName
          plannedServiceId
        }
      }
    }
  }
`;

export const GET_WORKFLOW = gql`
  query GetWorkflow($workflowId: String!) {
    workflow(id: $workflowId) {
      _id
      name
      servicesConfig
      steps {
        id
        schemaId
        next {
          nextStep
          conditionKey
          conditionValue
        }
      }
      workflowId
    }
  }
`;

export const GET_WORKFLOW_SCREEN = gql`
  query GetWorkflowScreen($screenId: String!) {
    findWorkflowScreenById(id: $screenId) {
      _id
      schema {
        widget
        title
        properties
        type
        required
      }
      fieldMapping
      fieldTemplate
      tabMapping
      tabWiseSaveMapping
    }
  }
`;

export const GET_LOCATION_BY_ID_QUERY = `
  query FindLocationById($locationId: String!) {
    findLocationById(locationId: $locationId) {
      _id
      locationName
    }
  }
`;

export const FETCH_SMART_RATE_CARD_BY_TYPE_AND_ORG_ID_QUERY = gql`
  query Query($rateCardType: String!, $orgId: String!) {
    findSmartRateCardByRateCardTypeAndOrgId(rateCardType: $rateCardType, orgId: $orgId) {
      orgId
      rateCardType
      operationId
      contractedPartyId
      contractedParty {
        orgName
      }
    }
  }
`;

export const GET_WORKFLOW_DEFINITIONS_QUERY = gql`
  query Query {
    workFlowDefinitions {
      name
      workflowDefId
    }
  }
`;

export const REMOVE_SHIPMENT_WITH_REFERENCE_MUTATION = gql`
  mutation RemoveShipmentWithReference($shipmentId: String!, $jobRefId: String!) {
    removeShipmentWithReference(shipmentId: $shipmentId, jobRefId: $jobRefId)
  }
`;

export const WORKFLOW_DEFINITION_QUERY = gql`
  query WorkFlowDefinitions {
    workFlowDefinitions {
      workflowDefId
      name
    }
  }
`;

export const GET_SERVICE_CONFIGS_QUERY = `
  query HmtServiceConfigs {
    hmtServiceConfigs {
      _id
      serviceName
    }
  }
`;

export const FETCH_LOCATIONS_QUERY = gql`
  query FindLocationsForWizard($limit: Int!, $offset: Int!, $wizardLocationFilterInput: WizardLocationFilterDto!) {
    findLocationsForWizard(limit: $limit, offset: $offset, wizardLocationFilterInput: $wizardLocationFilterInput) {
      locationName
      _id
    }
  }
`;

export const UPDATE_ACTIVITY_MUTATION = gql`
  mutation UpdateHmtActivity($updateActivityInput: UpdateHmtActivityInput!) {
    updateHmtActivity(updateActivityInput: $updateActivityInput) {
      jobRefId
      serviceId
      activities {
        _id
        activityName
        activityHeader
        activityId
        estimatedDuration
        canAddServiceAfter
        locationType
        locationId
        plannedStartDateTime
        plannedEndDateTime
        locationRelationshipType
        secondaryLocationType
        secondaryLocationId
        secondaryLocationRelationshipType
        workOrders {
          workOrderId
          workOrderName
          durationInMinutes
          required
          optional
          updateRequired
          override
          scopes
        }
      }
    }
  }
`;

export const FIND_WORKFLOW_DEFINITION_BY_WORKFLOW_DEF_ID_QUERY = `
  query FindWorkflowDefinitionByWorkflowDefId($workflowDefId: String!) {
    findWorkflowDefinitionByWorkflowDefId(workflowDefId: $workflowDefId) {
      _id
      additionalServices
      additionalServicesInfo {
        id
        name
      }
    }
  }
`;

export const DELETE_ORDER_TAB_ORDER_MUTATION = gql`
  mutation RemoveOrderWithReference($removeOrderWithReferenceId: String!, $jobRefId: String!) {
    removeOrderWithReference(id: $removeOrderWithReferenceId, jobRefId: $jobRefId)
  }
`;

export const PAGINATED_ORDERS_WITH_VALIDATION_QUERY = gql`
  query PaginatedOrdersWithValidation(
    $jobRefId: String!
    $shipmentId: String!
    $selectedOrderId: String!
    $offset: Int!
    $limit: Int!
    $filter: String!
  ) {
    paginatedOrdersWithValidation(
      jobRefId: $jobRefId
      shipmentId: $shipmentId
      selectedOrderId: $selectedOrderId
      offset: $offset
      limit: $limit
      filter: $filter
    ) {
      items {
        _id
        jobRefId
        orderRefNumber
        assigned
        parties {
          notifyingParties
          buyerId
          sellerId
          buyer {
            _id
            orgName
            countryCode
            logo
          }
          seller {
            _id
            orgName
            countryCode
            logo
          }
        }
        keyDates
        shipmentMethod
        shipmentDetails {
          originLocation
          destinationLocation
          cargoPickupLocation
          cargoDropOffLocation
          countryOfDestination
          countryOfOrigin
          partialShipment
          transhipment
          valueOfCargo
          incoterm
          preferredShipmentMode
          cargoPickupCountryCode
          cargoDropOffCountryCode
          paymentMethod
        }
        cargoDetails {
          grossWeight
          netWeight
          chargeableWeight
          specialCargo
          dangerousCargo
          packingList {
            itemRefId
            commodity
            hsCodes
            descriptionOfCargo
            packagingType
            noOfUnits
            dimensionH
            dimensionW
            dimensionL
            weightPerUnit
            grossWeight
            netWeight
            chargeableWeight
            volume
            dangerousCargo
            specialRequirements
          }
        }
        loadDetails {
          containerType
          noOfLoads
          volumeWeight
        }
        shippingDocuments {
          category
          documentDetails {
            customFileName
            fileId
            fileName
            fileType
            isActive
            isUploaded
            uploadedDate
            orgUserId
            key
            uploadedBy
            uploadedByName
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageSize
        total
      }
    }
  }
`;

export const UPDATE_JOB_SMARTENED_IN_JOB_FILE_MUTATION = gql`
  mutation UpdateJobSmartenedInJobFile($updateJobSmartenedInJobFile: UpdateJobSmartenedInput!) {
    updateJobSmartenedInJobFile(updateJobSmartenedInJobFile: $updateJobSmartenedInJobFile) {
      smartened
    }
  }
`;

export const GENERATE_DRY_HMT_ACTIVITY_MUTATION = gql`
  mutation GenerateDryHmtActivity($generateDryActivityInput: GenerateDryHmtActivityInput!) {
    generateDryHmtActivity(generateDryActivityInput: $generateDryActivityInput) {
      _id
      activityHeader
      activityId
      activityName
      canAddServiceAfter
      estimatedDuration
      locationCategory
      locationId
      locationRelationshipType
      locationType
      order
      plannedEndDateTime
      plannedStartDateTime
      secondaryLocationId
      secondaryLocationRelationshipType
      secondaryLocationType
      workOrders {
        workOrderId
        workOrderName
        durationInMinutes
        required
        optional
        updateRequired
        override
        scopes
      }
    }
  }
`;

export const ADD_HMT_ACTIVITY_MUTATION = gql`
  mutation AddHmtActivity($addActivityInput: AddHmtActivityInput!) {
    addHmtActivity(addActivityInput: $addActivityInput) {
      _id
      activities {
        _id
        activityName
        activityHeader
        activityId
        estimatedDuration
        canAddServiceAfter
        locationType
        locationId
        plannedStartDateTime
        plannedEndDateTime
        locationRelationshipType
        secondaryLocationType
        secondaryLocationId
        secondaryLocationRelationshipType
        workOrders {
          workOrderId
          workOrderName
          durationInMinutes
          required
          optional
          updateRequired
          override
          scopes
        }
      }
    }
  }
`;

export const REMOVE_HMT_ACTIVITY_MUTATION = gql`
  mutation RemoveHmtActivity($removeHmtActivityInput: RemoveHmtActivityInput!) {
    removeHmtActivity(removeHmtActivityInput: $removeHmtActivityInput) {
      activities {
        _id
        order
        activityId
        activityName
        locationCategory
        activityHeader
        estimatedDuration
        workOrders {
          workOrderId
          workOrderName
          durationInMinutes
          required
          optional
          updateRequired
          override
          scopes
        }
        canAddServiceAfter
        locationType
        locationRelationshipType
        locationId
        secondaryLocationType
        secondaryLocationRelationshipType
        secondaryLocationId
        plannedStartDateTime
        plannedEndDateTime
      }
    }
  }
`;

export const REMOVE_SERVICE_OPTION_MUTATION = gql`
  mutation RemoveServiceOption($shipmentId: String!, $optionId: String!) {
    removeServiceOption(shipmentId: $shipmentId, optionId: $optionId)
  }
`;

export const SEARCH_JOB_FILE_SUMMARIES_QUERY = gql`
  query SearchJobFile($orgId: String!, $jobFileTitle: String!, $offset: Int!, $limit: Int!) {
    searchJobFile(orgId: $orgId, jobFileTitle: $jobFileTitle, offset: $offset, limit: $limit) {
      pageInfo {
        total
        pageSize
        hasPreviousPage
        hasNextPage
      }
      items {
        title
        createdAt
        status
      }
    }
  }
`;

export const GET_PAGINATED_ORDERS_BY_JOB_REF_ID_QUERY = `
  query FindPaginatedOrdersByJobRefIdFromReferenceDocuments(
    $jobRefId: String!
    $filter: String!
    $offset: Int!
    $limit: Int!
  ) {
    findPaginatedOrdersByJobRefIdFromReferenceDocuments(
      jobRefId: $jobRefId
      filter: $filter
      offset: $offset
      limit: $limit
    ) {
      items {
        _id
        jobRefId
        orderRefNumber
        assigned
        parties {
          notifyingParties
          buyerId
          sellerId
          buyer {
            _id
            orgName
            countryCode
            logo
          }
          seller {
            _id
            orgName
            countryCode
            logo
          }
        }
        keyDates
        shipmentMethod
        shipmentDetails {
          originLocation
          destinationLocation
          cargoPickupLocation
          cargoDropOffLocation
          countryOfDestination
          countryOfOrigin
          partialShipment
          transhipment
          valueOfCargo
          incoterm
          preferredShipmentMode
          cargoPickupCountryCode
          cargoDropOffCountryCode
          paymentMethod
        }
        cargoDetails {
          grossWeight
          netWeight
          chargeableWeight
          specialCargo
          dangerousCargo
          packingList {
            itemRefId
            commodity
            hsCodes
            descriptionOfCargo
            packagingType
            noOfUnits
            dimensionH
            dimensionW
            dimensionL
            weightPerUnit
            grossWeight
            netWeight
            chargeableWeight
            volume
            dangerousCargo
            specialRequirements
          }
        }
        loadDetails {
          containerType
          noOfLoads
          volumeWeight
        }
        shippingDocuments {
          category
          documentDetails {
            customFileName
            fileId
            fileName
            fileType
            isActive
            isUploaded
            uploadedDate
            orgUserId
            key
            uploadedBy
            uploadedByName
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageSize
        total
      }
      shipmentOrderCount
    }
  }
`;

export const FIND_PAGINATED_ORDERS_OF_SHIPMENT_QUERY = `
  query FindPaginatedOrdersOfShipment($shipmentId: String!, $filter: String!, $offset: Int!, $limit: Int!) {
    findPaginatedOrdersOfShipment(shipmentId: $shipmentId, filter: $filter, offset: $offset, limit: $limit) {
      items {
        _id
        jobRefId
        orderRefNumber
        assigned
        parties {
          notifyingParties
          buyerId
          sellerId
          buyer {
            _id
            orgName
            countryCode
            logo
          }
          seller {
            _id
            orgName
            countryCode
            logo
          }
        }
        keyDates
        shipmentMethod
        shipmentDetails {
          originLocation
          destinationLocation
          cargoPickupLocation
          cargoDropOffLocation
          countryOfDestination
          countryOfOrigin
          partialShipment
          transhipment
          valueOfCargo
          incoterm
          preferredShipmentMode
          cargoPickupCountryCode
          cargoDropOffCountryCode
          paymentMethod
        }
        cargoDetails {
          grossWeight
          netWeight
          chargeableWeight
          specialCargo
          dangerousCargo
          packingList {
            itemRefId
            commodity
            hsCodes
            descriptionOfCargo
            packagingType
            noOfUnits
            dimensionH
            dimensionW
            dimensionL
            weightPerUnit
            grossWeight
            netWeight
            chargeableWeight
            volume
            dangerousCargo
            specialRequirements
          }
        }
        loadDetails {
          containerType
          noOfLoads
          volumeWeight
        }
        shippingDocuments {
          category
          documentDetails {
            customFileName
            fileId
            fileName
            fileType
            isActive
            isUploaded
            uploadedDate
            orgUserId
            key
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageSize
        total
      }
    }
  }
`;

export const FIND_COUNTRIES_QUERY = gql`
  query GetCountriesByShortCodes($countryCodes: [String!]!) {
    getCountriesByShortCodes(countryCodes: $countryCodes) {
      countryCode
      countryName
      _id
    }
  }
`;

export const FIND_COUNTRIES_BY_NAMES_QUERY = gql`
  query GetCountriesByNames($countryNames: [String!]!) {
    getCountriesByNames(countryNames: $countryNames) {
      _id
      countryCode
      countryName
    }
  }
`;

export const CONFIRM_JOB_FILE_SUMMARY_MUTATION = `
  mutation ConfirmJobFileSummary($jobRefId: String!) {
    confirmJobFileSummary(jobRefId: $jobRefId)
  }
`;

export const REMOVE_ORDER_FROM_SHIPMENT_MUTATION = `mutation RemoveOrderFromShipment($shipmentId: String!, $orderId: String!, $jobRefId: String!) {
  removeOrderFromShipment(shipmentId: $shipmentId, orderId: $orderId, jobRefId: $jobRefId)
}`;

export const DUPLICATE_SERVICE_OPTIONS_MUTATION = `
  mutation DuplicateServiceOption($duplicateServiceOptionInput: DuplicateServiceOptionInput!) {
    duplicateServiceOption(duplicateServiceOptionInput: $duplicateServiceOptionInput)
  }
`;

export const REMOVE_SERVICE_CARD_MUTATION = `
  mutation RemoveServiceCard($removeServiceCardId: String!) {
    removeServiceCard(id: $removeServiceCardId)
  }
`;

export const FIND_TENDERS_BY_JOB_REF_ID_QUERY = `
  query FindTendersByJobRefId($jobRefId: String!) {
    findTendersByJobRefId(jobRefId: $jobRefId) {
      tenderMetaData {
        unSmartenedMetadata {
          serviceIds
        }
      }
    }
  }
`;

export const GET_COUNTRIES_QUERY = `
   { findCountries {_id countryName, countryCode } }
`;

export const GET_PORTS_BY_COUNTRY_AND_SEARCH_KEY_QUERY = `
  query GetLocationsByCountryCodeAndLocationType(
    $countryCode: String!,
    $locationType: String!,
    $searchKey: String!,
    $parentLocationId: String!,
    $offset: Int!,
    $limit: Int!
  ) {
    getLocationsByCountryCodeAndLocationType(
      countryCode: $countryCode,
      locationType: $locationType,
      searchKey: $searchKey,
      parentLocationId: $parentLocationId,
      offset: $offset,
      limit: $limit
    ) {
      items {
        _id
        countryCode
        locationName
        locationType
        withinLocationId
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageSize
        total
      }
    }
  }
`;

export const GET_CONTAINER_TYPES_QUERY = `
query {
  containerTypes {
    name
    _id
  }
}`;

export const GET_PAGINATED_CHILD_ORGANIZATIONS_QUERY = `
  query GetPaginatedNestedChildOrgs($getPaginatedNestedChildOrgsInput: GetPaginatedChildOrganizationsDto!) {
    getPaginatedNestedChildOrgs(getPaginatedNestedChildOrgsInput: $getPaginatedNestedChildOrgsInput) {
      items {
        orgName
        _id
        tickerCode
      }
      pageInfo {
        total
        hasNextPage
        hasPreviousPage
        pageSize
      }
    }
  }
`;

export const GET_ALL_CURRENCIES_QUERY = `
  query {
    getAllCurrencies {
      code
      currency
      shortCode
    }
  }
`;

export const CHECK_SERVICE_VALIDATIONS_STATUS_QUERY = `
  query Query($jobRefId: String!) {
    checkServiceValidationsStatus(jobRefId: $jobRefId)
  }
`;

export const GET_COUNTRY_BY_ID_QUERY = `
  query GetCountryById($countryId: String!) {
    getCountryById(countryId: $countryId) {
      _id
      countryCode
      countryName
    }
  }
`;

export const AGGREGATE_FAVORITE_BIDS_QUERY = gql`
  query AggregateFavoriteBids($tenderId: String!) {
    aggregateFavoriteBids(tenderId: $tenderId)
  }
`;

export const AGGREGATE_LONG_TERM_BIDS_QUERY = gql`
  query Query($tenderId: String!) {
    aggregateLongTermBids(tenderId: $tenderId)
  }
`;
