import { FlightFrequencyType } from 'app/modules/catalog-manager/enums/flight-frequency-type.enum';

export function flightFrequencyToHumanReadable(
  flightFrequency:
    | FlightFrequencyType
    | {
        frequency: FlightFrequencyType;
        noOfDays: number;
      }
) {
  let testedFlightFrequency = flightFrequency;

  if (typeof flightFrequency === 'object' && flightFrequency !== null && flightFrequency !== undefined) {
    testedFlightFrequency = flightFrequency.frequency;
  }

  if (testedFlightFrequency === undefined || testedFlightFrequency === null) {
    return 'Not Yet Set';
  }

  switch (testedFlightFrequency) {
    case FlightFrequencyType.DAILY:
      return 'Daily';
    case FlightFrequencyType.PER_WEEK:
      return 'Per Week';
    case FlightFrequencyType.SPECIFIC_DAYS:
      return 'Specific Days';
    default:
      return 'Unknown';
  }
}

export function getFlightFrequencyFromHumanReadable(humanReadable: 'Daily' | 'Per Week' | 'Specific Days') {
  switch (humanReadable) {
    case 'Daily':
      return FlightFrequencyType.DAILY;
    case 'Per Week':
      return FlightFrequencyType.PER_WEEK;
    case 'Specific Days':
      return FlightFrequencyType.SPECIFIC_DAYS;
    default:
      return FlightFrequencyType.DAILY;
  }
}
