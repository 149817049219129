import { gql } from 'apollo-angular';

export const GET_LOCATION_NAME_BY_ID = gql`
  query GetLocationByNameId($locationId: String!) {
    location: findLocationById(locationId: $locationId) {
      name: locationName
      shortName: shortName
    }
  }
`;

export interface GetLocationNameByIdResponse {
  location: { name: string; shortName: string };
}
