import { Component, signal } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { FlightScheduleTableRow } from 'app/modules/catalog-manager/models/flight-schedule.model';
import { VesselScheduleTableRow } from 'app/modules/catalog-manager/models/vessel-schedule.model';

@Component({
  selector: 'hmt-enhanced-datagrid-routing-renderer',
  templateUrl: './enhanced-datagrid-routing-renderer.component.html',
  styleUrls: ['./enhanced-datagrid-routing-renderer.component.scss'],
})
export class EnhancedDatagridRoutingRendererComponent implements ICellRendererAngularComp {
  private params!: ICellRendererParams<VesselScheduleTableRow | FlightScheduleTableRow, string>;
  value!: string;
  stops = signal<{ id: string; name: string; shortName: string }[]>([]);

  agInit(params: ICellRendererParams<VesselScheduleTableRow | FlightScheduleTableRow, string>): void {
    this.params = params;
    this.setValue(params);
  }

  refresh(params: ICellRendererParams<VesselScheduleTableRow | FlightScheduleTableRow, string>): boolean {
    this.params = params;
    this.setValue(params);
    return true;
  }

  private setValue(params: ICellRendererParams<VesselScheduleTableRow | FlightScheduleTableRow, string>) {
    this.value = params.value;
    this.stops.set(params.data.routingDetails?.stops ?? []);
  }
}
