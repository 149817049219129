import { gql } from 'apollo-angular';
import { AdditionalCharge } from 'app/modules/procurement/models/additional-charges.model';
import { FlightSchedule } from '../models/flight-schedule.model';

export const UPDATE_FLIGHT_SCHEDULE_QUERY = gql`
  mutation UpdateFlightSchedule($updateFlightScheduleInput: UpdateFlightScheduleInput!) {
    updatedFlightSchedule: updateFlightSchedule(updateFlightScheduleInput: $updateFlightScheduleInput) {
      _id
      airLine
      createdAt
      createdBy
      deleted
      firstLegDepartureDays
      firstLegFrequency
      firstLegNoOfDays
      flightCutoff
      flightName
      from
      fromEta
      fromEtd
      orgId
      routingDetails {
        type
        stops
      }
      secondLegArrivalDays
      secondLegFrequency
      secondLegNoOfDays
      to
      toEta
      toEtd
      totalTransitTime
      updatedAt
      updatedBy
      versionHistory {
        _id
        changedField
        createdAt
        createdBy
        newValue
        oldValue
        orgUserId
        updatedAt
        updatedBy
      }
    }
  }
`;

export interface UpdateFlightScheduleResponse {
  updatedFlightSchedule: FlightSchedule;
}

export interface UpdateFlightScheduleInput extends Omit<Partial<FlightSchedule>, 'rateStructure'> {
  rateStructure?: {
    currencyCode: string;
    data: {
      schema: string;
      values: {
        volumeWeightsRates: {
          volumeWeights: {
            title: string;
            unit: string;
            values: number[];
          };
          rates: {
            title: string;
            unit: string;
            values: number[];
          };
        };
      };
    };
    additionalCharges: AdditionalCharge[];
  };
}
