import { Component, OnInit, inject } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Store } from '@ngxs/store';
import { LongTermContractsService } from 'app/modules/contract-management/services/long-term-contracts.service';
import { CurrentTenderState } from 'app/modules/current-tender/store/current-tender/current-tender.state';
import { NewServiceRequest } from 'app/modules/task-feed/models/new-service-request.model';
import { JobFile } from 'app/modules/wizard/models/job-file.model';
import { WizardState } from 'app/modules/wizard/store/wizard.state';
import { Observable, filter, take, tap } from 'rxjs';
import { TenderModel } from '../../models/tender.model';
import { TenderService } from '../../services/tender-service';

@Component({
  selector: 'hmt-selected-payment-term',
  standalone: true,
  imports: [],
  templateUrl: './selected-payment-term.component.html',
  styleUrl: './selected-payment-term.component.scss',
})
export class SelectedPaymentTermComponent extends FieldWrapper implements OnInit {
  store$ = inject(Store);
  longTermContractsService = inject(LongTermContractsService);
  paymentTerm: string = '';
  LONG_TERM_CONTRACT_TYPE = 'longTerm';

  currentTender$: Observable<TenderModel> = this.store$.select(CurrentTenderState.getTender);
  getJobFile$: Observable<JobFile> = this.store$.select(WizardState.getJobFile);

  constructor(private tenderService: TenderService) {
    super();
  }

  ngOnInit(): void {
    if (this.props['contractType'] && this.props['contractType'] === this.LONG_TERM_CONTRACT_TYPE) {
      const serviceRequest: NewServiceRequest = this.formState?.serviceRequest;
      if (serviceRequest && serviceRequest.metadata['longTermContractId']) {
        this.getPaymentTerm(serviceRequest?.metadata['longTermContractId']);
      }
    } else {
      this.getJobFile$
        .pipe(
          filter(jobFile => !!jobFile),
          take(1),
          tap(jobFile => {
            if (jobFile.tenderId) {
              this.tenderService.loadTenderById(jobFile.tenderId).subscribe();
            } else if (jobFile?.longTermContractId) {
              this.getPaymentTerm(jobFile?.longTermContractId);
            }
          })
        )
        .subscribe();
      this.currentTender$.subscribe(tender => {
        if (tender) {
          this.paymentTerm = tender.paymentTerm.text ?? '';
        }
      });
    }
  }

  private getPaymentTerm(longTermContractId: string) {
    if (longTermContractId) {
      this.longTermContractsService
        .findLongTermContractById(longTermContractId)
        .pipe(
          filter(contract => !!contract),
          take(1),
          tap(contract => {
            this.paymentTerm = contract.paymentTerm.text;
          })
        )
        .subscribe();
    }
  }
}
