import { TenderModel } from 'app/modules/procurement/models/tender.model';

export const SET_CURRENT_TENDER = '[Current Tendering] Set Current Tender';

export class SetTender {
  static readonly type = `${SET_CURRENT_TENDER} Set Current Tender`;
  constructor(
    public tender: TenderModel,
    public updateType?: 'jobUpdate' | 'gql' | 'entityUpdate'
  ) {}
}

export class ExtendTender {
  static readonly type = '[Tender] Extend Tender';
  constructor(
    public tenderId: string,
    public extendedToDate: string
  ) {}
}

export class ClearCurrentTender {
  static readonly type = '[Current Tendering] Clear Current Tender';
}

export class SetTenderingPageLoading {
  static readonly type = '[Tendering] Set Page Loading';
  constructor(public loading: boolean) {}
}
