import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { STORAGE_URL_PREFIX } from '@configs/constants';
import { SnackBarNotificationModel } from '../notification-drawer/models/snack-bar.model';

@Component({
  selector: 'hmt-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit {
  displayColorClass: string;
  displayIcon: string;
  showViewBtn: boolean = false;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarNotificationModel,
    private snackBarRef: MatSnackBarRef<NotificationComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {
    const { colorClass, icon } = this.data.type;
    this.displayColorClass = colorClass;
    this.displayIcon = icon;
    this.showViewBtn = !!this.data.url;
  }

  dismiss() {
    this.snackBarRef.dismiss();
  }

  view() {
    if (!this.showViewBtn) return;

    if (this.data.url?.startsWith(STORAGE_URL_PREFIX)) {
      window.open(this.data.url, '_blank');
    } else {
      this.router.navigateByUrl(this.data.url);
    }

    this.snackBarRef.dismiss();
  }

  cancel() {
    this.dismiss();
  }
}
