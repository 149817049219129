<div class="option-editor">
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="routing-type-selector">
    <mat-label>Choose type of routing</mat-label>
    <mat-select [(ngModel)]="routingType">
      @for (option of routingTypeOptions; track option) {
        <mat-option [value]="option">
          {{ getRoutingTypeHumanReadable(option) }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <div class="stops-container">
    @if (for() === 'vessels') {
      @for (stop of stopsFormArray().controls; track stop.value.stop.id; let i = $index) {
        <div class="stop-row">
          <hmt-port-selector
            [form]="stop"
            controlName="stop"
            [label]="$first ? 'From port' : $last ? 'To port' : 'Intermediate stop'" />
          @if (!$first && !$last && routingType() === CarrierRoutingType.TRANSIT) {
            <button mat-icon-button color="warn" (click)="removeStop(i)">
              <mat-icon>remove_circle</mat-icon>
            </button>
          }
        </div>
      }
      @if (routingType() === CarrierRoutingType.TRANSIT) {
        <div class="add-stop">
          <button mat-stroked-button color="primary" (click)="addIntermediateStop()">
            <mat-icon>add</mat-icon>
            Add Intermediate Stop
          </button>
        </div>
      }
    } @else if (for() === 'flights') {
      @for (stop of stopsFormArray().controls; track stop.value.stop.id; let i = $index) {
        <div class="stop-row">
          <hmt-airport-selector
            [form]="stop"
            controlName="stop"
            [label]="$first ? 'From airport' : $last ? 'To airport' : 'Intermediate stop'" />
          @if (!$first && !$last && routingType() === CarrierRoutingType.TRANSIT) {
            <button mat-icon-button color="warn" (click)="removeStop(i)">
              <mat-icon>remove_circle</mat-icon>
            </button>
          }
        </div>
      }
      @if (routingType() === CarrierRoutingType.TRANSIT) {
        <div class="add-stop">
          <button mat-stroked-button color="primary" (click)="addIntermediateStop()">
            <mat-icon>add</mat-icon>
            Add Intermediate Stop
          </button>
        </div>
      }
    }
  </div>
  <button (click)="onSave()" [disabled]="!isSaveButtonEnabled()" class="save-button" mat-flat-button color="primary">
    Save
  </button>
</div>
