import { Injectable } from '@angular/core';
import { FileHandlerService } from '@shared/services/file-handler.service';
import { Apollo, gql } from 'apollo-angular';
import { ContractParty, JobContract } from 'app/modules/contract-management/models/job-contract.model';
import { Organization } from 'app/modules/organizations-manager/models/organization.model';
import { JobFile } from 'app/modules/wizard/models/job-file.model';
import { catchError, filter, map, Observable, of, take } from 'rxjs';
import { ContractedParties } from '../components/models/contracted-parties.model';

@Injectable({ providedIn: 'root' })
export class JobContractService {
  constructor(
    private apollo: Apollo,
    private fileHandlerService: FileHandlerService
  ) {}

  getPdfFileId(contract: JobContract, currentOrgId: string): string {
    let party: ContractParty = null;
    if (contract.parties?.seller?.partyId === currentOrgId) {
      party = contract.parties?.seller;
    } else if (
      contract.parties?.buyer?.partyId === currentOrgId ||
      contract.parties?.fourPL?.partyId === currentOrgId
    ) {
      party = contract.parties?.buyer;
    }

    if (!party || !party.pdfFileInfo || party.pdfFileInfo.length == 0) {
      return null;
    }

    // latest version
    return party.pdfFileInfo[party.pdfFileInfo.length - 1].id;
  }

  getLogo(logoId: string): Observable<string> {
    if (!logoId) {
      return of('./assets/icons/no-image.png');
    }
    return this.fileHandlerService.fetchFile(logoId).pipe(
      map(f => f.url),
      catchError(error => {
        console.error('Failed to load logo:', error);
        return of('assets/placeholders/image_placeholder.svg');
      })
    );
  }

  getPaymentTerms(tenderId: string) {
    const GET_PAYMENT_TERMS = gql`
      query findTenderById($tenderId: String!) {
        findTenderById(tenderId: $tenderId) {
          tenderTerm {
            data {
              revealBidders
              revealAmount
              decisionMaker
              validity {
                validityType
                timeSpanType
                timeSpan
                from
                to
              }
              parties {
                id
                bisStatus
              }
            }
          }
          paymentTerm {
            data
          }
        }
      }
    `;
    const variables = {
      tenderId,
    };
    return this.apollo
      .query<{ findTenderById: any }>({
        query: GET_PAYMENT_TERMS,
        variables: variables,
      })
      .pipe(
        map(result => {
          return result.data.findTenderById.paymentTerm.data;
        }),
        catchError(error => {
          console.error(error);
          throw error;
        })
      );
  }

  getJobFileByJobRefIds(jobRefIds: string[]) {
    const GET_JOB_FILE_BY_JOB_REF_IDS = gql`
      query findJobFilesByJobRefIds($jobRefIds: [String!]!) {
        findJobFilesByJobRefIds(jobRefIds: $jobRefIds) {
          _id
          jobRefId
          title
          jobGroupName
          workflowId
          customerReferenceNo
          status
          customerName
          initiatorName
          workflowName
          createdAt
          createdBy
          initiatorName
        }
      }
    `;
    const variables = {
      jobRefIds,
    };
    return this.apollo
      .query<{ findJobFilesByJobRefIds: JobFile[] }>({
        query: GET_JOB_FILE_BY_JOB_REF_IDS,
        variables: variables,
      })
      .pipe(
        map(result => result.data.findJobFilesByJobRefIds),
        catchError(error => {
          console.error(error);
          throw error;
        })
      );
  }

  getOrgDetailsByIds(ids: string[]) {
    const GET_ORG_DETAILS_BY_IDS = gql`
      query getOrganizationsByIds($orgIds: [String!]!) {
        getOrganizationsByIds(orgIds: $orgIds) {
          _id
          orgName
          logo
          companyRegNum
          tinNum
          vatNum
          verticals
          registeredAddress {
            addressLine
            city
            country
            zipCode
          }
          principalContact {
            firstName
            lastName
            email
            mobile
          }
        }
      }
    `;
    const variables = {
      orgIds: ids,
    };
    return this.apollo
      .query<{ getOrganizationsByIds: Organization[] }>({
        query: GET_ORG_DETAILS_BY_IDS,
        variables: variables,
      })
      .pipe(
        map(result => {
          if (!result.data.getOrganizationsByIds) {
            console.error('Error loading organizations by ids', result);
            return [];
          } else {
            return result.data.getOrganizationsByIds;
          }
        }),
        catchError(error => {
          console.error(error);
          throw error;
        })
      );
  }

  getPlannedServicesByIds(ids: string[]) {
    const GET_PLANNED_SERVICES_BY_IDS = gql`
      query findPlannedServicesByIds($ids: [String!]!) {
        findPlannedServicesByIds(ids: $ids) {
          _id
          serviceName
          transhipment
          activities {
            activityName
            workOrders {
              workOrderName
            }
          }
          routingDetails {
            transitTime
            shippingLine
            airline
            vessel
            flight
            voyage
            countryOfOrigin
            loadingPort
            loadingPortETA
            loadingPortETD
            loadingTerminal
            loadingTerminalETA
            loadingTerminalETD
            loadingAirport
            loadingAirportETA
            loadingAirportETD
            countryOfDestination
            unloadingPort
            unloadingPortETA
            unloadingPortETD
            unloadingTerminal
            unloadingTerminalETA
            unloadingTerminalETD
            unloadingAirport
            unloadingAirportETA
            unloadingAirportETD
            cargoCutoff
            vgmCutoff
            cfsCutoff
            cyCutoff
            transitCountry
            unloadingPortTransit
            loadingPortTransit
            unloadingAirportTransit
            loadingAirportTransit
            unloadingTerminalTransit
            loadingTerminalTransit
            bookingNumber
          }
        }
      }
    `;
    const variables = {
      ids,
    };
    return this.apollo
      .query<any>({
        query: GET_PLANNED_SERVICES_BY_IDS,
        variables: variables,
      })
      .pipe(
        map(result => {
          return result.data.findPlannedServicesByIds;
        }),
        catchError(error => {
          console.error(error);
          throw error;
        })
      );
  }

  getJobContractById(jobContractId: string) {
    const GET_JOB_CONTRACT_BY_ID = gql`
      query getSmartContractById($id: String!) {
        getSmartContractById(id: $id) {
          _id
          contractRef
          createdAt
          status
          tenderId
          parties {
            buyer {
              partyId
              orgName
              partyType
              companyReg
              vat
              tin
              logo
              principalContact {
                email
                mobile
              }
              registeredAddress {
                addressLine
                city
                country
                state
              }
              pdfFileInfo {
                id
              }
              jobFileInfo {
                jobRefId
                title
                jobGroupId
                jobGroupName
                customerReferenceNo
                createdAt
                workflowId
                workflowName
                initiatorName
              }
            }
            seller {
              partyId
              orgName
              partyType
              companyReg
              vat
              tin
              logo
              principalContact {
                email
                mobile
              }
              registeredAddress {
                addressLine
                city
                country
                state
              }
              pdfFileInfo {
                id
              }
              jobFileInfo {
                jobRefId
                title
                jobGroupId
                jobGroupName
                customerReferenceNo
                createdAt
                workflowId
                workflowName
              }
            }
            fourPL {
              partyId
              orgName
              partyType
              companyReg
              vat
              tin
              logo
              principalContact {
                email
                mobile
              }
              registeredAddress {
                addressLine
                city
                country
                state
              }
              jobFileInfo {
                jobRefId
                title
                jobGroupId
                jobGroupName
                workflowId
                workflowName
              }
            }
          }
          plannedData {
            shipmentsData {
              shipmentId
              serviceMetaData {
                serviceId
                ratesSummary {
                  total
                  subTotal
                  additionalCharges
                }
                service
                rateCard
              }
            }
          }
          paymentTerm {
            text
          }
          terminatedInfo {
            terminatedBy
            terminatedByName
            terminationReason
            terminatedAt
          }
        }
      }
    `;
    const variables = {
      id: jobContractId,
    };
    return this.apollo
      .query<any>({
        query: GET_JOB_CONTRACT_BY_ID,
        variables: variables,
      })
      .pipe(
        map(result => {
          return result.data.getSmartContractById;
        }),
        catchError(error => {
          console.error(error);
          throw error;
        })
      );
  }

  getJobContractPartiesByContractId(jobContractId: string) {
    const GET_JOB_CONTRACT_BY_ID = gql`
      query getSmartContractById($id: String!) {
        getSmartContractById(id: $id) {
          parties {
            buyer {
              partyId
              orgName
              partyType
              companyReg
              vat
              tin
              logo
              principalContact {
                email
                mobile
              }
              registeredAddress {
                addressLine
                city
                country
                state
              }
              pdfFileInfo {
                id
              }
              jobFileInfo {
                jobRefId
                title
                jobGroupId
                jobGroupName
                customerReferenceNo
                createdAt
                workflowId
                workflowName
                initiatorName
              }
            }
            seller {
              partyId
              orgName
              partyType
              companyReg
              vat
              tin
              logo
              principalContact {
                email
                mobile
              }
              registeredAddress {
                addressLine
                city
                country
                state
              }
              pdfFileInfo {
                id
              }
              jobFileInfo {
                jobRefId
                title
                jobGroupId
                jobGroupName
                customerReferenceNo
                createdAt
                workflowId
                workflowName
              }
            }
            fourPL {
              partyId
              orgName
              partyType
              companyReg
              vat
              tin
              logo
              principalContact {
                email
                mobile
              }
              registeredAddress {
                addressLine
                city
                country
                state
              }
              jobFileInfo {
                jobRefId
                title
                jobGroupId
                jobGroupName
                workflowId
                workflowName
              }
            }
          }
        }
      }
    `;
    const variables = {
      id: jobContractId,
    };
    return this.apollo
      .query<{ getSmartContractById: { parties: ContractedParties } }>({
        query: GET_JOB_CONTRACT_BY_ID,
        variables: variables,
      })
      .pipe(
        map(result => {
          return result.data.getSmartContractById;
        }),
        catchError(error => {
          console.error(error);
          throw error;
        })
      );
  }

  // TODO: Move the following methods to a shared service since its used in multiple places
  getLocationsByIds(locationIds: string[]): Observable<{ _id: string; locationName: string }[]> {
    return this.apollo
      .query({
        query: gql`
          query FindLocationsByIds($locationIds: [String!]!) {
            findLocationsByIds(locationIds: $locationIds) {
              locationName
              _id
            }
          }
        `,
        variables: {
          locationIds,
        },
      })
      .pipe(
        filter(result => result.data['findLocationsByIds']),
        take(1),
        map(result => result.data['findLocationsByIds'])
      );
  }

  getCountriesByIds(countryIds: string[]): Observable<{ _id: string; countryName: string }[]> {
    return this.apollo
      .query({
        query: gql`
          query GetCountriesByIds($countryIds: [String!]!) {
            getCountriesByIds(countryIds: $countryIds) {
              _id
              countryName
            }
          }
        `,
        variables: {
          countryIds,
        },
      })
      .pipe(
        filter(result => result.data['getCountriesByIds']),
        take(1),
        map(result => result.data['getCountriesByIds'])
      );
  }

  terminateJobContract(contractId: string, terminationReason: string): Observable<boolean> {
    const TERMINATE_JOB_CONTRACT = gql`
      mutation Mutation($contractId: String!, $terminationReason: String!) {
        terminateContract(contractId: $contractId, terminationReason: $terminationReason)
      }
    `;
    const variables = {
      contractId,
      terminationReason,
    };
    return this.apollo.mutate<{ terminateContract: boolean }>({ mutation: TERMINATE_JOB_CONTRACT, variables }).pipe(
      map(result => {
        return result.data.terminateContract;
      }),
      catchError(error => {
        console.error(error);
        throw error;
      })
    );
  }
}
