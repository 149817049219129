import { Injectable } from '@angular/core';
import { NotificationService } from '@core/services/notification/notification.service';
import { SetSpinner } from '@core/store/shared/shared.actions';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { FileHandlerService } from '@shared/services/file-handler.service';
import { Apollo } from 'apollo-angular';
import { JobContract } from 'app/modules/contract-management/models/job-contract.model';
import { catchError, EMPTY, mergeMap, tap, throwError } from 'rxjs';
import { JobContractService } from '../../services/job-contract-.service';
import { initialJobContractViewState, JobContractViewStateModel } from '../model/job-contract-view-state.model';
import {
  DownloadJobContractPdf,
  FetchJobContract,
  SetJobContract,
  TerminateJobContract,
} from './job-contract-view.actions';

@State<JobContractViewStateModel>({
  name: 'jobContractView',
  defaults: initialJobContractViewState,
})
@Injectable()
export class JobContractViewState {
  constructor(
    private apollo: Apollo,
    private store: Store,
    private notificationService: NotificationService,
    private jobContractService: JobContractService,
    private fileHandlerService: FileHandlerService
  ) {}

  @Selector()
  static getJobContract(state: JobContractViewStateModel): JobContract {
    return state.jobContract;
  }

  @Selector()
  static loading(state: JobContractViewStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static pdfReadyToDownload(state: JobContractViewStateModel): boolean {
    return state.pdfReadyToDownload;
  }

  @Action(FetchJobContract)
  fetchJobContract(ctx: StateContext<JobContractViewStateModel>, action: FetchJobContract) {
    ctx.patchState({ loading: true });
    return this.jobContractService.getJobContractById(action.contractId).pipe(
      tap(jobContract => {
        const {
          user: { orgId },
        } = this.store.selectSnapshot(state => state.auth);
        const pdfFileId = this.jobContractService.getPdfFileId(jobContract, orgId);
        ctx.patchState({ jobContract, loading: false, pdfReadyToDownload: !!pdfFileId });
      }),
      catchError(error => {
        ctx.patchState({ loading: false, error: error.message });
        return throwError(() => error);
      })
    );
  }

  @Action(DownloadJobContractPdf)
  downloadJobContractPdf(ctx: StateContext<JobContractViewStateModel>) {
    const {
      user: { orgId },
    } = this.store.selectSnapshot(state => state.auth);

    const fileId = this.jobContractService.getPdfFileId(ctx.getState().jobContract, orgId);

    if (!fileId) {
      console.log('PDF file not found');
      return EMPTY;
    }

    return this.fileHandlerService.fetchFile(fileId).pipe(
      mergeMap(res => {
        return this.fileHandlerService.getPDFFileData(res.url);
      }),
      tap(response => {
        const a = document.createElement('a');
        const url = URL.createObjectURL(response);
        a.href = url;
        a.download = `Contract.pdf`;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  @Action(TerminateJobContract)
  terminateJobContract(
    ctx: StateContext<JobContractViewStateModel>,
    { contractId, terminationReason }: TerminateJobContract
  ) {
    this.store.dispatch(new SetSpinner(true));
    return this.jobContractService.terminateJobContract(contractId, terminationReason).pipe(
      tap(_ => {
        ctx.patchState({
          jobContract: {
            ...ctx.getState().jobContract,
            status: 'TERMINATED',
          },
        });
        this.store.dispatch(new SetSpinner(false));
      }),
      catchError(error => {
        this.store.dispatch(new SetSpinner(false));
        return throwError(() => error);
      })
    );
  }

  @Action(SetJobContract)
  setJobContract(ctx: StateContext<JobContractViewStateModel>, { jobContract }: SetJobContract) {
    ctx.patchState({ jobContract });
  }
}
