import { Component, ViewChild } from '@angular/core';
import { OwlDateTimeComponent } from '@danielmoncada/angular-datetime-picker';
import { EnhancedDatagridOptionEditorParams } from '@shared/types/enhanced-grid-types/enhanced-grid-components.type';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'hmt-enhanced-datagrid-option-editor',
  templateUrl: './enhanced-datagrid-option-editor.component.html',
  styleUrls: ['./enhanced-datagrid-option-editor.component.scss'],
})
export class EnhancedDatagridOptionEditorComponent<TData = Record<string, unknown>, TOption = string>
  implements ICellEditorAngularComp
{
  params: EnhancedDatagridOptionEditorParams<TData, TOption>;
  selectedOption: TOption;
  options: TOption[];
  labelForInput: string;
  optionLabelGetter: (option: TOption) => string | null;

  @ViewChild(OwlDateTimeComponent) datepicker: OwlDateTimeComponent<Date>;

  agInit(params: EnhancedDatagridOptionEditorParams<TData, TOption>): void {
    this.params = params;
    this.selectedOption = params.value;
    this.options = params.options;
    this.labelForInput = params.labelForInput;
    this.optionLabelGetter = params.optionLabelGetter;
  }

  getValue(): TOption {
    return this.selectedOption;
  }

  onOptionChange(option: TOption): void {
    this.selectedOption = option;
    this.params.stopEditing();
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key !== 'Escape') {
      return;
    }
    this.params.stopEditing();
  }
}
