import { gql } from 'apollo-angular';

export const GET_USER_NAME_BY_ID_QUERY = gql`
  query GetUserNameById($userId: String!) {
    user: getOrgUserById(userId: $userId) {
      firstName
      lastName
      photo
      designation
    }
  }
`;

export interface GetUserNameByIdResponse {
  user: {
    firstName: string;
    lastName: string;
    photo: string | null;
    designation: string | null;
  };
}
