import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'hmt-you-are-here-container',
  templateUrl: './you-are-here-container.component.html',
  styleUrl: './you-are-here-container.component.scss',
})
export class YouAreHereContainerComponent extends FieldWrapper implements AfterViewInit {
  constructor(private elementRef: ElementRef) {
    super();
  }

  ngAfterViewInit() {
    const element = this.elementRef.nativeElement.querySelector('hmt-you-are-here');
    if (element) {
      element.click();
    }
  }
}
