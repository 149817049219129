import { GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY } from '@configs/constants';
import { gql } from 'apollo-angular';
import { Organization } from '../models/organization.model';

// TODO: ADD OTHER NECESSARY FIELDS AS WELL
export const GET_ORGANIZATION_BY_ID_QUERY = gql`
  query GetOrganizationById($orgId: String!) {
    getOrganizationById(orgId: $orgId) {
      ${GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY}
    }
  }
`;

export type GetOrganizationByIdDTO = {
  getOrganizationById: Organization;
};
