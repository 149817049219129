<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>Select Services</span>
    <span class="spacer"></span>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<div class="body">
  <mat-divider></mat-divider>
  <div class="search-container">
    <mat-form-field appearance="outline" class="search-field">
      <mat-icon matPrefix>search</mat-icon>
      <!--TODO: enable when backend is ready-->
      <input matInput placeholder="Search" disabled="true" />
    </mat-form-field>
  </div>
  <div class="services-list">
    <mat-checkbox
      *ngFor="let service of selectableServices"
      [ngModel]="service?.selected && !service?.disabled"
      [disabled]="service?.disabled"
      (ngModelChange)="markAsChecked(service, $event)">
      {{ service?.serviceName }}
    </mat-checkbox>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" (click)="save()">Select</button>
  </mat-dialog-actions>
</div>
