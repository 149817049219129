<div [class.hidden]="!isVisible()">
  <div class="rates-charging-structure" [formGroup]="form">
    <div class="header">
      <div>Rates and Charging Structure</div>
      <div [class.select-disabled]="isDisabled()">
        <mat-form-field appearance="outline" class="currency-dropdown" subscriptSizing="dynamic">
          <mat-label [class.select-disabled]="isDisabled()">Currency</mat-label>
          <mat-select formControlName="mainCurrency" [panelClass]="isDisabled() ? 'hidden' : ''">
            @for (currency of currencies$ | async; track currency.code) {
              <mat-option [value]="currency.code">{{ currency.code }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div
      *ngFor="let rate of form.controls.containerTypeUnitPrices.controls; let i = index"
      class="rate-row"
      [formGroup]="rate">
      <mat-form-field
        appearance="outline"
        class="container-type-field"
        subscriptSizing="dynamic"
        [class.select-disabled]="isDisabled()">
        <mat-label [class.select-disabled]="isDisabled()">Container Type</mat-label>
        <mat-select formControlName="containerType" [panelClass]="isDisabled() ? 'hidden' : ''">
          @for (t of containerTypes(); track t._id) {
            <mat-option [value]="t._id">
              {{ t.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="unit-price-field" subscriptSizing="dynamic">
        <mat-label [class.select-disabled]="isDisabled()">Unit Price</mat-label>
        <input
          formControlName="unitPrice"
          matInput
          type="number"
          min="0"
          step="0.01"
          required
          [readonly]="isDisabled()" />
      </mat-form-field>

      @if (!isReadOnly()) {
        <button mat-icon-button (click)="removeContainerTypeUnitPrice(i)" class="delete-btn" [disabled]="isDisabled()">
          <mat-icon>delete_outline</mat-icon>
        </button>
      }
    </div>
  </div>

  <div class="add-container-type-unit-price-btn">
    @if (!isDisabled()) {
      <button mat-mini-fab class="add-btn" (click)="addContainerTypeUnitPrice()" [disabled]="isDisabled()">
        <mat-icon>add</mat-icon>
      </button>
      Add Container Type Unit Price
      <div class="horizontal-line"></div>
    } @else {
      Additional Charges
    }
  </div>

  <div
    *ngFor="let charge of form.controls.additionalCharges.controls; let i = index"
    class="additional-charge-row"
    [formGroup]="charge">
    <mat-form-field appearance="outline" class="charge-type-field" subscriptSizing="dynamic">
      <mat-label [class.select-disabled]="isDisabled()">Additional Charge</mat-label>
      <input type="text" formControlName="chargeType" matInput [readonly]="isDisabled()" />
    </mat-form-field>
    <div class="amount-currency-pair">
      <mat-form-field appearance="outline" class="amount-field" subscriptSizing="dynamic">
        <mat-label [class.select-disabled]="isDisabled()">Amount</mat-label>
        <input matInput formControlName="chargeAmount" type="number" [readonly]="isDisabled()" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="currency-dropdown" subscriptSizing="dynamic">
        <mat-label [class.select-disabled]="isDisabled()">Currency</mat-label>
        <input matInput readonly [value]="form.controls.mainCurrency.value" [readonly]="isDisabled()" />
      </mat-form-field>
    </div>
    @if (!isReadOnly()) {
      <button mat-icon-button (click)="removeAdditionalCharge(i)" class="delete-btn" [disabled]="isDisabled()">
        <mat-icon>delete_outline</mat-icon>
      </button>
    }
  </div>

  @if (!isReadOnly()) {
    <div class="add-additional-charge-btn">
      <button
        mat-stroked-button
        class="add-btn"
        color="primary"
        (click)="addAdditionalCharge()"
        [disabled]="isDisabled()">
        <mat-icon>add</mat-icon>
        Add New Fee
      </button>
    </div>
  }
</div>
