import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FILE_UPLOAD } from '@configs/api-endpoints';
import { API } from '@configs/api.config';
import { FileInfo } from '@shared/models/file-info.model';
import { PreSignedUrl } from '@shared/models/pre-signed-url.model';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileHandlerService {
  private readonly fileHandlerBaseUrl = FILE_UPLOAD;

  private logoUploadUrl = `${this.fileHandlerBaseUrl}/upload-to-path-without-orgId`;

  constructor(private httpClient: HttpClient) {}

  uploadFile(orgId: string, path: string[], file: File): Observable<FileInfo> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const url = API.fileHandelar.uploadFile.format([orgId, path.toString()]);
    return this.httpClient.post<FileInfo>(url, formData);
  }

  fetchFile(fileId: string): Observable<PreSignedUrl> {
    return this.httpClient.get<PreSignedUrl>(`${this.fileHandlerBaseUrl}/${fileId}/pre-signed`);
  }

  fetchFileWithoutForcedDownload(fileId: string): Observable<PreSignedUrl> {
    return this.httpClient.get<PreSignedUrl>(`${this.fileHandlerBaseUrl}/${fileId}/pre-signed-without-forced-download`);
  }

  fetchFiles(fileIds: string[], duration = 1): Observable<PreSignedUrl[]> {
    const url = `${API.fileHandelar.fetchThumbnails}?duration${duration}`;
    return this.httpClient.post<PreSignedUrl[]>(url, fileIds);
  }

  getPDFFileData(url: string) {
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      map(res => {
        return new Blob([res], { type: 'application/pdf' });
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  uploadFileWithoutOrgId(file: File, path: string, heading?: string): Observable<FileInfo> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    let queryParams = new HttpParams();
    if (heading) {
      queryParams = queryParams.set('heading', heading);
    }
    queryParams = queryParams.set('path', path);

    return this.httpClient.post<FileInfo>(`${this.logoUploadUrl}`, formData, { params: queryParams });
  }
}
