import { gql } from 'apollo-angular';

export const DELETE_VESSEL_SCHEDULE_QUERY = gql`
  mutation DeleteVesselSchedule($scheduleId: String!) {
    deletedVesselSchedule: deleteVesselSchedule(scheduleId: $scheduleId) {
      _id
    }
  }
`;

export interface DeleteVesselScheduleResponse {
  deletedVesselSchedule: {
    _id: string;
  };
}
