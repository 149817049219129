import { Component, inject, input, OnInit, signal, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { RejectDialogComponent } from '@shared/components/reject-dialog/reject-dialog.component';
import { ViewContractComponent } from 'app/modules/contract-view/components/view-contract/view-contract.component';
import {
  DownloadJobContractPdf,
  TerminateJobContract,
} from 'app/modules/contract-view/store/job-contract-view/job-contract-view.actions';
import { JobContractViewState } from 'app/modules/contract-view/store/job-contract-view/job-contract-view.state';
import { take, tap } from 'rxjs';
@Component({
  selector: 'hmt-long-term-contract',
  templateUrl: './long-term-contract.component.html',
  styleUrl: './long-term-contract.component.scss',
})
export class LongTermContractComponent implements OnInit {
  @ViewChild('viewContractRef') viewContractRef!: ViewContractComponent;

  contractIdFromInput = input<string | null>(null);
  contractId = signal('');

  route = inject(ActivatedRoute);
  store = inject(Store);
  dialog = inject(MatDialog);

  pdfReadyToDownload$ = this.store.select(JobContractViewState.pdfReadyToDownload);
  jobContract$ = this.store.select(JobContractViewState.getJobContract);

  ngOnInit(): void {
    if (this.contractIdFromInput()) {
      this.contractId.set(this.contractIdFromInput());
    } else {
      this.route.params.subscribe(() => {
        const parentParams = this.route.params;
        if (parentParams) {
          parentParams.pipe(take(1)).subscribe(params => {
            this.contractId.set(params['contractId']);
          });
        } else {
          console.error('No parent route params found');
        }
      });
    }
  }

  goBack() {
    window.history.back();
  }

  download() {
    this.store.dispatch(new DownloadJobContractPdf(this.contractId()));
  }

  openTerminateTenderDialog() {
    this.dialog
      .open(RejectDialogComponent, {
        data: {
          bodyText: 'Are You Sure You Want to Terminate This Contract?',
          buttonText: 'Terminate Contract',
        },
      })
      .afterClosed()
      .pipe(
        take(1),
        tap(rejectionReason => {
          if (!rejectionReason) return;
          this.openConfirmationDialog(rejectionReason);
        })
      )
      .subscribe();
  }

  private openConfirmationDialog(rejectionReason: string) {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        success: false,
        showCancel: true,
        title: 'Are You Sure',
        message: 'Are You Sure You Want to Terminate This Contract?',
        onConfirmCallback: () => this.terminateTender(rejectionReason),
      },
    });
  }

  private terminateTender(rejectionReason: string) {
    this.store
      .dispatch(new TerminateJobContract(this.contractId(), rejectionReason))
      .pipe(
        tap(() => {
          this.viewContractRef.fetchContract();
        })
      )
      .subscribe();
  }
}
