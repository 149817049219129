<div class="shipment-method-requirement-container">
  <div class="mode">{{ mode() }}</div>
  <mat-divider></mat-divider>
  <div class="bold type">{{ typeLabel() }}</div>
  <div class="requirement-blocks-container">
    @for (type of containerTypes(); track type) {
      <hmt-key-value [keyValue]="{ key: '', value: type }" style="width: 100%"></hmt-key-value>
    }
  </div>
</div>
