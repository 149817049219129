import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { API } from '@configs/api.config';
import { LocationType } from '@shared/enums/location-type.enum';
import { GatewayLocation } from '@shared/models/gateway-location.model';
import {
  GET_ALL_COUNTRIES_AND_STATES_QUERY,
  GetAllCountriesAndStatesDTO,
} from '@shared/queries/get-all-countries-and-states.query';
import { GET_LOCATION_NAME_BY_ID, GetLocationNameByIdResponse } from '@shared/queries/get-location-name-by-id.query';
import {
  GET_LOCATIONS_WITH_SEARCH_QUERY,
  GetLocationsWithSearchResponse,
} from '@shared/queries/get-locations-with-search.query';
import { Apollo } from 'apollo-angular';
import { map, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  private httpClient = inject(HttpClient);
  private gqlService = inject(Apollo);

  getLocationsByLocationIdAnType(
    locations: Array<{ locationId: string; locationType: LocationType }>
  ): Observable<GatewayLocation[]> {
    const url = API.gateway.getLocations;
    return this.httpClient.post<GatewayLocation[]>(url, locations);
  }

  getLocationsByType(locationType: LocationType): Observable<GatewayLocation[]> {
    const url = API.gateway.getLocationsByType.format([locationType]);
    return this.httpClient.get<GatewayLocation[]>(url);
  }

  getCountriesAndStates() {
    return this.gqlService.query<GetAllCountriesAndStatesDTO>({ query: GET_ALL_COUNTRIES_AND_STATES_QUERY }).pipe(
      take(1),
      map(response => response.data.getAllCountries)
    );
  }

  getLocationsWithSearch(locationType: LocationType, searchKey?: string) {
    const getLocationsByFilterInput: Record<string, string | boolean> = {};
    getLocationsByFilterInput['locationType'] = locationType;
    if (searchKey) {
      getLocationsByFilterInput['searchText'] = searchKey;
    }
    getLocationsByFilterInput['limited'] = true;
    return this.gqlService
      .query<GetLocationsWithSearchResponse>({
        query: GET_LOCATIONS_WITH_SEARCH_QUERY,
        variables: { getLocationsByFilterInput },
      })
      .pipe(map(response => response.data.locations));
  }

  getLocationNameById(locationId: string) {
    return this.gqlService
      .query<GetLocationNameByIdResponse>({
        query: GET_LOCATION_NAME_BY_ID,
        variables: { locationId },
      })
      .pipe(map(response => response.data?.location ?? { name: 'Unknown', shortName: 'Unknown' }));
  }
}
