export const GET_SMART_RATE_CARD_BY_RATE_CARD_TYPE_AND_ORG_ID_QUERY = `
  query findSmartRateCardByRateCardTypeAndOrgId($rateCardType: String!, $orgId: String!) {
    findSmartRateCardByRateCardTypeAndOrgId(rateCardType: $rateCardType, orgId: $orgId) {
      _id
      contractedPartyId
      contractedParty {
        _id
        orgName
      }
    }
  }
`;

export const GET_SMART_RATE_CARD_BY_RATE_CARD_TYPE_AND_ORG_ID_AND_CONTRACTED_PARTY_ID_QUERY = `
  query FindSmartRateCardByRateCardTypeAndOrgIdAndContractedPartyId(
    $rateCardType: String!,
    $orgId: String!,
    $contractedPartyId: String!
  ) {
    findSmartRateCardByRateCardTypeAndOrgIdAndContractedPartyId(
      rateCardType: $rateCardType,
      orgId: $orgId,
      contractedPartyId: $contractedPartyId
    ) {
      rateCardData {
        name
        _id
      }
    }
  }
`;

export const SAVE_PORT_PAIR_TEMPLATE_MUTATION = `
  mutation CreateLocationPairTemplate($locationPairTemplateInput: LocationPairTemplateInput!) {
    createLocationPairTemplate(locationPairTemplateInput: $locationPairTemplateInput) {
      _id
    }
  }
`;

export const GET_PORT_PAIR_TEMPLATES_QUERY = `
query Query($offset: Int!, $limit: Int!, $locationPairTemplateFilterInput: LocationPairsTemplateFilterInput!) {
  findLocationPairTemplatesByFilter(offset: $offset, limit: $limit, locationPairTemplateFilterInput: $locationPairTemplateFilterInput) {
    pageInfo {
      total
    }
    items {
      templateType
      templateName
      templateDescription
      orgId
      locationPairs {
        transhipmentAllowed
        shipmentMethodsData
        originLocation {
          locationType
          locationName
          locationId
          countryName
          countryId
        }
        destinationLocation {
          countryId
          countryName
          locationId
          locationName
          locationType
        }
      }
      _id
    }
  }
}
`;
