<mat-accordion class="long-term-contract-location-pair">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title> {{ title() }} </mat-panel-title>
    </mat-expansion-panel-header>
    <hmt-long-term-contract-location-pair-summary [locationPair]="locationPair()" />
    @for (vesselScheduleGroup of vesselScheduleGroups(); track vesselScheduleGroup.name) {
      <hmt-long-term-location-pair-vessel-schedule-table [vesselScheduleGroup]="vesselScheduleGroup" />
    }
    @for (flightScheduleGroup of flightScheduleGroups(); track flightScheduleGroup.name) {
      <hmt-long-term-location-pair-flight-schedule-table [flightScheduleGroup]="flightScheduleGroup" />
    }
  </mat-expansion-panel>
</mat-accordion>
