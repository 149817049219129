import { Component, computed, signal } from '@angular/core';
import { EnhancedDatagridFlightDatesFrequencyEditorParams } from '@shared/types/enhanced-grid-types/enhanced-grid-components.type';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { FlightFrequencyType } from 'app/modules/catalog-manager/enums/flight-frequency-type.enum';

@Component({
  selector: 'hmt-enhanced-datagrid-flight-dates-editor',
  templateUrl: './enhanced-datagrid-flight-dates-editor.component.html',
  styleUrls: ['./enhanced-datagrid-flight-dates-editor.component.scss'],
})
export class EnhancedDatagridFlightDatesEditorComponent implements ICellEditorAngularComp {
  params: EnhancedDatagridFlightDatesFrequencyEditorParams;
  frequency = signal<FlightFrequencyType>(FlightFrequencyType.DAILY);
  for = signal<'firstLeg' | 'secondLeg'>('firstLeg');
  numOfDays = signal(0);
  maxNumOfDaysAllowed = signal(0);
  minNumOfDaysAllowed = signal(0);

  dayOptions = ['D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7'];
  selectedDays = signal<string[]>([]);

  isSaveButtonEnabled = computed(() => {
    return (
      this.selectedDays().length >= this.minNumOfDaysAllowed() &&
      this.selectedDays().length <= this.maxNumOfDaysAllowed()
    );
  });

  isSelectingDaysAllowed = computed(() => {
    return this.frequency() !== FlightFrequencyType.DAILY;
  });

  isSelectingAnyMoreAllowed = computed(() => {
    return !(
      (this.frequency() === FlightFrequencyType.PER_WEEK || this.frequency() === FlightFrequencyType.SPECIFIC_DAYS) &&
      this.selectedDays().length >= this.maxNumOfDaysAllowed()
    );
  });

  message = computed(() => {
    if (this.frequency() === FlightFrequencyType.PER_WEEK) {
      return `You must select ${this.maxNumOfDaysAllowed()} days.You can uncheck currently checked days to select different days.Edit your frequency to select more/less days`;
    }
    if (this.frequency() === FlightFrequencyType.SPECIFIC_DAYS) {
      return `You must select ${this.maxNumOfDaysAllowed()} days. You can uncheck currently checked days to select different days.Edit your frequency to select more/less days`;
    }

    if (this.frequency() === FlightFrequencyType.DAILY) {
      return `You can't select days. Edit your frequency to select days`;
    }

    return '';
  });

  agInit(params: EnhancedDatagridFlightDatesFrequencyEditorParams): void {
    this.params = params;
    this.for.set(params.for);
    this.frequency.set(this.for() === 'firstLeg' ? params.data.firstLegFrequency : params.data.secondLegFrequency);
    this.selectedDays.set(
      this.for() === 'firstLeg' ? params.data.firstLegDepartureDays : params.data.secondLegArrivalDays
    );
    if (this.frequency() === FlightFrequencyType.PER_WEEK || this.frequency() === FlightFrequencyType.SPECIFIC_DAYS) {
      this.setNumberOfDaysAllowed(
        this.frequency(),
        this.for() === 'firstLeg' ? params.data.firstLegNoOfDays : params.data.secondLegNoOfDays
      );
    } else {
      this.setNumberOfDaysAllowed(this.frequency());
    }

    console.log('this.frequency()', this.frequency());
    console.log('this.for()', this.for());
    console.log('this.selectedDays()', this.selectedDays());
    console.log('this.isSelectingDaysAllowed()', this.isSelectingDaysAllowed());
    console.log('this.isSelectingAnyMoreAllowed()', this.isSelectingAnyMoreAllowed());
    console.log('this.message()', this.message());
    console.log('this.isSaveButtonEnabled()', this.isSaveButtonEnabled());
  }

  getValue() {
    const rawSelectedDays = this.selectedDays();
    console.log('rawSelectedDays', rawSelectedDays);
    return rawSelectedDays;
    // return rawSelectedDays.sort((a, b) => this.dayOptions.indexOf(a) - this.dayOptions.indexOf(b));
  }

  onChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      if (!this.isSelectingAnyMoreAllowed()) {
        checkbox.checked = false;
        return;
      }
      this.selectedDays.set([...this.selectedDays(), checkbox.value]);
    } else {
      this.selectedDays.set(this.selectedDays().filter(day => day !== checkbox.value));
    }
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key !== 'Escape') {
      return;
    }
    this.params.stopEditing();
  }

  onSave(): void {
    this.params.stopEditing();
  }

  setNumberOfDaysAllowed(frequency: FlightFrequencyType, perWeekAllowedDays?: number) {
    console.log('frequency', frequency);
    console.log('perWeekAllowedDays', perWeekAllowedDays);
    switch (frequency) {
      case FlightFrequencyType.DAILY:
        this.maxNumOfDaysAllowed.set(7);
        this.minNumOfDaysAllowed.set(7);
        break;
      case FlightFrequencyType.PER_WEEK:
        this.maxNumOfDaysAllowed.set(perWeekAllowedDays);
        this.minNumOfDaysAllowed.set(perWeekAllowedDays);
        break;
      case FlightFrequencyType.SPECIFIC_DAYS:
        this.maxNumOfDaysAllowed.set(perWeekAllowedDays);
        this.minNumOfDaysAllowed.set(perWeekAllowedDays);
        break;
    }
  }
}
