import { inject, Injectable, signal } from '@angular/core';
import { GET_USER_NAME_BY_ID_QUERY, GetUserNameByIdResponse } from '@shared/queries/get-user-name-by-id.query';
import { Apollo } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserNamesService {
  apollo = inject(Apollo);

  userNames = signal<
    Record<string, { firstName: string; lastName: string; designation?: string; photo: string; timestamp: number }>
  >({});
  private pendingRequests = new Map<
    string,
    Promise<{ firstName: string; lastName: string; designation?: string; photo: string }>
  >();

  async getUserName(userId: string) {
    const cachedUserName = this.userNames()[userId];
    const now = Date.now();
    const FIFTY_NINE_MINUTES = 59 * 60 * 1000;

    if (cachedUserName && now - cachedUserName.timestamp < FIFTY_NINE_MINUTES) {
      return cachedUserName;
    }

    // Check if there's already a pending request for this userId
    if (this.pendingRequests.has(userId)) {
      return await this.pendingRequests.get(userId);
    }

    // Create new request and store it
    const request = firstValueFrom(
      this.apollo.query<GetUserNameByIdResponse>({
        query: GET_USER_NAME_BY_ID_QUERY,
        variables: { userId },
      })
    ).then(result => ({
      firstName: result.data.user.firstName,
      lastName: result.data.user.lastName,
      designation: result.data.user.designation,
      photo: result.data.user.photo,
    }));
    this.pendingRequests.set(userId, request);

    try {
      const userName = await request;
      this.userNames.set({
        ...this.userNames(),
        [userId]: {
          firstName: userName.firstName,
          lastName: userName.lastName,
          photo: userName.photo,
          designation: userName.designation,
          timestamp: now,
        },
      });

      return userName;
    } finally {
      // Clean up the pending request
      this.pendingRequests.delete(userId);
    }
  }
}
