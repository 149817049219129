import { DatePipe } from '@angular/common';
import { Component, computed, Inject, inject, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthState } from '@core/store/auth/auth.state';
import { Store } from '@ngxs/store';
import { OrgDetailsService } from '@shared/services/org-details.service';
import { TenderNamesService } from '@shared/services/tender-names.service';
import { UserNamesService } from '@shared/services/user-names.service';
import { LongTermRatesContract } from 'app/modules/contract-management/models/long-term-contract.model';
import { LongTermContractsService } from 'app/modules/contract-management/services/long-term-contracts.service';
import { ContractTerminatedInfo } from 'app/modules/contract-view/components/models/contract-terminated-info.model';
import { ContractedParties } from 'app/modules/contract-view/components/models/contracted-parties.model';
import { ContractedParty } from 'app/modules/contract-view/components/models/contracted-party.model';
import { JobContractService } from 'app/modules/contract-view/services/job-contract-.service';
import { map, take, tap, throwError } from 'rxjs';

@Component({
  selector: 'hmt-view-long-term-contract',
  templateUrl: './view-long-term-contract.component.html',
  styleUrls: ['./view-long-term-contract.component.scss'],
  providers: [DatePipe],
})
export class ViewLongTermContractComponent implements OnInit, OnDestroy {
  jobContractService = inject(JobContractService);
  longTermContractsService = inject(LongTermContractsService);
  tenderNamesService = inject(TenderNamesService);
  userNamesService = inject(UserNamesService);
  orgDetailsService = inject(OrgDetailsService);
  store = inject(Store);
  datePipe = inject(DatePipe);

  @Input() contractId: string;
  longTermRatesContract = signal<LongTermRatesContract | null>(null);
  tenderName = signal<string | null>(null);
  loading = signal<boolean>(false);

  title = 'Contract';
  viewingAsA: 'BUYER' | 'SELLER' | '4PL' | 'OTHER' = 'OTHER';
  customerInformation = {
    logo: './assets/icons/no-image.png',
    companyReg: '--',
    orgName: '--',
    orgAddress: '--',
    tenderName: '--',
    internalRefNumber: '--',
    preparedBy: '--',
    preparedByDesignation: '--',
    customerReferenceNumber: '--',
    validityPeriod: '--',
    createdDateAndTime: '--',
  };
  customerOrgInformation = {
    companyReg: '--',
    vat: '--',
    tin: '--',
    address: '--',
    phone: '--',
    email: '--',
    fax: '--',
    principalContact: {
      name: '--',
      designation: '--',
      email: '--',
      phone: '--',
    },
  };

  fourPLInformation = {
    contractedWith4PL: false,
    contractRefNumber: '--',
    orgName: '--',
    orgAddress: '--',
  };

  orgLogos: { [key: string]: string } = {};
  paymentTerms: string[] = [];
  terminationInfo: ContractTerminatedInfo = null;

  services = computed(() => {
    return this.longTermRatesContract() ? this.longTermRatesContract().metaData.serviceMetaData : [];
  });
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contractId: string;
    }
  ) {
    this.contractId = data?.contractId;
  }

  ngOnInit(): void {
    this.fetchContract();
  }

  fetchContract(): void {
    this.loading.set(true);
    this.longTermContractsService
      .findLongTermRatesContractById(this.contractId)
      .pipe(
        map(contract => {
          console.log('contract', contract);
          this.longTermRatesContract.set(contract);
          const currentOrgId: string = this.store.selectSnapshot(AuthState.getOrgId);
          let customerOrgId = null;

          if (contract.parties?.seller?.partyId === currentOrgId) {
            this.viewingAsA = 'SELLER';
            customerOrgId = contract.parties?.buyer?.partyId;
          } else if (
            contract.parties?.buyer?.partyId === currentOrgId ||
            contract.parties?.fourPL?.partyId === currentOrgId
          ) {
            this.viewingAsA = 'BUYER';
            customerOrgId = contract.parties?.seller?.partyId;
          } else {
            throwError(() => new Error('You are not authorized to view this contract'));
          }

          return contract;
        }),
        tap(contract => {
          this.tenderNamesService.getTenderName(contract.tenderId).then(tenderName => {
            console.log('tenderName', tenderName);
            this.tenderName.set(tenderName.name);
            this.customerInformation.tenderName = tenderName.name;
          });
          this.generateTitle(contract);
          this.generateTerminationInfo(contract);
          this.generateCustomerInformation(contract.parties);
          this.generateCustomerOrgInformation(contract.parties);
          this.generateFourPLInformation(contract);
          this.generateOrgLogos(contract);
          this.generatePaymentTerms(contract.paymentTerm);
        })
      )
      .subscribe({
        complete: () => this.loading.set(false),
      });
  }

  private generateTitle(contract: LongTermRatesContract): void {
    const serviceNames = contract?.metaData?.serviceMetaData.map(({ serviceId }) => serviceId);
    this.title = `Contract | ${serviceNames.join(' | ')}`;
    console.log('title', this.title);
  }

  private generateTerminationInfo(_contract: LongTermRatesContract): void {
    this.terminationInfo = null; // please change this properly looking at view-contract.component.ts
  }

  private generateOrgLogos(contract: LongTermRatesContract): void {
    const logoId = this.viewingAsA === 'SELLER' ? contract.parties?.buyer?.logo : contract.parties?.seller?.logo;
    if (!logoId) return;
    this.jobContractService
      .getLogo(logoId)
      .pipe(take(1))
      .subscribe(url => {
        this.customerInformation.logo = url;
      });
  }

  private async generateFourPLInformation(contract: LongTermRatesContract) {
    const currentOrgId: string = this.store.selectSnapshot(AuthState.getOrgId);
    const org: ContractedParty = this.viewingAsA === 'SELLER' ? contract.parties.buyer : contract.parties.seller;

    const fourPlParty = contract.parties?.fourPL;
    const fourPlOrg =
      fourPlParty && fourPlParty.partyId ? await this.orgDetailsService.getOrgDetails(fourPlParty.partyId) : null;

    this.fourPLInformation.contractedWith4PL = contract.parties?.fourPL?.partyId === currentOrgId;
    this.fourPLInformation.contractRefNumber = contract.contractRef ?? '--';

    if (fourPlOrg) {
      this.fourPLInformation.orgName = fourPlOrg.orgName;
    }

    console.log('fourPlParty', fourPlParty);
    console.log('fourPlOrg', fourPlOrg);

    if (this.fourPLInformation.contractedWith4PL && fourPlOrg) {
      if (fourPlOrg.registeredAddress && fourPlOrg.registeredAddress.addressLine) {
        let address = fourPlOrg.registeredAddress.addressLine;
        if (fourPlOrg.registeredAddress.city) {
          address += `, ${fourPlOrg.registeredAddress.city}`;
        }
        if (fourPlOrg.registeredAddress.state) {
          address += `, ${fourPlOrg.registeredAddress.state}`;
        }
        if (fourPlOrg.registeredAddress.country) {
          address += `, ${fourPlOrg.registeredAddress.country}`;
        }
        this.fourPLInformation.orgAddress = address;
      }
    }
  }

  private async generateCustomerInformation(contractedParties: ContractedParties) {
    const org = this.viewingAsA === 'SELLER' ? contractedParties.buyer : contractedParties.seller;

    this.customerInformation.logo = org.logo || './assets/icons/no-image.png';
    this.customerInformation.companyReg = org.companyReg || '--';
    this.customerInformation.orgName = org.orgName || '--';
    this.customerInformation.orgAddress = org.registeredAddress?.addressLine || '--';
    // this.customerInformation.internalRefNumber = jobFile?.jobRefId || '--'; // TODO: check if this is correct

    const userName = this.longTermRatesContract().createdBy
      ? await this.userNamesService.getUserName(this.longTermRatesContract().createdBy)
      : null;

    this.customerInformation.preparedBy = userName ? `${userName.firstName} ${userName.lastName}` : '--';
    this.customerInformation.preparedByDesignation = userName ? userName.designation : '--';
    // this.customerInformation.customerReferenceNumber = jobFile?.customerReferenceNo || '--'; // TODO: check if this is correct
    this.customerInformation.validityPeriod = this.longTermRatesContract().ratesValidityPeriod
      ? `${this.convertDate(this.longTermRatesContract().ratesValidityPeriod.from.toString())} to ${this.convertDate(this.longTermRatesContract().ratesValidityPeriod.to.toString())}`
      : '--';
    this.customerInformation.createdDateAndTime = this.convertDate(this.longTermRatesContract().createdAt.toString());
  }

  private async generateCustomerOrgInformation(contractedParties: ContractedParties) {
    const org = this.viewingAsA === 'SELLER' ? contractedParties.buyer : contractedParties.seller;
    const orgDetails = await this.orgDetailsService.getOrgDetails(org.partyId);

    this.customerOrgInformation.companyReg = orgDetails?.companyRegNum ?? '--';
    this.customerOrgInformation.vat = orgDetails?.vatNum ?? '--';
    this.customerOrgInformation.tin = orgDetails?.tinNum ?? '--';

    if (orgDetails?.principalContact) {
      this.customerOrgInformation.principalContact.phone = orgDetails?.principalContact.mobile ?? '--';
      this.customerOrgInformation.principalContact.email = `${orgDetails?.principalContact.email} (Mobile)` ?? '--';
      this.customerOrgInformation.principalContact.name =
        `${orgDetails?.principalContact?.firstName || ''} ${orgDetails?.principalContact?.lastName || ''}` || '--';
    }

    if (orgDetails?.registeredAddress && orgDetails?.registeredAddress.addressLine) {
      this.customerOrgInformation.address = orgDetails?.registeredAddress.addressLine;
      if (orgDetails?.registeredAddress.city) {
        this.customerOrgInformation.address += `, ${orgDetails?.registeredAddress.city}`;
      }
      if (orgDetails?.registeredAddress.state) {
        this.customerOrgInformation.address += `, ${orgDetails?.registeredAddress.state}`;
      }
      if (orgDetails?.registeredAddress.country) {
        this.customerOrgInformation.address += `, ${orgDetails?.registeredAddress.country}`;
      }
    }
  }

  private generateJobType(workflowId: string) {
    if (workflowId === 'IL') {
      return 'International Logistics';
    } else {
      return '--';
    }
  }

  convertDate(date: string): string {
    if (!date) return '--';
    try {
      const date24Hours = this.datePipe.transform(date, 'dd-MMM-yyyy hh:mm:a');
      return date24Hours ? date24Hours : '--';
    } catch (error) {
      return '--';
    }
  }

  convertDateToDate24Hours(date: string): string {
    if (!date) return '--';
    try {
      const date24Hours = this.datePipe.transform(date, 'dd/MMM/yyyy HHmm');
      return date24Hours ? `${date24Hours}h` : '--';
    } catch (error) {
      return '--';
    }
  }

  private determineRouting(transitType: string): string {
    if (transitType === 'TRANSIT') {
      return 'Transit';
    } else if (transitType === 'DIRECT') {
      return 'Direct';
    } else {
      return '--';
    }
  }

  private generatePaymentTerms(paymentTerms: LongTermRatesContract['paymentTerm']) {
    if (paymentTerms && paymentTerms.text) {
      this.paymentTerms = [paymentTerms.text];
    } else {
      this.paymentTerms = ['--'];
    }
  }

  ngOnDestroy(): void {
    console.log('destroy');
  }

  serviceIdToName(serviceId: string): string {
    switch (serviceId) {
      case 'OCEAN-FREIGHT':
        return 'Ocean Freight';
      case 'AIR-FREIGHT':
        return 'Air Freight';
      default:
        return serviceId;
    }
  }
}
