import { Component, inject, output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SaveModalInput } from '@shared/models/save-modal-input.model';

@Component({
  selector: 'hmt-save-modal',
  templateUrl: './save-modal.component.html',
  styleUrl: './save-modal.component.scss',
})
export class SaveModalComponent {
  private readonly fb = inject(FormBuilder);
  readonly data = inject(MAT_DIALOG_DATA) as SaveModalInput;
  readonly dialogRef = inject(MatDialogRef<SaveModalComponent>);
  readonly saveTemplate = output<string>();
  readonly closeModal = output<void>();
  readonly form: FormGroup;

  constructor() {
    this.form = this.fb.group({
      inputField: ['', Validators.required],
    });
  }

  onSave() {
    if (!this.form.valid) return;
    this.dialogRef.close(this.form.value.inputField);
  }

  onClose() {
    this.dialogRef.close();
  }
}
