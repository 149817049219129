export const ORDER_CREATE_UI = '801cc7cd-971d-4e6b-b681-3e476a7e3524';
export const ORG_ID = '7171bb41-185a-448f-aa40-c9ab64ab9ca2';
export const PAGE_SIZE = 10;
export const PAGE_NUMBER = 1;
export const DEFAULT_OPTION_COUNT = 50;
export const PAGE_SIZE_OPTIONS = [1, 5, 10, 15, 20];
export const AIR_FREIGHT = 'AIR-FREIGHT';
export const WORKFLOW_INFO = {
  // todo: get this from backend
  'OCEAN-FREIGHT': {
    name: 'Ocean Freight',
  },
  'AIR-FREIGHT': {
    name: 'Air Freight',
  },
  IL: {
    name: 'International Logistics',
  },
};
export const COUNTRY_FLAG_CDN_URL = (countryCode: string): string =>
  `https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.0.0/flags/1x1/${countryCode ? countryCode.toLowerCase() : ''}.svg`;
export const DEFAULT_DEBOUNCE_TIME = 300;
export const DEFAULT_VOLUME_WEIGHT_METRIC = 'Kg';

export enum PaymentUsage {
  CUSTOMER_AND_LSP = 'CUSTOMER_AND_LSP',
  CUSTOMER_AND_SUPPLIER = 'CUSTOMER_AND_SUPPLIER',
}
export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_HOUR = 60;
export const HOURS_PER_DAY = 24;
export const MILLISECONDS_PER_SECOND = 1000;

export const DEFAULT_POLLING_INTERVAL = 1000;

//! TEMPORARY SOLUTION USED FOR DEMO: Remove this after release
export const DOWNLOAD_FILE_OPTIONS = {
  LONG_TERM_FILE:
    'https://storage.googleapis.com/platform_upload_files/bulk-order/demo/aad2522d-b230-4000-bb08-c3c6bc0dc5fe?response-content-disposition=attachment%3B%20filename%3D%22Long_Term_FCL_Bids_202401150002.xlsx%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20250115T073856Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86399&X-Amz-Credential=GOOG1E6EF5KIKVIQOSGC2H3BVKS2NIVEWQHXTPERCIKLRJYCM5PDBZNOHEU2X%2F20250115%2Fauto%2Fs3%2Faws4_request&X-Amz-Signature=2028202d7de623aebe525bc33a797db99923359e22b91b70eb426dba8b6aa74c',
  SPOT_FILE:
    'https://storage.googleapis.com/platform_upload_files/bulk-order/demo/e35e092d-5fef-4c8b-b455-394234be605f?response-content-disposition=attachment%3B%20filename%3D%22FCL_SEA_Bids_202501150001.xlsx%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20250115T072631Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86399&X-Amz-Credential=GOOG1E6EF5KIKVIQOSGC2H3BVKS2NIVEWQHXTPERCIKLRJYCM5PDBZNOHEU2X%2F20250115%2Fauto%2Fs3%2Faws4_request&X-Amz-Signature=0dbd4c229e7d6354e75abc1a5bb75e9126649629d331a118fb2a180afd241d5f',
  FILE_NAME: 'favorite-bids.xlsx',
};
