import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CarrierRoutingStopDetailed } from 'app/modules/catalog-manager/models/carrier-routing-details.model';
import { FlightScheduleTableRow } from 'app/modules/catalog-manager/models/flight-schedule.model';
import { VesselScheduleTableRow } from 'app/modules/catalog-manager/models/vessel-schedule.model';

@Component({
  selector: 'hmt-enhanced-datagrid-airport-renderer',
  templateUrl: './enhanced-datagrid-airport-renderer.component.html',
  styleUrls: ['./enhanced-datagrid-airport-renderer.component.scss'],
})
export class EnhancedDatagridAirportRendererComponent implements ICellRendererAngularComp {
  private params!: ICellRendererParams<VesselScheduleTableRow | FlightScheduleTableRow, CarrierRoutingStopDetailed>;
  value!: CarrierRoutingStopDetailed;

  agInit(
    params: ICellRendererParams<VesselScheduleTableRow | FlightScheduleTableRow, CarrierRoutingStopDetailed>
  ): void {
    this.params = params;
    this.setValue(params);
  }

  refresh(
    params: ICellRendererParams<VesselScheduleTableRow | FlightScheduleTableRow, CarrierRoutingStopDetailed>
  ): boolean {
    this.params = params;
    this.setValue(params);
    return true;
  }

  private setValue(
    params: ICellRendererParams<VesselScheduleTableRow | FlightScheduleTableRow, CarrierRoutingStopDetailed>
  ) {
    this.value = params.value;
  }
}
