<mat-toolbar class="header-bar">
  <!--  eslint-disable-next-line @angular-eslint/template/interactive-supports-focus -->
  <img
    src="assets/logo/dwara_logo.png"
    alt="Logo"
    class="logo c-p"
    width="130"
    height="130"
    (click)="goToDashboard()"
    (keydown.enter)="goToDashboard()" />
  <span class="spacer"></span>
  <mat-form-field appearance="outline" class="operation-select" subscriptSizing="dynamic">
    <mat-label>Operation</mat-label>
    <mat-select [(value)]="selectedOperation" (selectionChange)="onOperationChange($event.value)">
      <mat-option *ngFor="let operation of operations" [value]="operation">
        {{ operation.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-divider vertical="true" class="full-height-divider"></mat-divider>
  <div class="badge-wrapper">
    <button mat-icon-button>
      <mat-icon fontSet="material-icons-outlined">maps_ugc</mat-icon>
    </button>
    <ng-container *ngIf="(warRoomMessageCount$ | async) > 0">
      <div class="custom-badge circle-badge">{{ warRoomMessageCount$ | async }}</div>
    </ng-container>
  </div>
  <mat-divider vertical="true" class="full-height-divider"></mat-divider>
  <div class="badge-wrapper">
    <button mat-icon-button (click)="toggleNotifications()">
      <mat-icon fontSet="material-icons-outlined">notifications</mat-icon>
    </button>
    <ng-container *ngIf="(notificationsCount$ | async) > 0">
      <div class="custom-badge oval-badge">{{ notificationsCount$ | async }}</div>
    </ng-container>
  </div>
  <mat-divider vertical="true" class="full-height-divider"></mat-divider>
  <mat-icon fontSet="material-icons-outlined" class="profile-icon">account_circle</mat-icon>
  <button mat-button [matMenuTriggerFor]="menu" class="profile-button">
    <span class="profile-name">{{ userFullName$ | async }}</span>
    <mat-icon class="dropdown-icon">keyboard_arrow_down</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>Profile</button>
    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu>
</mat-toolbar>
