import { Component, Input } from '@angular/core';

@Component({
  selector: 'hmt-contract-grid',
  templateUrl: './contract-grid.component.html',
  styleUrl: './contract-grid.component.scss',
})
export class ContractGridComponent {
  // Default values override by the parent component
  @Input() columns: number = 4;
  @Input() justifyItems: string = 'center';

  get gridClass() {
    return `grid-${this.columns} ${this.justifyItems === 'center' ? 'center-items' : ''}`;
  }
}
