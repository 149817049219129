<div class="option-editor">
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="option-editor__input">
    <mat-label>{{ labelForInput() ?? 'Choose an option' }}</mat-label>
    <mat-select
      [(ngModel)]="selectedOption"
      (selectionChange)="onOptionChange($event.value)"
      (keydown)="onKeyDown($event)">
      @for (option of options; track option) {
        <mat-option [value]="option">
          {{ flightFrequencyToHumanReadable(option) }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" subscriptSizing="dynamic">
    <mat-label>Number of days</mat-label>
    <input
      type="number"
      matInput
      [(ngModel)]="noOfDays"
      [min]="minNoOfDays()"
      [max]="maxNoOfDays()"
      [disabled]="isNoOfDaysDisabled()" />
  </mat-form-field>
  <button mat-flat-button color="primary" (click)="onSave()" [disabled]="!isSaveButtonEnabled()">Save</button>
</div>
