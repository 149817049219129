import { DatePipe, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, inject, Input, output, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { DialogHandlerService } from '@core/services/dialog-handler.service';
import { UploadedFileType } from '../../../modules/wizard/models/uploaded-file.type';
import { OrgDocumentPreviewDialogComponent } from '../org-document-preview-dialog/org-document-preview-dialog.component';

@Component({
  selector: 'hmt-uploaded-document',
  templateUrl: './uploaded-document.component.html',
  styleUrl: './uploaded-document.component.scss',
  standalone: true,
  imports: [FormsModule, MatSlideToggle, NgIf, NgOptimizedImage, DatePipe, MatButtonModule, MatIconModule],
})
export class UploadedDocumentComponent {
  dialogHandlerService = inject(DialogHandlerService);

  @Input() file!: UploadedFileType;
  @Input() saveFileCallback: (file: UploadedFileType) => void = () => {};
  @Output() activateFile = new EventEmitter<UploadedFileType>();

  saveFile = output<UploadedFileType>();

  toggleActive(): void {
    if (!this.file.isActive) {
      this.activateFile.emit(this.file);
    }
  }

  onSaveButtonClicked(event: Event): void {
    event.stopPropagation();
    this.saveFileCallback(this.file);
  }

  openDocumentPreviewDialog(file: UploadedFileType) {
    this.dialogHandlerService.openDialog(OrgDocumentPreviewDialogComponent, {
      file,
    });
  }
}
