import { Component, Inject, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogHandlerService } from '@core/services/dialog-handler.service';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'hmt-apply-contract-response-cutoff-dialog',
  templateUrl: './apply-contract-response-cutoff-dialog.component.html',
  styleUrls: ['./apply-contract-response-cutoff-dialog.component.scss'],
})
export class ApplyContractResponseCutoffDialogComponent implements OnInit {
  unsubscribe$ = new Subject<void>();

  dialogHandlerService = inject(DialogHandlerService);
  dialogRef = inject(MatDialogRef);

  cutoffForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      invoiceId?: string;
      invoiceIds?: string[];
    }
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.cutoffForm = new FormGroup({
      selectedOption: new FormControl('VALIDITY_PERIOD'),
      fromDate: new FormControl(null),
      toDate: new FormControl(null),
      duration: new FormControl(null),
      durationType: new FormControl('HOURS'),
    });

    this.cutoffForm
      .get('selectedOption')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap(value => {
          if (value === 'VALIDITY_PERIOD') {
            this.cutoffForm.get('fromDate').setValidators([Validators.required]);
            this.cutoffForm.get('toDate').setValidators([Validators.required]);
            this.cutoffForm.get('duration').clearValidators();
          } else {
            this.cutoffForm.get('duration').setValidators([Validators.required]);
            this.cutoffForm.get('fromDate').clearValidators();
            this.cutoffForm.get('toDate').clearValidators();
          }

          this.cutoffForm.get('fromDate').updateValueAndValidity();
          this.cutoffForm.get('toDate').updateValueAndValidity();
          this.cutoffForm.get('duration').updateValueAndValidity();
        })
      )
      .subscribe();
  }

  submit(): void {
    if (this.cutoffForm.valid) {
      const formValue = this.cutoffForm.value;
      const result = {
        type: formValue.selectedOption,
        ...(formValue.selectedOption === 'VALIDITY_PERIOD'
          ? {
              fromDate: formValue.fromDate,
              toDate: formValue.toDate,
            }
          : {
              duration: formValue.duration,
              durationType: formValue.durationType,
            }),
      };

      this.dialogRef.close(result);
    }
  }
}
