<div class="modal-container">
  <div class="modal-header flex justify-between items-center mb-20 p-15">
    <div class="m-0 bold font-16 pt-10">{{ data.title }}</div>
    <button class="close-btn" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="modal-body mt-20 mb-20 pr-30 pl-30">
    <form [formGroup]="form">
      <mat-form-field appearance="outline" class="w-full" [subscriptSizing]="'dynamic'">
        <mat-label>{{ data.inputLabel }}</mat-label>
        <input matInput placeholder="Enter Name" formControlName="inputField" />
        <mat-error *ngIf="form.get('inputField')?.invalid && form.get('inputField')?.touched">
          {{ data.inputLabel }} is required.
        </mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="modal-footer flex justify-end gap-10 p-10">
    <button mat-stroked-button class="btn-grey-outline" (click)="onClose()">{{ data.cancelButtonText }}</button>
    <button
      mat-flat-button
      [ngClass]="{ 'btn-purple-fill': !form.invalid, 'disabled-button': form.invalid }"
      (click)="onSave()">
      {{ data.saveButtonText }}
    </button>
  </div>
</div>
