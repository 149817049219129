import { Component, HostListener, OnInit, output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DaysHoursMinutes } from '@shared/models/days-hours-minutes.model';

@Component({
  selector: 'hmt-time-input',
  template: `
    <mat-form-field appearance="outline">
      <input
        matInput
        type="text"
        [formControl]="formControl"
        mask="00:00:00"
        [validation]="true"
        [dropSpecialCharacters]="false"
        placeholder="DD:HH:MM" />
      <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
    </mat-form-field>
  `,
})
export class TimeInputComponent implements OnInit {
  daysHoursMinutesOutput = output<DaysHoursMinutes>();
  formControl = new FormControl('', [Validators.pattern(/^\d{2}:\d{2}:\d{2}$/)]);
  errorMessage: string | null = null;
  disabled = false;
  isComponentClicked = false;

  ngOnInit() {
    this.formControl.valueChanges.subscribe(value => {
      if (value && this.formControl.valid) {
        this.validateTime(value);
        if (!this.errorMessage) {
          const formattedValue = this.formatTime(value);
          this.daysHoursMinutesOutput.emit(formattedValue);
        }
      }
    });
  }

  validateTime(value: string): void {
    if (!value?.includes(':')) {
      this.errorMessage = 'Invalid time format';
      return;
    }

    const [days, hours, minutes] = value.split(':').map(Number);

    if (isNaN(days) || isNaN(hours) || isNaN(minutes)) {
      this.errorMessage = 'Invalid time format';
      return;
    }

    if (days > 99 || hours > 23 || minutes > 59) {
      this.errorMessage = 'Format: Days (0-99), Hours (0-23), Minutes (0-59)';
    } else {
      this.errorMessage = null;
    }
  }

  private formatTime(value: string): DaysHoursMinutes {
    if (!value?.includes(':')) return {};

    const [days, hours, minutes] = value.split(':').map(Number);
    if (isNaN(days) || isNaN(hours) || isNaN(minutes)) return {};

    return { days, hours, minutes };
  }

  @HostListener('click')
  onClick() {
    this.isComponentClicked = true;
    if (!this.formControl.value) {
      this.formControl.setValue('00:00:00');
    }
  }

  @HostListener('document:click')
  onDocumentClick() {
    if (!this.isComponentClicked && this.formControl.value) {
      this.validateTime(this.formControl.value);
    }
    this.isComponentClicked = false;
  }
}
