<div class="tendered-orders-container">
  <div class="header">
    <span class="header-title">Orders</span>
    <mat-form-field appearance="outline">
      <input matInput placeholder="Search" disabled="true" />
    </mat-form-field>
  </div>
  <div class="content">
    <ng-container>
      <div class="service-content">
        <div class="row-1 row">
          <div class="col col-1">
            <div>Shipment Methods</div>
            <div class="col-content">
              <div class="shipment-type-container">
                <div class="shipment-type-col">
                  <div class="title">Mode</div>
                  <div class="mode-content">{{ shipmentOption?.shipmentMode }}</div>
                </div>
                <div class="shipment-type-col">
                  <div class="title">Type</div>
                  <div class="mode-content">{{ orders ? orders[0]?.shipmentMethod : '' }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-2">
            <div>Services Requested</div>
            <div class="col-content">
              <div class="service">
                {{ service?.serviceName ?? '' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    @for (order of orders; track order._id) {
      <hmt-order-card [order]="order" [viewOnly]="true" [hideCheckBox]="true"></hmt-order-card>
    }
  </div>
</div>
