import { Component } from '@angular/core';
import { EnhancedDatagridActionsRendererParams } from '@shared/types/enhanced-grid-types/enhanced-grid-components.type';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'hmt-enhanced-datagrid-actions-renderer',
  templateUrl: './enhanced-datagrid-actions-renderer.component.html',
  styleUrls: ['./enhanced-datagrid-actions-renderer.component.scss'],
})
export class EnhancedDatagridActionsRendererComponent<TData> implements ICellRendererAngularComp {
  params!: EnhancedDatagridActionsRendererParams<TData>;
  value!: Array<string>;

  deleteCallback!: (data: TData) => void;

  agInit(params: EnhancedDatagridActionsRendererParams<TData>): void {
    this.params = params;
    this.setValue(params);
    this.deleteCallback = params.deleteCallback;
  }

  refresh(params: EnhancedDatagridActionsRendererParams<TData>): boolean {
    this.params = params;
    this.setValue(params);
    return true;
  }

  private setValue(params: EnhancedDatagridActionsRendererParams<TData>) {
    this.value = params.value;
  }

  getFormattedValue(): string {
    return this.value.join(', ');
  }

  onDeleteButtonClick() {
    this.deleteCallback(this.params.data);
  }

  onVersionHistoryButtonClick() {
    this.params.versionHistoryCallback(this.params.data);
  }
}
