import { Component, computed, input, output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { COUNTRY_FLAG_CDN_URL } from '@shared/constants';

@Component({
  selector: 'hmt-requirement-overview-port-pair-card',
  templateUrl: './requirement-overview-port-pair-card.component.html',
  styleUrl: './requirement-overview-port-pair-card.component.scss',
})
export class RequirementOverviewPortPairCardComponent {
  portTypeLabel = input.required<string>();
  cardIndex = input.required<number>();
  expanded = input.required<boolean>();
  showToggleCheck = input.required<boolean>();
  originCountryCode = input<string>();
  destinationCountryCode = input<string>();
  favoriteToggled = input<boolean>();
  totalLikes = input<number>(0);
  totalDislikes = input<number>(0);
  totalComments = input<number>(0);

  showFavoriteButton = input<boolean>(false);
  showLikeDislikeButtons = input<boolean>(false);
  showDeleteButton = input<boolean>(false);
  canVote = input<boolean>(false);
  hasCurrentUserVoted = input<boolean>(false);
  readonly = input<boolean>(false);

  toggleCheck = output<boolean>();
  handleToggleFavorite = output<boolean>();
  handleToggleLike = output<void>();
  handleToggleDislike = output<void>();
  onDelete = output<void>();

  originFlagUrl = computed(() => {
    const code = this.originCountryCode();
    return code ? COUNTRY_FLAG_CDN_URL(code) : '';
  });

  destinationFlagUrl = computed(() => {
    const code = this.destinationCountryCode();
    return code ? COUNTRY_FLAG_CDN_URL(code) : '';
  });

  handleToggleCheck(event: MatCheckboxChange) {
    event.source._elementRef.nativeElement.onclick = (e: Event) => {
      e.stopPropagation();
    };
    this.toggleCheck.emit(event.checked);
  }

  favoriteButtonClicked(event: MatButtonToggleChange) {
    event.source._buttonElement.nativeElement.onclick = (e: Event) => {
      e.stopPropagation();
    };
    this.handleToggleFavorite.emit(event.source.checked);
  }

  likeButtonClicked(event: Event) {
    event.stopPropagation();
    if (!this.canVote()) {
      return;
    }
    this.handleToggleLike.emit();
  }

  dislikeButtonClicked(event: Event) {
    event.stopPropagation();
    this.handleToggleDislike.emit();
  }

  deletePortPairCard(event: Event) {
    event.stopPropagation();
    this.onDelete.emit();
  }
}
