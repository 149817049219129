<div class="general-details-container">
  <div class="col-1">
    <img src="./assets/icons/globe.svg" alt="home icon" />
  </div>
  <div class="col-2">
    <hmt-key-value [keyValue]="{ key: 'Tender Name', value: tenderName() }" style="width: 100%"></hmt-key-value>
    <h3 class="rate-validity-period-heading">Rate Validity Period</h3>
    <div class="row-2 content-style">
      <hmt-key-value
        [keyValue]="{ key: 'From', value: summary() && summary().from ? (summary().from | date: 'dd/MM/yyyy') : '' }"
        style="width: 100%"></hmt-key-value>
      <hmt-key-value
        [keyValue]="{ key: 'To', value: summary() && summary().to ? (summary().to | date: 'dd/MM/yyyy') : '' }"
        style="width: 100%"></hmt-key-value>
      <hmt-key-value
        [keyValue]="{
          key: 'Contract Effective Date',
          value: summary() && summary().effectiveDate ? (summary().effectiveDate | date: 'dd/MM/yyyy') : ''
        }"
        style="width: 100%"></hmt-key-value>
      <hmt-key-value [keyValue]="{ key: 'Usage', value: usageString() }" style="width: 100%"></hmt-key-value>
      <hmt-key-value [keyValue]="{ key: 'Currency', value: currency() }" style="width: 100%"></hmt-key-value>
    </div>
  </div>
</div>
