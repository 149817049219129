import { TenderStates } from '../../enums/tender-status.enum';

const LONG_TERM_TENDER_PANEL_PREFIX = '[Long Term Tender Panel]';

export class FetchLongTermTenderSummaries {
  public static readonly type = `${LONG_TERM_TENDER_PANEL_PREFIX} Fetch Long Term Tender Summaries`;
  constructor(
    public orgId: string,
    public offset: number,
    public limit: number,
    public searchKey?: string
  ) {}
}
export class UpdateLongTermTenderSummaryState {
  public static readonly type = `${LONG_TERM_TENDER_PANEL_PREFIX} Update Long Term Tender Summary`;
  constructor(
    public tenderId: string,
    public longTermTenderSummaryState: TenderStates
  ) {}
}
