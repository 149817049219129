import { inject, Injectable, signal } from '@angular/core';
import { GET_TENDER_NAME_BY_ID_QUERY, GetTenderNameByIdResponse } from '@shared/queries/get-tender-name-by-id.query';
import { Apollo } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TenderNamesService {
  apollo = inject(Apollo);

  tenderNames = signal<Record<string, { name: string; timestamp: number }>>({});
  private pendingRequests = new Map<string, Promise<{ name: string }>>();

  async getTenderName(tenderId: string) {
    const cachedTenderName = this.tenderNames()[tenderId];
    const now = Date.now();
    const FIFTY_NINE_MINUTES = 59 * 60 * 1000;

    if (cachedTenderName && now - cachedTenderName.timestamp < FIFTY_NINE_MINUTES) {
      return cachedTenderName;
    }

    // Check if there's already a pending request for this tenderId
    if (this.pendingRequests.has(tenderId)) {
      return await this.pendingRequests.get(tenderId);
    }

    // Create new request and store it
    const request = firstValueFrom(
      this.apollo.query<GetTenderNameByIdResponse>({
        query: GET_TENDER_NAME_BY_ID_QUERY,
        variables: { tenderId },
      })
    ).then(result => ({
      name: result.data.tender.name,
    }));
    this.pendingRequests.set(tenderId, request);

    try {
      const tenderName = await request;
      this.tenderNames.set({
        ...this.tenderNames(),
        [tenderId]: {
          name: tenderName.name,
          timestamp: now,
        },
      });

      return tenderName;
    } finally {
      // Clean up the pending request
      this.pendingRequests.delete(tenderId);
    }
  }
}
