import { inject, Injectable, signal } from '@angular/core';
import { LocationsService } from '@shared/services/locations.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationNamesService {
  locationsService = inject(LocationsService);

  locationNames = signal<Record<string, { name: string; shortName: string; timestamp: number }>>({});
  private pendingRequests = new Map<string, Promise<{ name: string; shortName: string }>>();

  async getLocationName(locationId: string) {
    const cachedLocationName = this.locationNames()[locationId];
    const now = Date.now();
    const FIFTY_NINE_MINUTES = 59 * 60 * 1000;

    if (cachedLocationName && now - cachedLocationName.timestamp < FIFTY_NINE_MINUTES) {
      return cachedLocationName;
    }

    // Check if there's already a pending request for this locationId
    if (this.pendingRequests.has(locationId)) {
      return await this.pendingRequests.get(locationId);
    }

    // Create new request and store it
    const request = firstValueFrom(this.locationsService.getLocationNameById(locationId)).then(name => ({
      name: name.name,
      shortName: name.shortName,
    }));
    this.pendingRequests.set(locationId, request);

    try {
      const locationName = await request;
      this.locationNames.set({
        ...this.locationNames(),
        [locationId]: {
          name: locationName.name,
          shortName: locationName.shortName,
          timestamp: now,
        },
      });

      return locationName;
    } finally {
      // Clean up the pending request
      this.pendingRequests.delete(locationId);
    }
  }
}
