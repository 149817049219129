import { inject, Injectable } from '@angular/core';
import { GraphqlClientService } from '@core/services/graphql-client.service';
import { DEFAULT_OPTION_COUNT } from '@shared/constants';
import {
  GET_ALL_CURRENCIES_QUERY,
  GET_CONTAINER_TYPES_QUERY,
  GET_COUNTRIES_QUERY,
  GET_PAGINATED_CHILD_ORGANIZATIONS_QUERY,
  GET_PORTS_BY_COUNTRY_AND_SEARCH_KEY_QUERY,
} from '@shared/gql-shared-queries';
import { Country } from '@shared/models/country.model';
import { Location } from '@shared/models/location.model';
import { PageInfo } from '@shared/models/paginated-response.model';
import { Currency } from 'app/modules/organizations-manager/models/currency.model';
import {
  GET_PORT_PAIR_TEMPLATES_QUERY,
  GET_SMART_RATE_CARD_BY_RATE_CARD_TYPE_AND_ORG_ID_AND_CONTRACTED_PARTY_ID_QUERY,
  GET_SMART_RATE_CARD_BY_RATE_CARD_TYPE_AND_ORG_ID_QUERY,
  SAVE_PORT_PAIR_TEMPLATE_MUTATION,
} from 'app/modules/queries/contract-management-gql.queries';
import { FourPLCustomer } from 'app/modules/wizard/models/4pl-customer.model';
import { filter, map, Observable, of, take } from 'rxjs';
import { LocationPairTemplateType } from '../enums/location-pair-template.enum';
import { LocationPairTemplate } from '../models/long-term-contract/location-pair-template.model';
import { GetPortPairTemplatesInput } from '../models/long-term-contract/port-pair-template-input.model';

@Injectable({
  providedIn: 'root',
})
export class ContractManagementService {
  graphqlClient = inject(GraphqlClientService);

  loadCountries() {
    return this.graphqlClient.query<{ findCountries: Country[] }, undefined>(GET_COUNTRIES_QUERY).pipe(
      map(result => result.findCountries),
      filter(countries => !!countries),
      take(1)
    );
  }

  loadLocationsByCountryAndSearchKey(
    countryCode: string,
    searchKey: string = '',
    locationType: string,
    parentLocationId: string = '',
    offset: number = 0,
    limit: number = DEFAULT_OPTION_COUNT
  ) {
    if (!countryCode) {
      return of([]);
    }

    return this.graphqlClient
      .query<
        { getLocationsByCountryCodeAndLocationType: { items: Location[]; pageInfo: PageInfo } },
        {
          countryCode: string;
          searchKey: string;
          locationType: string;
          parentLocationId: string;
          offset: number;
          limit: number;
        }
      >(GET_PORTS_BY_COUNTRY_AND_SEARCH_KEY_QUERY, {
        countryCode,
        searchKey,
        locationType: locationType,
        parentLocationId,
        offset,
        limit,
      })
      .pipe(map(result => result.getLocationsByCountryCodeAndLocationType?.items || []));
  }

  fetchContainerTypes() {
    return this.graphqlClient
      .query<{ containerTypes: { _id: string; name: string }[] }, undefined>(GET_CONTAINER_TYPES_QUERY)
      .pipe(
        take(1),
        map(result => result.containerTypes)
      );
  }

  fetchSmartRateCardByRateCardTypeAndOrgId(rateCardType: string, orgId: string) {
    return this.graphqlClient
      .query<
        { findSmartRateCardByRateCardTypeAndOrgId: FourPLCustomer[] },
        { rateCardType: string; orgId: string }
      >(GET_SMART_RATE_CARD_BY_RATE_CARD_TYPE_AND_ORG_ID_QUERY, { rateCardType, orgId })
      .pipe(map(result => result.findSmartRateCardByRateCardTypeAndOrgId));
  }

  fetchSmartRateCardByRateCardTypeAndOrgIdAndContractedPartyId(
    rateCardType: string,
    orgId: string,
    contractedPartyId: string
  ) {
    return this.graphqlClient
      .query<
        {
          findSmartRateCardByRateCardTypeAndOrgIdAndContractedPartyId: {
            rateCardData: {
              name: string;
              _id: string;
            };
          }[];
        },
        { rateCardType: string; orgId: string; contractedPartyId: string }
      >(GET_SMART_RATE_CARD_BY_RATE_CARD_TYPE_AND_ORG_ID_AND_CONTRACTED_PARTY_ID_QUERY, {
        rateCardType,
        orgId,
        contractedPartyId,
      })
      .pipe(map(result => result.findSmartRateCardByRateCardTypeAndOrgIdAndContractedPartyId));
  }

  fetchPaginatedChildOrganizations(
    parentOrgId: string,
    pageSize: number = DEFAULT_OPTION_COUNT,
    pageNum: number = 0,
    searchKey: string = ''
  ) {
    return this.graphqlClient
      .query<
        { getPaginatedNestedChildOrgs: { items: { _id: string; orgName: string }[]; pageInfo: PageInfo } },
        {
          getPaginatedNestedChildOrgsInput: {
            parentOrgId: string;
            pageSize: number;
            pageNum: number;
            searchKey: string;
          };
        }
      >(GET_PAGINATED_CHILD_ORGANIZATIONS_QUERY, {
        getPaginatedNestedChildOrgsInput: {
          parentOrgId: parentOrgId,
          pageSize,
          pageNum,
          searchKey,
        },
      })
      .pipe(map(result => result.getPaginatedNestedChildOrgs.items));
  }

  fetchAllCurrencies() {
    return this.graphqlClient
      .query<{ getAllCurrencies: Currency[] }, undefined>(GET_ALL_CURRENCIES_QUERY)
      .pipe(map(result => result.getAllCurrencies));
  }

  getPortPairTemplates(getPortPairTemplatesInput: GetPortPairTemplatesInput): Observable<LocationPairTemplate[]> {
    return this.graphqlClient
      .query<
        { findLocationPairTemplatesByFilter: { items: LocationPairTemplate[]; pageInfo: PageInfo } },
        {
          locationPairTemplateFilterInput: {
            orgId: string;
            templateType: LocationPairTemplateType;
            searchKey: string;
          };
          offset: number;
          limit: number;
        }
      >(GET_PORT_PAIR_TEMPLATES_QUERY, {
        locationPairTemplateFilterInput: {
          orgId: getPortPairTemplatesInput.orgId,
          templateType: getPortPairTemplatesInput.templateType,
          searchKey: getPortPairTemplatesInput.searchKey,
        },
        offset: getPortPairTemplatesInput.offset,
        limit: getPortPairTemplatesInput.limit,
      })
      .pipe(map(result => result.findLocationPairTemplatesByFilter.items));
  }

  savePortPairTemplate(locationPairTemplate: LocationPairTemplate) {
    return this.graphqlClient.mutation<{ _id: string }, { locationPairTemplateInput: LocationPairTemplate }>(
      SAVE_PORT_PAIR_TEMPLATE_MUTATION,
      { locationPairTemplateInput: locationPairTemplate }
    );
  }
}
