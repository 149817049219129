<div class="general-details-container">
  <div class="col-1">
    <img src="./assets/icons/globe.svg" alt="home icon" />
  </div>
  <div class="col-2">
    @for (section of sections(); track section) {
      @if (section.groupLabel) {
        <div class="section-label">{{ section.groupLabel }}</div>
      }
      <div class="row-2 content-style w-100">
        @for (item of section.items; track item) {
          @if (item.isStatusLabel) {
            <div class="tender-status-container key-value-container">
              <div class="key">{{ item.label }}</div>
              <div class="status" [ngClass]="getStatusClass(item.value)">{{ item.value | statusToLower }}</div>
            </div>
          } @else {
            @if (item.countDown) {
              <div class="key-value-container">
                <div class="key">{{ item.label }}</div>
                <hmt-count-down class="value" [endDate]="item.countDownFrom"></hmt-count-down>
              </div>
            } @else {
              <hmt-key-value style="width: 100%" [keyValue]="{ key: item.label, value: item.value }"> </hmt-key-value>
            }
          }
        }
      </div>
    }
  </div>
</div>
