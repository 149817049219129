import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'hmt-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountDownComponent implements OnInit, OnDestroy {
  @Input() endDate: Date;

  remainingTime: string = '';
  ADD_LEADING_ZERO_AFTER_TEN = 10;

  private subscription: Subscription;

  ngOnInit() {
    if (this.endDate) {
      this.startTimer();
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private startTimer() {
    this.calculateTimeRemaining();
    this.subscription = interval(1000).subscribe(() => {
      this.calculateTimeRemaining();
    });
  }

  private calculateTimeRemaining() {
    const now = new Date().getTime();
    const end = new Date(this.endDate).getTime();
    const timeRemaining = Math.max(0, end - now);

    if (timeRemaining > 0) {
      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
      this.remainingTime = `${days < this.ADD_LEADING_ZERO_AFTER_TEN ? '0' + days : days}D:${hours < this.ADD_LEADING_ZERO_AFTER_TEN ? '0' + hours : hours}H:${minutes < this.ADD_LEADING_ZERO_AFTER_TEN ? '0' + minutes : minutes}M:${seconds < this.ADD_LEADING_ZERO_AFTER_TEN ? '0' + seconds : seconds}S`;
    } else {
      this.remainingTime = '00D:00H:00M:00S';
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }
  }
}
