import { Component, computed, inject, input, signal } from '@angular/core';
import { NotificationService } from '@core/services/notification/notification.service';
import { Store } from '@ngxs/store';
import { LongTermRatesContract } from 'app/modules/contract-management/models/long-term-contract.model';
import { ContractedParties } from 'app/modules/contract-view/components/models/contracted-parties.model';
import { JobContractService } from 'app/modules/contract-view/services/job-contract-.service';

@Component({
  selector: 'hmt-long-term-contract-summary',
  templateUrl: './long-term-contract-summary.component.html',
  styleUrl: './long-term-contract-summary.component.scss',
})
export class LongTermContractSummaryComponent {
  notificationService = inject(NotificationService);
  jobContractService = inject(JobContractService);
  store = inject(Store);

  tenderName = input.required<string>();
  summary = input<LongTermRatesContract['ratesValidityPeriod']>();
  usage = input.required<boolean>();
  currency = input.required<string>();

  usageString = computed(() => (this.usage() ? 'Unlimited' : 'Limited'));

  contractParties = signal<ContractedParties | null>(null);
  viewingAsA = signal<'SELLER' | 'BUYER' | null>(null);
  generalJobInformationKeyValueRows = signal<{ key: string; value: string }[]>([]);

  private generateGeneralJobDetails() {
    const party = this.viewingAsA() === 'SELLER' ? this.contractParties()?.seller : this.contractParties()?.buyer;

    this.generalJobInformationKeyValueRows.set([
      { key: 'Job File Name', value: party.jobFileInfo?.title || '--' },
      { key: 'Job Type', value: this.generateJobType(party.jobFileInfo?.workflowId) },
      { key: 'Job File Group', value: party.jobFileInfo?.jobGroupName || '--' },
      { key: 'Job Reference Number', value: party.jobFileInfo.jobRefId || '--' },
      { key: 'Customer Reference Number', value: party.jobFileInfo?.customerReferenceNo || '--' },
    ]);
  }

  private generateJobType(workflowId: string) {
    if (workflowId === 'IL') {
      return 'International Logistics';
    } else {
      return '--';
    }
  }
}
