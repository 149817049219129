import { Component, inject, input, output } from '@angular/core';
import { DialogHandlerService } from '@core/services/dialog-handler.service';
import { NotificationService } from '@core/services/notification/notification.service';
import { FileHandlerService } from '@shared/services/file-handler.service';
import { ContractRequirementDocument } from 'app/modules/contract-management/models/long-term-contract/long-term-contract-requirement.model';
import { UploadedFileType } from 'app/modules/wizard/models/uploaded-file.type';
import { firstValueFrom } from 'rxjs';
import { OrgDocumentPreviewDialogComponent } from '../org-document-preview-dialog/org-document-preview-dialog.component';

@Component({
  selector: 'hmt-uploaded-contract-requirement-document',
  templateUrl: './uploaded-contract-requirement-document.component.html',
  styleUrl: './uploaded-contract-requirement-document.component.scss',
})
export class UploadedContractRequirementDocumentComponent {
  readonly dialogService = inject(DialogHandlerService);
  readonly fileHandlerService = inject(FileHandlerService);
  readonly notificationService = inject(NotificationService);

  file = input.required<{
    customFileName: string;
    file: UploadedFileType | ContractRequirementDocument;
  }>();

  deleteDisabled = input(false);

  deleteButtonClicked = output<{
    customFileName: string;
    file: UploadedFileType | ContractRequirementDocument;
  }>();

  onDeleteBtnClicked() {
    this.deleteButtonClicked.emit(this.file());
  }

  openDocumentPreviewDialog() {
    this.dialogService.openDialog(OrgDocumentPreviewDialogComponent, {
      file: this.file().file,
    });
  }

  async downloadDocument() {
    const a = document.createElement('a');
    a.href = (await firstValueFrom(this.fileHandlerService.fetchFile(this.file().file.fileId))).url;
    a.download = this.file().customFileName;
    a.target = '_blank';
    a.click();
  }
}
