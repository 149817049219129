import { Component, OnInit, inject } from '@angular/core';
import { GraphqlClientService } from '@core/services/graphql-client.service';
import { FieldWrapper } from '@ngx-formly/core';
import { Store } from '@ngxs/store';
import { GET_SERVICE_CONFIGS_QUERY } from '@shared/gql-shared-queries';
import { map, take, tap } from 'rxjs';
import { CurrentTenderState } from '../../../current-tender/store/current-tender/current-tender.state';
import { ServiceConfig } from '../../models/service-config.model';
import { FetchAllOrdersByTenderId } from '../../store/wizard.actions';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-tendered-orders-container',
  templateUrl: './tendered-orders-container.component.html',
  styleUrl: './tendered-orders-container.component.scss',
})
export class TenderedOrdersContainerComponent extends FieldWrapper implements OnInit {
  orders$ = inject(Store).select(WizardState.getOrders);
  currentTender$ = inject(Store).select(CurrentTenderState.getTender);

  private readonly store$: Store = inject(Store);
  private readonly gqlService = inject(GraphqlClientService);

  smartenedJobFile: boolean;
  serviceConfigs: ServiceConfig[] = [];

  ngOnInit(): void {
    this.currentTender$
      .pipe(
        take(1),
        map(tender => {
          this.store$.dispatch(new FetchAllOrdersByTenderId(tender._id));
          this.smartenedJobFile = tender.smartened;
        })
      )
      .subscribe();

    this.gqlService
      .query<{ hmtServiceConfigs: ServiceConfig[] }, null>(GET_SERVICE_CONFIGS_QUERY, null)
      .pipe(
        take(1),
        tap((data: { hmtServiceConfigs: ServiceConfig[] }) => {
          this.serviceConfigs = data.hmtServiceConfigs;
        })
      )
      .subscribe();
  }

  getServiceName(serviceId: string): string {
    return this.serviceConfigs?.find(service => service._id === serviceId)?.serviceName || '';
  }
}
