<div class="enhanced-datagrid-container">
  <ag-grid-angular
    #agGrid
    [rowData]="rowData()"
    [columnDefs]="preparedColDefs()"
    class="ag-theme-quartz enhanced-datagrid"
    [style]="styleString()"
    (cellEditingStopped)="handleCellEditingStopped($event)"
    [suppressRowTransform]="true"
    [suppressMovableColumns]="true"
    [alwaysShowVerticalScroll]="true"
    [loading]="loading()" />
</div>
