import { Component, input } from '@angular/core';
import { TenderSummaryGroup } from 'app/modules/tender-wizard/models/tender-summary.model';
import { getTenderChipStatusClass } from 'app/utils/status-chip.util';

@Component({
  selector: 'hmt-long-term-tender-summary',
  templateUrl: './long-term-tender-summary.component.html',
  styleUrl: './long-term-tender-summary.component.scss',
})
export class LongTermTenderSummaryComponent {
  sections = input<TenderSummaryGroup[]>();

  getStatusClass(status: string) {
    return getTenderChipStatusClass(status);
  }
}
