import { Component, input } from '@angular/core';

@Component({
  selector: 'hmt-shipment-method-block',
  templateUrl: './shipment-method-block.component.html',
  styleUrl: './shipment-method-block.component.scss',
})
export class ShipmentMethodBlockComponent {
  mode = input<string>('');
  containerTypes = input<string[]>([]);
  typeLabel = input<string>('');
}
