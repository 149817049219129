import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EXTEND_TENDER_MUTATION } from '@shared/gql-shared-queries';
import { Apollo } from 'apollo-angular';
import { catchError, of, tap } from 'rxjs';
import { CurrentTenderStateModel } from '../../models/currentt-state.modelt';
import { ClearCurrentTender, ExtendTender, SetTender, SetTenderingPageLoading } from './current-tender.actions';

const defaults: CurrentTenderStateModel = {
  currentTender: null,
  isTenderingPageLoading: false,
};

@State<CurrentTenderStateModel>({
  name: 'currentTender',
  defaults,
})
@Injectable()
export class CurrentTenderState {
  constructor(private readonly apollo: Apollo) {}

  @Selector()
  static getTender(state: CurrentTenderStateModel) {
    return state.currentTender;
  }

  @Selector()
  static getTenderStatus(state: CurrentTenderStateModel) {
    return state.currentTender?.tenderStatus;
  }

  @Selector()
  static getRejectedInfo(state: CurrentTenderStateModel) {
    return state.currentTender?.rejectedInfo;
  }

  @Selector()
  static getTerminatedInfo(state: CurrentTenderStateModel) {
    return state.currentTender?.terminatedInfo;
  }

  @Selector()
  static isTenderingPageLoading(state: CurrentTenderStateModel) {
    return state.isTenderingPageLoading;
  }

  @Action(SetTender)
  setTender({ patchState, getState }: StateContext<CurrentTenderStateModel>, { tender, updateType }: SetTender) {
    const currentTender = getState().currentTender;

    if ((updateType === 'jobUpdate' || updateType === 'entityUpdate') && currentTender.canAccessTender === true) {
      patchState({ currentTender: { ...tender, canAccessTender: true }, isTenderingPageLoading: false });
    } else if (updateType === 'gql' && tender.canAccessTender === true) {
      patchState({ currentTender: { ...tender, canAccessTender: true }, isTenderingPageLoading: false });
    } else {
      patchState({ currentTender: tender, isTenderingPageLoading: false });
    }
  }

  @Action(ExtendTender)
  extendTender({ patchState }: StateContext<CurrentTenderStateModel>, { tenderId, extendedToDate }: ExtendTender) {
    return this.apollo
      .mutate({
        mutation: EXTEND_TENDER_MUTATION,
        variables: {
          tenderId,
          extendedToDate,
        },
      })
      .pipe(
        tap(response => {
          const tender = response.data['extendTender'];
          if (tender) {
            // TODO: show success toast
          }
        }),
        catchError(err => {
          console.error('Error extending tender', err);
          return of(null);
        })
      );
  }

  @Action(ClearCurrentTender)
  clearCurrentTender({ patchState }: StateContext<CurrentTenderStateModel>) {
    patchState(defaults);
  }

  @Action(SetTenderingPageLoading)
  setTenderingPageLoading(ctx: StateContext<CurrentTenderStateModel>, action: SetTenderingPageLoading) {
    ctx.patchState({
      isTenderingPageLoading: action.loading,
    });
  }
}
