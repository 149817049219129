import { gql } from 'apollo-angular';

export const DELETE_FLIGHT_SCHEDULE_QUERY = gql`
  mutation DeleteFlightSchedule($scheduleId: String!) {
    deletedFlightSchedule: deleteFlightSchedule(scheduleId: $scheduleId) {
      _id
    }
  }
`;

export interface DeleteFlightScheduleResponse {
  deletedFlightSchedule: {
    _id: string;
  };
}
