import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SCOPES } from '@configs/scopes';
import { checkScopesReactive } from '@core/guards/auth.guard';
import { FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';
import { Store } from '@ngxs/store';
import { SetNextStep } from 'app/json-schema-forms/store/workflow.actions';
import { WorkflowState } from 'app/json-schema-forms/store/workflow.state';
import { combineLatest, filter, map, mergeMap, of, Subject, take, takeUntil, tap } from 'rxjs';
import { JobFile } from '../../models/job-file.model';
import { Shipment } from '../../models/shipment.model';
import { WizardService } from '../../services/wizard.service';
import { UpdateJobSmartenedInJobFile } from '../../store/wizard.actions';
import { WizardState } from '../../store/wizard.state';
import { Tab } from '../service-view/service-view.component';
import { ShipmentCreationSelectorComponent } from '../shipment-creation-selector/shipment-creation-selector.component';

@Component({
  selector: 'hmt-orders-shipments-tab-group',
  templateUrl: './orders-shipments-tab-group.component.html',
  styleUrl: './orders-shipments-tab-group.component.scss',
})
export class OrdersShipmentsTabGroupComponent extends FieldWrapper implements OnInit, OnDestroy {
  store$ = inject(Store);
  referenceDocumentOrders$ = this.store$.select(WizardState.getPaginatedOrders);
  shipmentOrders$ = this.store$.select(WizardState.getShipmentOrders);
  jobFile$ = this.store$.select(WizardState.getJobFile);
  orders$ = this.jobFile$.pipe(
    filter((jobFile: JobFile) => !!jobFile),
    mergeMap((jobFile: JobFile) => {
      if (jobFile?.parentJobRefId) {
        return this.wizardService
          .findJobFileByJobRefId(jobFile.parentJobRefId)
          .pipe(mergeMap(parentJobFile => of([parentJobFile, jobFile])));
      }
      return of([null, jobFile]);
    }),
    mergeMap(([parentJobFile, _]: [JobFile, JobFile]) => {
      if (parentJobFile) {
        if (parentJobFile?.smartened) {
          return this.shipmentOrders$;
        } else {
          return this.referenceDocumentOrders$;
        }
      }
      return this.referenceDocumentOrders$;
    })
  );
  shipments$ = this.store$.select(WizardState.getShipments);
  currentStep$ = this.store$.select(WorkflowState.getCurrentStep);
  canCreateBid$ = checkScopesReactive({ oneOf: [SCOPES.BIDS.CREATE] });
  wizardGeneralScopes$ = checkScopesReactive({ oneOf: [SCOPES.JOB_FILES.CREATE] });
  serviceCardsValidationsStatus$ = this.store$.select(WizardState.getServiceCardsValidationsStatus);

  private onDestroy$ = new Subject<void>();
  private readonly matDialog = inject(MatDialog);
  private cdr = inject(ChangeDetectorRef);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private wizardService = inject(WizardService);

  fields: FormlyFieldConfig[] = [];
  tabs: Tab[] = [];
  selectedTab = 0;
  fm;
  parentJobRefId: string;

  ngOnInit(): void {
    this.fm = this.form;

    this.wizardGeneralScopes$
      .pipe(
        take(1),
        tap(wizardGeneralScopes => {
          this.formsGenerator(!wizardGeneralScopes);
        })
      )
      .subscribe();

    this.activatedRoute.queryParams
      .pipe(
        take(1),
        tap(params => {
          if (params['tab']) {
            const tabIndex = this.tabs.findIndex(tab => tab.label.toLowerCase() === params['tab'].toLowerCase());
            if (tabIndex !== -1) {
              this.selectedTab = tabIndex;
              this.onTabChanged(tabIndex);
            }
          }
        })
      )
      .subscribe();

    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        filter(event => event.url.includes('/wizard')),
        takeUntil(this.onDestroy$)
      )
      .subscribe(this.handleNavigationEnd);
    this.jobFile$
      .pipe(
        take(1),
        tap(jobFile => (this.parentJobRefId = jobFile?.parentJobRefId))
      )
      .subscribe();
  }

  private handleNavigationEnd = (event: NavigationEnd): void => {
    const [, , jobRefId, , stepId] = event.url.split('/');
    if (jobRefId && stepId) {
      this.updateSelectedTabFromQueryParams();
    }
  };

  private updateSelectedTabFromQueryParams(): void {
    this.activatedRoute.queryParams
      .pipe(
        take(1),
        map(params => params['tab']),
        filter(Boolean)
      )
      .subscribe(tabParam => {
        const tabIndex = this.findTabIndex(tabParam);
        if (tabIndex !== -1) {
          this.selectedTab = tabIndex;
          this.onTabChanged(tabIndex);
        }
      });
  }

  private findTabIndex(tabParam: string): number {
    return this.tabs.findIndex(tab => tab.label.toLowerCase() === tabParam.toLowerCase());
  }

  formsGenerator(scopesAvailable: boolean) {
    const ordersNextButton = this.findFormlyFieldConfig(this.field.fieldGroup, 'orderNextButton');
    const shipmentNextButton = this.findFormlyFieldConfig(this.field.fieldGroup, 'shipmentNextButton');
    const exitButton = this.findFormlyFieldConfig(this.field.fieldGroup, 'exit');

    if (ordersNextButton) {
      this.setupOrdersNextButton(ordersNextButton, scopesAvailable);
    }

    if (shipmentNextButton) {
      this.setupShipmentNextButton(shipmentNextButton, scopesAvailable);
    }

    if (exitButton) {
      exitButton.props['onClick'] = () => this.router.navigate(['/dashboard']);
    }

    this.field.fieldGroup.forEach((field: FormlyFieldConfig) => {
      this.tabs.push({
        label: field.templateOptions.label,
        fields: [field],
        disabled: true,
        model: this.model,
        formGroup: new FormGroup({}),
      });
    });
  }

  setupOrdersNextButton(button: FormlyFieldConfig, disabled: boolean) {
    button.hooks = {
      onInit: field => {
        this.enableButtonWhenOrdersExist(field);
      },
    };
    button.props['onClick'] = () => this.handleOrdersNextButtonClick();
  }

  enableButtonWhenOrdersExist(field: FormlyFieldConfig) {
    combineLatest([this.orders$, this.wizardGeneralScopes$])
      .pipe(
        takeUntil(this.onDestroy$),
        tap(([orders, wizardGeneralScopes]) => {
          if (orders && orders.items.length > 0) {
            field.props.disabled = !(wizardGeneralScopes && orders);
            this.enableAllTabs();
          }
        })
      )
      .subscribe();
  }

  enableAllTabs() {
    this.tabs.forEach(tab => (tab.disabled = false));
  }

  handleOrdersNextButtonClick() {
    this.shipments$
      .pipe(
        take(1),
        tap(shipments => {
          if (!shipments || shipments.length === 0) {
            this.openShipmentCreationDialog();
          } else {
            this.moveToNextTab();
          }
        })
      )
      .subscribe();
  }

  openShipmentCreationDialog() {
    const dialogRef = this.matDialog.open(ShipmentCreationSelectorComponent, {
      data: {
        parentJobRefId: this.parentJobRefId,
      },
    });

    this.handleDialogActions(dialogRef);
  }

  handleDialogActions(dialogRef: MatDialogRef<ShipmentCreationSelectorComponent>) {
    dialogRef.componentInstance.createShipment
      .pipe(
        take(1),
        tap(() => this.moveToNextTab())
      )
      .subscribe();

    dialogRef.componentInstance.continueToAssignServices
      .pipe(
        takeUntil(this.onDestroy$),
        tap(event => {
          this.store$.dispatch(new UpdateJobSmartenedInJobFile(this.activatedRoute.snapshot.params['jobRefId'], false));
          this.nextPage(event);
        })
      )
      .subscribe();
  }

  moveToNextTab() {
    if (this.selectedTab < this.tabs.length - 1) {
      this.selectedTab++;
      this.addTabNameToUrl(this.selectedTab);
      this.cdr.detectChanges();
    }
  }

  //root level next button
  setupShipmentNextButton(button: FormlyFieldConfig, disabled: boolean) {
    button.hooks = {
      onInit: field => {
        this.enableButtonWhenValidShipmentExists(field);
      },
    };
    button.props['onClick'] = () => this.nextPage(button.props['nextScreen']);
  }

  enableButtonWhenValidShipmentExists(field: FormlyFieldConfig) {
    combineLatest([this.shipments$, this.wizardGeneralScopes$, this.serviceCardsValidationsStatus$])
      .pipe(
        takeUntil(this.onDestroy$),
        tap(([shipments, wizardGeneralScopes, serviceCardsValidationsStatus]) => {
          field.props.disabled = !(
            wizardGeneralScopes &&
            shipments &&
            shipments.length &&
            shipments.every(
              shipment =>
                shipment?.orders &&
                shipment?.orders.length > 0 &&
                shipment.serviceOptions &&
                shipment.serviceOptions.length > 0 &&
                shipment.serviceOptions.every(
                  serviceOption => serviceOption.services && serviceOption.services.length > 0
                )
            )
          );
          field.props.disabled = !serviceCardsValidationsStatus;
        })
      )
      .subscribe();
  }

  hasValidShipment(shipments: Shipment[]): boolean {
    return shipments.some(
      shipment =>
        shipment?.orders &&
        shipment?.orders.length > 0 &&
        shipment?.serviceOptions &&
        shipment.serviceOptions?.length > 0
    );
  }

  onTabChanged(index) {
    this.selectedTab = index;
    this.cdr.detectChanges();
    this.addTabNameToUrl(index);
  }

  addTabNameToUrl(index) {
    const tabName = this.tabs[index].label.toLowerCase();
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { tab: tabName },
      queryParamsHandling: 'merge',
    });
  }

  findFormlyFieldConfig(fields: FormlyFieldConfig[], key: string): FormlyFieldConfig | undefined {
    for (const field of fields) {
      if (field.key === key) {
        return field;
      }

      // If the field has nested fields (like in fieldGroup), recursively search there
      if (field.fieldGroup && field.fieldGroup.length) {
        const foundField = this.findFormlyFieldConfig(field.fieldGroup, key);
        if (foundField) {
          return foundField;
        }
      }
    }
    return undefined;
  }

  nextPage(nextScreen) {
    this.store$.dispatch(new SetNextStep(nextScreen));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
