import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { NewServiceRequest } from 'app/modules/task-feed/models/new-service-request.model';
import { Order } from 'app/modules/wizard/models/order.model';
import { ServiceInfo } from 'app/modules/wizard/models/service-info.model';
import { ServiceOption } from 'app/modules/wizard/models/service-option.model';
import { Shipment } from 'app/modules/wizard/models/shipment.model';
import { FetchShipmentsByIds } from 'app/modules/wizard/store/wizard.actions';
import { WizardState } from 'app/modules/wizard/store/wizard.state';
import { mergeMap, take, tap } from 'rxjs';

@Component({
  selector: 'hmt-long-term-contract-orders',
  templateUrl: './long-term-contract-orders.component.html',
  styleUrl: './long-term-contract-orders.component.scss',
})
export class LongTermContractOrdersComponent extends FieldWrapper implements OnInit {
  store$ = inject(Store);
  actions$ = inject(Actions);
  private cdr = inject(ChangeDetectorRef);

  orders: Order[];
  shipmentOption: ServiceOption;
  service: ServiceInfo;

  ngOnInit(): void {
    this.generateData();
  }

  private generateData(): void {
    const serviceRequest: NewServiceRequest = this.formState.serviceRequest;

    if (serviceRequest && serviceRequest?.metadata['shipmentId']) {
      this.store$.dispatch(new FetchShipmentsByIds([serviceRequest?.metadata['shipmentId']]));
    }

    this.actions$
      .pipe(
        ofActionCompleted(FetchShipmentsByIds),
        take(1),
        mergeMap(() => this.store$.select(WizardState.getShipments)),
        tap((shipments: Shipment[]) => {
          const relatedShipment = shipments.find(shipment => serviceRequest?.metadata['shipmentId'] === shipment._id);
          if (relatedShipment) {
            this.orders = relatedShipment.orders;
            this.shipmentOption = relatedShipment?.serviceOptions.find(
              option => option._id === serviceRequest?.metadata['serviceOptionId']
            );
            if (this.shipmentOption) {
              this.service = this.shipmentOption?.services.find(
                service => service.plannedServiceId === serviceRequest?.metadata['serviceId']
              );
            }
          }
          this.orders = shipments[0]?.orders;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }
}
