import { Component, computed, input, output, viewChild } from '@angular/core';
import {
  EnhancedCellEditingStoppedEvent,
  EnhancedColDef,
} from '@shared/types/enhanced-grid-types/enhanced-grid-common.type';
import { AgGridAngular } from 'ag-grid-angular';
import { CellEditingStoppedEvent } from 'ag-grid-community';
import { EnhancedDatagridDateEditorComponent } from './components/enhanced-datagrid-date-editor/enhanced-datagrid-date-editor.component';
import { EnhancedDatagridDateRendererComponent } from './components/enhanced-datagrid-date-renderer/enhanced-datagrid-date-renderer.component';
import { EnhancedDatagridDatetimeEditorComponent } from './components/enhanced-datagrid-datetime-editor/enhanced-datagrid-datetime-editor.component';
import { EnhancedDatagridDatetimeRendererComponent } from './components/enhanced-datagrid-datetime-renderer/enhanced-datagrid-datetime-renderer.component';
import { EnhancedDatagridNumberEditorComponent } from './components/enhanced-datagrid-number-editor/enhanced-datagrid-number-editor.component';
import { EnhancedDatagridOptionEditorComponent } from './components/enhanced-datagrid-option-editor/enhanced-datagrid-option-editor.component';
import { EnhancedDatagridTextEditorComponent } from './components/enhanced-datagrid-text-editor/enhanced-datagrid-text-editor.component';

@Component({
  selector: 'hmt-enhanced-datagrid',
  templateUrl: './enhanced-datagrid.component.html',
  styleUrls: ['./enhanced-datagrid.component.scss'],
})
export class EnhancedDatagridComponent<RowType> {
  agGrid = viewChild<AgGridAngular<RowType>>('agGrid');

  rowData = input.required<RowType[]>();
  colDefs = input.required<EnhancedColDef<RowType>[]>();
  loading = input<boolean>();
  height = input<string>('350px');

  onCellEditingStopped = output<EnhancedCellEditingStoppedEvent<RowType>>();
  onRowDataChange = output<RowType>();

  preparedColDefs = computed(() => {
    return this.colDefs().map(colDef => {
      let enhancedColDef: EnhancedColDef<RowType>;
      if (colDef.colType === 'option') {
        enhancedColDef = { ...colDef, cellEditor: EnhancedDatagridOptionEditorComponent };
      } else if (colDef.colType === 'datetime') {
        enhancedColDef = {
          ...colDef,
          cellEditor: EnhancedDatagridDatetimeEditorComponent,
          cellRenderer: EnhancedDatagridDatetimeRendererComponent,
        };
      } else if (colDef.colType === 'date') {
        enhancedColDef = {
          ...colDef,
          cellEditor: EnhancedDatagridDateEditorComponent,
          cellRenderer: EnhancedDatagridDateRendererComponent,
        };
      } else if (colDef.colType === 'text') {
        enhancedColDef = {
          ...colDef,
          cellEditor: EnhancedDatagridTextEditorComponent,
        };
      } else if (colDef.colType === 'number') {
        enhancedColDef = {
          ...colDef,
          cellEditor: EnhancedDatagridNumberEditorComponent,
        };
      } else {
        enhancedColDef = colDef;
      }
      delete enhancedColDef.colType;

      if (enhancedColDef.sortable === undefined) {
        enhancedColDef.sortable = false;
      }
      if (enhancedColDef.cellEditorPopup === undefined) {
        enhancedColDef.cellEditorPopup = true;
      }
      return enhancedColDef;
    });
  });

  styleString = computed(() => {
    return `--table-body-height: calc(${this.height()} - 50px);height: ${this.height()}`;
  });

  handleCellEditingStopped(event: CellEditingStoppedEvent<RowType>) {
    const field = event.colDef.field as unknown as keyof RowType;
    const newValue = event.newValue as RowType[typeof field];
    const oldValue = event.oldValue as RowType[typeof field];
    const eventValue = { idOfRow: event.data['_id'] as string, field, newValue, oldValue };
    this.onCellEditingStopped.emit(eventValue);
    this.onRowDataChange.emit(event.data);
  }

  addRow(row: RowType) {
    const grid = this.agGrid();
    if (grid) {
      grid.api.applyTransaction({ add: [row] });
    }
  }
}
