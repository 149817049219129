import { gql } from 'apollo-angular';

export const GET_TENDER_NAME_BY_ID_QUERY = gql`
  query FindTenderById($tenderId: String!) {
    tender: findTenderById(tenderId: $tenderId) {
      _id
      name
    }
  }
`;

export interface GetTenderNameByIdResponse {
  tender: {
    _id: string;
    name: string;
  };
}
