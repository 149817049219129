import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { SCOPES } from '@configs/scopes';
import { checkScopesReactive } from '@core/guards/auth.guard';
import { DialogHandlerService } from '@core/services/dialog-handler.service';
import { FieldWrapper } from '@ngx-formly/core';
import { Select, Store } from '@ngxs/store';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { DEFAULT_DEBOUNCE_TIME, PAGE_SIZE, PAGE_SIZE_OPTIONS } from '@shared/constants';
import { Pageable } from '@shared/models/pageable.model';
import { EntityMap } from '@shared/types';
import { WorkflowScreen } from 'app/json-schema-forms/store/models/workflow-screen.model';
import { debounceTime, map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { JobFile } from '../../models/job-file.model';
import { Order } from '../../models/order.model';
import { ServiceConfig } from '../../models/service-config.model';
import { ServiceOption } from '../../models/service-option.model';
import { Shipment } from '../../models/shipment.model';
import {
  AddOrdersToShipment,
  CreateDefaultServices,
  DuplicateServiceOption,
  FetchAssignedServices,
  FetchPaginatedCategorizedOrders,
  FetchPaginatedOrdersOfShipment,
  RemoveOrderFromShipment,
  RemoveServiceCard,
  RemoveServiceOption,
  RemoveShipmentWithReference,
  UpdateDefaultServices,
} from '../../store/wizard.actions';
import { WizardState } from '../../store/wizard.state';
import { OrderSelectorDrawerTsComponent } from '../order-selector-drawer.ts/order-selector-drawer.component';

@Component({
  selector: 'hmt-shipment-card',
  templateUrl: './shipment-card.component.html',
  styleUrl: './shipment-card.component.scss',
})
export class ShipmentCardComponent extends FieldWrapper implements OnInit, OnDestroy {
  @Input() shipment: Shipment;
  @Input() shipmentMethods: { key: string; value: string; transhipmentAllowed: boolean }[];
  @Input() assignedServices: Observable<ServiceConfig[]>;
  @Input() shipmentServiceOptions: Observable<(shipmentId?: string) => ServiceOption[]>;
  @Input() defaultServiceConfigs: EntityMap<string, string[]>;
  @Input() injectedScreen$: Observable<WorkflowScreen>;
  @Input() isFirstCard: boolean;
  @Input() parentJobRefId: string;
  @Input() categorizedOrders: Observable<(shipmentId: string) => Pageable<Order>[]>;
  @Output() selectedService = new EventEmitter<{
    shipmentId: string;
    serviceOptionId: string;
    serviceId: string;
    screenId: string;
  }>();

  private store$ = inject(Store);
  @Select(WizardState.getJobFile) jobFile$: Observable<JobFile>;
  ordersOfShipment$ = this.store$.select(WizardState.getShipmentOrders);

  wizardGeneralScopes$ = checkScopesReactive({ oneOf: [SCOPES.JOB_FILES.CREATE] });

  public dialogHandlerService = inject(DialogHandlerService);
  private matDialog = inject(MatDialog);
  private route = inject(ActivatedRoute);
  private cdr = inject(ChangeDetectorRef);
  private onDestroy$ = new Subject<void>();

  isOpen = false;
  serviceOptions: ServiceOption[] = [];
  searchControl = new FormControl('');
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  servicesEmpty = false;

  ngOnInit(): void {
    this.shipmentServiceOptions
      .pipe(
        takeUntil(this.onDestroy$),
        map(selector => selector(this.shipment?._id)),
        tap(serviceOptions => {
          this.serviceOptions = serviceOptions;
          this.servicesEmpty = serviceOptions.some(option => option?.services?.length > 0);
        })
      )
      .subscribe();
    this.fetchOrdersOfShipment();
    this.searchKeyListener();
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  deleteShipment(event: Event) {
    event.stopPropagation();
    //TODO: move the Confirmation Dialog to dialog handler service
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        success: false,
        title: 'Delete Shipment',
        message: 'Are you sure you want to delete this shipment?',
        confirmButtonText: 'Yes',
        showCancel: true,
        onConfirmCallback: () => this.store$.dispatch(new RemoveShipmentWithReference(this.shipment._id)),
      },
    });
  }

  importOrders() {
    this.store$.dispatch(
      new FetchPaginatedCategorizedOrders(this.route.snapshot.params['jobRefId'], this.shipment._id, '', 0, PAGE_SIZE)
    );
    const data = {
      categorizedOrders: this.categorizedOrders.pipe(map(selector => selector(this.shipment._id))),
      shipmentOrders: this.shipment.orders,
    };
    const dialogRef = this.dialogHandlerService.openDialog(OrderSelectorDrawerTsComponent, data, {
      height: '100%',
      width: '730px',
      position: { right: 'right' },
    });

    dialogRef.componentInstance.selectedOrders
      .pipe(
        takeUntil(this.onDestroy$),
        tap((selectedOrders: Order[]) => {
          this.store$.dispatch(new AddOrdersToShipment(this.shipment._id, selectedOrders));
        })
      )
      .subscribe();
    dialogRef.componentInstance.selectedFirstOrder
      .pipe(
        takeUntil(this.onDestroy$),
        tap((orderData: { order: Order; searchText: string }) => {
          this.store$.dispatch(
            new FetchPaginatedCategorizedOrders(
              this.route.snapshot.params['jobRefId'],
              this.shipment._id,
              orderData?.order?._id ?? '',
              0,
              PAGE_SIZE,
              orderData?.searchText ?? ''
            )
          );
        })
      )
      .subscribe();
    dialogRef.componentInstance.pageEvent
      .pipe(
        takeUntil(this.onDestroy$),
        tap((event: { pageEvent: PageEvent; searchText: string }) => {
          this.store$.dispatch(
            new FetchPaginatedCategorizedOrders(
              this.route.snapshot.params['jobRefId'],
              this.shipment._id,
              '',
              event?.pageEvent?.pageIndex ?? 0,
              event?.pageEvent?.pageSize ?? PAGE_SIZE,
              event?.searchText ?? ''
            )
          );
        })
      )
      .subscribe();
  }

  setSelectedService($event) {
    this.selectedService.emit({
      shipmentId: this.shipment._id,
      serviceOptionId: $event.serviceOptionId,
      serviceId: $event.serviceId,
      screenId: $event.screenId,
    });
  }

  setSelectedShipmentMode($event) {
    this.store$.dispatch(new FetchAssignedServices($event));
  }

  setSelectedServiceConfigs($event) {
    const serviceConfig = $event?.selectedServiceConfigs || [];
    //update existing services
    if ($event?.serviceOptionId) {
      this.store$.dispatch(
        new UpdateDefaultServices(
          serviceConfig?.filter(srv => !srv?.disabled).map(srv => srv?._id),
          $event.shipmentMethod,
          this.shipment._id,
          $event.serviceOptionId,
          $event.previousPlannedServiceId
        )
      );
    } else {
      //create new services
      this.store$.dispatch(
        new CreateDefaultServices(
          serviceConfig.map(srv => srv._id),
          $event.shipmentMethod,
          this.shipment._id,
          $event.serviceOptionId
        )
      );
    }
  }

  fetchOrdersOfShipment(filter = '', offset = 0, limit = PAGE_SIZE) {
    this.store$.dispatch(new FetchPaginatedOrdersOfShipment(this.shipment._id, offset, limit, filter));
    this.cdr.detectChanges();
  }

  searchKeyListener() {
    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        tap(value => {
          if (typeof value === 'string') {
            this.fetchOrdersOfShipment(value, 0, PAGE_SIZE);
          }
        })
      )
      .subscribe();
  }

  onPageChange($event) {
    this.searchControl.setValue('');
    this.fetchOrdersOfShipment('', $event.pageIndex, $event.pageSize);
  }

  removeServiceOption($event) {
    this.store$.dispatch(new RemoveServiceOption(this.shipment._id, $event));
  }

  removeOrderFromShipment($event) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        success: false,
        title: 'Delete Order',
        message: 'Are you sure you want to remove this order from the shipment?',
        confirmButtonText: 'Yes',
        showCancel: true,
        onConfirmCallback: () => this.store$.dispatch(new RemoveOrderFromShipment($event._id, this.shipment._id)),
      },
    });
  }

  duplicateServiceOption($event) {
    this.store$.dispatch(new DuplicateServiceOption(this.shipment._id, $event));
  }

  removeServiceCard($event) {
    this.store$.dispatch(new RemoveServiceCard($event));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
