import { gql } from 'apollo-angular';
import { CarrierRoutingType } from '../enums/carrier-routing-type.enum';
import { VesselSchedule } from '../models/vessel-schedule.model';

export const CREATE_VESSEL_SCHEDULE_QUERY = gql`
  mutation CreateVesselSchedule($createVesselScheduleInput: CreateVesselScheduleInput!) {
    createdVesselSchedule: createVesselSchedule(createVesselScheduleInput: $createVesselScheduleInput) {
      _id
      cfsCutoff
      createdAt
      createdBy
      cyCutoffDate
      from
      fromEta
      fromEtd
      orgId
      previousPortOfCall
      previousPortOfCallEta
      previousPortOfCallEtd
      shippingLine
      to
      toEta
      toEtd
      totalTransitTime
      updatedAt
      updatedBy
      vesselName
      voyage
      routingDetails {
        type
        stops
      }
      versionHistory {
        _id
        changedField
        createdAt
        createdBy
        newValue
        oldValue
        orgUserId
        updatedAt
        updatedBy
      }
    }
  }
`;

export interface CreateVesselScheduleInput {
  cfsCutoff?: string;
  cyCutoffDate?: string;
  from?: string;
  fromEta?: string;
  fromEtd?: string;
  previousPortOfCall?: string;
  previousPortOfCallEta?: string;
  previousPortOfCallEtd?: string;
  shippingLine?: string;
  vesselName?: string;
  voyage?: string;
  to?: string;
  toEta?: string;
  toEtd?: string;
  totalTransitTime?: number;
  routingDetails?: {
    type: CarrierRoutingType;
    stops: string[];
  };
}

export interface CreateVesselScheduleResponse {
  createdVesselSchedule: VesselSchedule;
}
