<!-- uploaded-document.component.html -->
<div class="uploaded-document-wrapper">
  <header class="uploaded-document-wrapper__header">
    <p class="uploaded-document-title">{{ file().customFileName }}</p>
    <div class="uploaded-document-actions">
      <button mat-icon-button (click)="openDocumentPreviewDialog()" class="view-btn" color="primary">
        <mat-icon>visibility</mat-icon>
      </button>
      <button mat-icon-button (click)="downloadDocument()" class="download-btn" color="primary">
        <mat-icon>download</mat-icon>
      </button>

      @if (!deleteDisabled()) {
        <button mat-icon-button (click)="onDeleteBtnClicked()" class="delete-btn">
          <mat-icon>delete_outline</mat-icon>
        </button>
      }
    </div>
  </header>
  <div class="uploaded-document">
    <div class="uploaded-document-details">
      <div class="uploaded-document-left">
        <div class="uploaded-document-icon">
          <img src="assets/icons/file-icon.svg" alt="" height="36" width="36" />
        </div>
        <div class="text-details">
          <p class="uploaded-document-name">{{ file().customFileName }}</p>
          <p class="uploaded-date">{{ file().file.uploadedDate | date: 'dd MMM yyyy, hh:mm a' }}</p>
          <p class="uploaded-user"><b>Uploaded by: </b> {{ file().file.uploadedByName }}</p>
        </div>
      </div>
      <div class="uploaded-document-right">
        <!-- <button mat-icon-button color="primary" (click)="onDeleteBtnClicked()">
          <mat-icon>delete_outline</mat-icon>
        </button> -->
      </div>
    </div>
  </div>
</div>
