import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { doesHaveOneOfScopes, SCOPES } from '@configs/scopes';
import { JobUpdateNotification } from '@core/models/job-updates-notification.model';
import { AuthState } from '@core/store/auth/auth.state';
import { JobUpdatesState } from '@core/store/job-updates/job-updates.state';
import { FieldType, FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { DEFAULT_DEBOUNCE_TIME, PAGE_SIZE, PAGE_SIZE_OPTIONS } from '@shared/constants';
import { EntityMap } from '@shared/types';
import { WorkflowState } from 'app/json-schema-forms/store/workflow.state';
import { debounceTime, filter, mergeMap, Observable, of, Subject, take, takeUntil, tap } from 'rxjs';
import { JobFile } from '../../models/job-file.model';
import { Order } from '../../models/order.model';
import { UploadedFileType } from '../../models/uploaded-file.type';
import { ViewOrderService } from '../../services/view-order.service';
import { WizardService } from '../../services/wizard.service';
import {
  CreateOrder,
  DeleteOrder,
  FetchPaginatedOrders,
  FetchPaginatedOrdersOfShipment,
  ResetOrderDocuments,
  SelectOrder,
} from '../../store/wizard.actions';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-orders',
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.scss',
})
export class OrdersComponent extends FieldType implements OnInit, OnDestroy {
  private readonly store$ = inject(Store);

  currentScreen$ = this.store$.select(WorkflowState.getCurrentScreen);
  injectedScreen$ = this.store$.select(WorkflowState.getInjectedScreen);
  referenceDocumentOrders$ = this.store$.select(WizardState.getPaginatedOrders);
  shipmentOrders$ = this.store$.select(WizardState.getShipmentOrders);
  jobFile$ = this.store$.select(WizardState.getJobFile);
  orderDocuments$ = this.store$.select(WizardState.getOrderDocuments);
  userScopes$ = this.store$.select(AuthState.getUserScopes);

  private $destroy: Subject<void> = new Subject<void>();

  public translate = inject(TranslateService);
  private readonly viewOrderService = inject(ViewOrderService);
  private readonly actions$ = inject(Actions);
  private readonly matDialog = inject(MatDialog);
  private readonly wizardService = inject(WizardService);

  doesHaveOneOfScopesOfUser = doesHaveOneOfScopes;
  DEFINED_USER_SCOPES = SCOPES;

  latestJobUpdates$: Observable<JobUpdateNotification> = this.store$.select(JobUpdatesState.getLatestJobUpdates);
  fields: FormlyFieldConfig[] = [];
  dialogFields: FormlyFieldConfig[] = [];
  orderDocuments: EntityMap<string, UploadedFileType[]> = {};

  createOrderOptions: FormlyFormOptions = {
    formState: {
      disabled: true,
      showErrorState: true,
    },
  };
  dialogRef: MatDialogRef<unknown>;
  parentJobRefId: string;
  jobRefId: string;

  createOrderForm: FormGroup = new FormGroup({});
  formControlSearch: FormControl = new FormControl('');
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  orders$ = this.jobFile$.pipe(
    filter((jobFile: JobFile) => !!jobFile),
    mergeMap((jobFile: JobFile) => {
      if (jobFile?.parentJobRefId) {
        return this.wizardService
          .findJobFileByJobRefId(jobFile.parentJobRefId)
          .pipe(mergeMap(parentJobFile => of([parentJobFile, jobFile])));
      }
      return of([null, jobFile]);
    }),
    mergeMap(([parentJobFile, _]: [JobFile, JobFile]) => {
      if (parentJobFile) {
        if (parentJobFile?.smartened) {
          return this.shipmentOrders$;
        } else {
          return this.referenceDocumentOrders$;
        }
      }
      return this.referenceDocumentOrders$;
    })
  );

  ngOnInit(): void {
    this.listenToActions();
    this.listenToOrderSearch();

    this.jobFile$
      .pipe(
        filter((jobFile: JobFile) => !!jobFile?.jobRefId),
        take(1),
        tap((jobFile: JobFile) => {
          this.jobRefId = jobFile?.jobRefId;
        })
      )
      .subscribe();

    this.createOrderForm.statusChanges
      .pipe(
        takeUntil(this.$destroy),
        tap(status => {
          this.createOrderOptions.formState.disabled = status === 'INVALID';
        })
      )
      .subscribe();

    this.actions$
      .pipe(
        takeUntil(this.$destroy),
        ofActionSuccessful(CreateOrder),
        mergeMap(_ => this.jobFile$),
        tap((jobFile: JobFile) => {
          this.formControlSearch.setValue('');
          this.jobRefId = jobFile?.jobRefId;
          this.store$.dispatch(new FetchPaginatedOrders(jobFile?.jobRefId, 0, PAGE_SIZE, ''));
        })
      )
      .subscribe();
  }

  listenToOrderSearch(): void {
    this.formControlSearch.valueChanges
      .pipe(
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        takeUntil(this.$destroy),
        tap(value => {
          if (typeof value === 'string') {
            this.store$.dispatch(new FetchPaginatedOrders(this.jobRefId, 0, PAGE_SIZE, value));
          }
        })
      )
      .subscribe();
  }

  openCreateOrderDialog(order?: Order): void {
    this.store$.dispatch(new SelectOrder(order?._id));
    if (!order) {
      this.store$.dispatch(new ResetOrderDocuments());
    }
    this.viewOrderService.openOrderView(
      order,
      this.injectedScreen$,
      this.dialogFields,
      this.createOrderForm,
      this.createOrderOptions,
      this.dialogRef
    );
    // this.viewOrderService.ngOnDestroy();
  }

  editOrder(order: Order): void {
    if (order) {
      this.openCreateOrderDialog(order);
    }
  }

  deleteOrder(order: Order): void {
    if (order) {
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          success: false,
          title: 'Delete Order',
          message: 'Are you sure you want to delete this order?',
          confirmButtonText: 'Yes',
          showCancel: true,
          onConfirmCallback: () =>
            order?.assigned
              ? this.matDialog.open(ConfirmationDialogComponent, {
                  data: {
                    success: false,
                    title: 'Delete Order',
                    confirmButtonText: 'OK',
                    message: 'Cannot Delete Assigned Order',
                  },
                })
              : this.store$.dispatch(new DeleteOrder(order?._id)),
        },
      });
    }
  }

  listenToActions(): void {
    this.jobFile$
      .pipe(
        takeUntil(this.$destroy),
        filter((jobFile: JobFile) => !!jobFile),
        mergeMap((jobFile: JobFile) => {
          if (jobFile?.parentJobRefId) {
            return this.wizardService
              .findJobFileByJobRefId(jobFile.parentJobRefId)
              .pipe(mergeMap(parentJobFile => of([parentJobFile, jobFile])));
          }
          return of([null, jobFile]);
        }),
        tap(([parentOrCurrentJobFile, currentJobFile]) => {
          this.parentJobRefId = currentJobFile?.parentJobRefId;
          if (parentOrCurrentJobFile) {
            if (parentOrCurrentJobFile?.smartened) {
              this.store$.dispatch(
                new FetchPaginatedOrdersOfShipment(
                  parentOrCurrentJobFile?.referenceDocuments?.shipmentIds[0],
                  0,
                  PAGE_SIZE,
                  ''
                )
              );
            } else {
              this.store$.dispatch(new FetchPaginatedOrders(parentOrCurrentJobFile?.jobRefId, 0, PAGE_SIZE, ''));
            }
          } else {
            this.store$.dispatch(new FetchPaginatedOrders(currentJobFile?.jobRefId, 0, PAGE_SIZE, ''));
          }
        })
      )
      .subscribe();

    this.latestJobUpdates$
      .pipe(
        takeUntil(this.$destroy),
        filter((jobUpdate: JobUpdateNotification) => {
          return jobUpdate && jobUpdate.entityType === 'ORDER';
        }),
        tap((jobUpdate: JobUpdateNotification) => {
          // TODO: Fetch only the updated order
          this.store$.dispatch(new FetchPaginatedOrders(jobUpdate.jobRefId, 0, PAGE_SIZE, ''));
        })
      )
      .subscribe();
  }

  onPageChange(event: PageEvent): void {
    this.store$.dispatch(
      new FetchPaginatedOrders(
        this.jobRefId,
        event.pageIndex,
        event.pageSize,
        typeof this.formControlSearch?.value === 'string' ? this.formControlSearch.value : ''
      )
    );
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
