<div class="requirement-definition-port-pair-card">
  <mat-expansion-panel [expanded]="expanded()">
    <mat-expansion-panel-header class="p-10">
      <mat-panel-title>
        @if (showToggleCheck()) {
          <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()" tabindex="0" role="button">
            <mat-checkbox (change)="handleToggleCheck($event)"></mat-checkbox>
          </div>
        }
        <div>{{ portTypeLabel() }} {{ cardIndex() + 1 }}</div>
      </mat-panel-title>
      <mat-panel-description class="flex justify-between">
        <div
          [ngClass]="{ 'port-pair-logo-container': showLikeDislikeButtons() }"
          class="country-pair-container flex items-center gap-10">
          <div class="flag-icon-sm">
            <img [src]="originFlagUrl()" alt="flag" />
          </div>
          <div class="bold">{{ originCountryCode() }}</div>
          <div>
            <mat-icon color="primary">arrow_forward</mat-icon>
          </div>
          <div class="flag-icon-sm">
            <img [src]="destinationFlagUrl()" alt="flag" />
          </div>
          <div class="bold">{{ destinationCountryCode() }}</div>
        </div>
        <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()" tabindex="0" role="button">
          @if (showFavoriteButton()) {
            <div class="p-10 favorite-btn-container">
              <mat-button-toggle
                (change)="favoriteButtonClicked($event)"
                [checked]="favoriteToggled()"
                [disabled]="!showFavoriteButton"
                class="btn-grey-outline favorite-btn">
                <mat-icon>star</mat-icon>
                <ng-container>Mark as Favorite</ng-container>
              </mat-button-toggle>
            </div>
          }
          @if (showDeleteButton()) {
            <div class="p-10 delete-btn-container">
              <button mat-icon-button color="warn" type="button" (click)="deletePortPairCard($event)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          }
          @if (showLikeDislikeButtons()) {
            <div class="actions flex items-center gap-10">
              <div [matTooltip]="" matTooltipPosition="right">
                <button
                  [disabled]="readonly()"
                  [matTooltip]="hasCurrentUserVoted() ? 'You have already Liked' : 'Like'"
                  [class]="
                    'btn-base action-button ' + (hasCurrentUserVoted() ? 'awarded-like-btn' : 'btn-green-outline')
                  "
                  (click)="likeButtonClicked($event)"
                  class="btn-base action-button"
                  mat-stroked-button>
                  <div class="row items-center">
                    <div class="mt-5" [ngClass]="hasCurrentUserVoted() ? 'count awarded-count' : 'count'">
                      {{ totalLikes() }}
                    </div>
                    <div>
                      <img
                        class="icon like-icon"
                        [src]="hasCurrentUserVoted() ? 'assets/icons/like-white.svg' : 'assets/icons/like.svg'"
                        alt="like" />
                    </div>
                  </div>
                </button>
              </div>
              <button
                [disabled]="true"
                class="btn-base action-button btn-purple-outline disabled-button"
                mat-stroked-button>
                <div class="flex items-center gap-10">
                  <span class="count">{{ totalComments() }}</span>
                  <img class="icon comment-icon" src="assets/icons/message.svg" alt="comments" />
                </div>
              </button>
            </div>
          }
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <ng-content></ng-content>
    </div>
  </mat-expansion-panel>
</div>
