import { Component, inject, input, OnInit, signal } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FileTypeExtension } from '@shared/enums/file-type-extension.enum';
import { PreSignedUrl } from '@shared/models/pre-signed-url.model';
import { FileHandlerService } from '@shared/services/file-handler.service';
import { UploadedFileType } from 'app/modules/wizard/models/uploaded-file.type';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { first } from 'rxjs';

@Component({
  selector: 'hmt-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  standalone: true,
  imports: [NgxDocViewerModule, MatProgressSpinnerModule],
})
export class FilePreviewComponent implements OnInit {
  fileTypeExtension = FileTypeExtension;
  fileHandlerService = inject(FileHandlerService);
  file = input.required<UploadedFileType>();
  preSignedUrl = signal<PreSignedUrl | null>(null);

  ngOnInit(): void {
    this.fileHandlerService
      .fetchFileWithoutForcedDownload(this.file().fileId)
      .pipe(first())
      .subscribe(res => {
        this.preSignedUrl.set(res);
      });
  }
}
