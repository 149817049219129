import { AfterViewInit, Component, ElementRef, inject, input, signal, viewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DEFAULT_DEBOUNCE_TIME } from '@shared/constants';
import { LocationType } from '@shared/enums/location-type.enum';
import { LocationsService } from '@shared/services/locations.service';
import { debounceTime, first, fromEvent } from 'rxjs';

@Component({
  selector: 'hmt-port-selector',
  templateUrl: './port-selector.component.html',
  styleUrls: ['./port-selector.component.scss'],
})
export class PortSelectorComponent implements AfterViewInit {
  locationsService = inject(LocationsService);

  form = input<FormGroup>();
  controlName = input<string>();
  label = input<string>();

  portInput = viewChild.required<ElementRef<HTMLInputElement>>('portInput');
  ports = signal<{ id: string; name: string; shortName: string }[]>([]);

  ngAfterViewInit(): void {
    fromEvent(this.portInput()?.nativeElement, 'input')
      .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME))
      .subscribe(() => {
        const portText = this.portInput()?.nativeElement?.value ?? undefined;
        this.loadPorts(portText);
      });
  }

  loadPorts(searchText = undefined) {
    this.locationsService
      .getLocationsWithSearch(LocationType.SEAPORT, searchText)
      .pipe(first())
      .subscribe(ports => {
        this.ports.set(ports);
      });
  }

  displayPortName(port: { id: string; name: string }) {
    if (port) {
      return port.name;
    }
    return '';
  }
}
