import { gql } from 'apollo-angular';
import { CarrierRoutingType } from '../enums/carrier-routing-type.enum';
import { FlightSchedule } from '../models/flight-schedule.model';

export const CREATE_FLIGHT_SCHEDULE_QUERY = gql`
  mutation CreateFlightSchedule($createFlightScheduleInput: CreateFlightScheduleInput!) {
    createdFlightSchedule: createFlightSchedule(createFlightScheduleInput: $createFlightScheduleInput) {
      _id
      airLine
      createdAt
      createdBy
      deleted
      firstLegDepartureDays
      firstLegFrequency
      flightCutoff
      flightName
      from
      fromEta
      fromEtd
      orgId
      routingDetails {
        type
        stops
      }
      secondLegArrivalDays
      secondLegFrequency
      to
      toEta
      toEtd
      totalTransitTime
      updatedAt
      updatedBy
      versionHistory {
        _id
        changedField
        createdAt
        createdBy
        newValue
        oldValue
        orgUserId
        updatedAt
        updatedBy
      }
    }
  }
`;

export interface CreateFlightScheduleQueryResponse {
  createdFlightSchedule: FlightSchedule;
}

export interface CreateFlightScheduleInput {
  airLine?: string;
  flightName?: string;
  firstLegDepartureDays?: string[];
  secondLegArrivalDays?: string[];
  firstLegFrequency?: string;
  secondLegFrequency?: string;
  flightCutoff?: number;
  from?: string;
  fromEta?: string;
  fromEtd?: string;
  to?: string;
  toEta?: string;
  toEtd?: string;
  totalTransitTime?: number;
  routingDetails?: {
    type: CarrierRoutingType;
    stops: string[];
  };
}
