import { checkAllOfScopes, checkOneOfScopes } from '@core/guards/auth.guard';

const ORGS = 'orgs';
const PARTNER_ORGS = 'partner_orgs';
const CLUSTERS = 'clusters';
const OPERATIONS = 'operations';
const HUMAN_RESOURCES = 'human_resources';
const VEHICLES = 'vehicles';
const LOCATIONS = 'locations';
const DOCUMENTS = 'documents';
const JOB_FILES = 'job_files';
const TENDERS = 'tenders';
const BIDS = 'bids';
const PROCUREMENT_CONTRACTS = 'procurement_contracts';
const SALES_CONTRACTS = 'sales_contracts';
const POW = 'pow';
const INVOICES = 'invoices';
const SERVICE_CATALOG = 'service_catalog';
const FLIGHT_SCHEDULES = 'flight_schedules';
const VESSEL_SCHEDULES = 'vessel_schedules';
const SERVICE_DELIVERY = 'service_delivery';

type ScopePrefixes =
  | typeof ORGS
  | typeof PARTNER_ORGS
  | typeof CLUSTERS
  | typeof OPERATIONS
  | typeof HUMAN_RESOURCES
  | typeof VEHICLES
  | typeof LOCATIONS
  | typeof DOCUMENTS
  | typeof JOB_FILES
  | typeof TENDERS
  | typeof BIDS
  | typeof PROCUREMENT_CONTRACTS
  | typeof SALES_CONTRACTS
  | typeof POW
  | typeof INVOICES
  | typeof SERVICE_CATALOG
  | typeof FLIGHT_SCHEDULES
  | typeof VESSEL_SCHEDULES
  | typeof SERVICE_DELIVERY;

const COMMON_ACTIONS = ['read', 'create', 'update', 'delete', 'any'] as const;
const COMMON_ACTIONS_WITHOUT_DELETE = ['read', 'create', 'update', 'any'] as const;

type CommonActions = (typeof COMMON_ACTIONS)[number];
type CapitalizedCommonActions = Uppercase<CommonActions>;

function getScopesWithCommonActions(prefix: ScopePrefixes) {
  return Object.fromEntries(COMMON_ACTIONS.map(action => [action.toUpperCase(), `${prefix}:${action}`])) as Record<
    CapitalizedCommonActions,
    string
  >;
}

function getScopesWithCommonActionsWithoutDelete(prefix: ScopePrefixes) {
  return Object.fromEntries(
    COMMON_ACTIONS_WITHOUT_DELETE.map(action => [action.toUpperCase(), `${prefix}:${action}`])
  ) as Record<CapitalizedCommonActions, string>;
}

export const SCOPES = {
  ORGS: getScopesWithCommonActions(ORGS),
  PARTNER_ORGS: getScopesWithCommonActions(PARTNER_ORGS),
  CLUSTERS: getScopesWithCommonActions(CLUSTERS),
  OPERATIONS: getScopesWithCommonActions(OPERATIONS),
  HUMAN_RESOURCES: getScopesWithCommonActions(HUMAN_RESOURCES),
  VEHICLES: getScopesWithCommonActions(VEHICLES),
  LOCATIONS: getScopesWithCommonActions(LOCATIONS),
  DOCUMENTS: getScopesWithCommonActions(DOCUMENTS),
  JOB_FILES: {
    ...getScopesWithCommonActionsWithoutDelete(JOB_FILES),
    TERMINATE: `${JOB_FILES}:terminate`,
  },
  TENDERS: getScopesWithCommonActionsWithoutDelete(TENDERS),
  BIDS: {
    ...getScopesWithCommonActionsWithoutDelete(BIDS),
    WITHDRAW: `${BIDS}:withdraw`,
    REJECT: `${BIDS}:reject`,
  },
  PROCUREMENT_CONTRACTS: getScopesWithCommonActionsWithoutDelete(PROCUREMENT_CONTRACTS),
  SALES_CONTRACTS: getScopesWithCommonActionsWithoutDelete(SALES_CONTRACTS),

  // FOR HAULMATIC SUPER ADMIN
  ADMIN: { SUPER: 'admin:super' },

  TASK_FEED: {
    TODO: 'task_feed:todo',
    SERVICE_REQUESTS: 'task_feed:service_requests',
  },
  POW: {
    ADD_ACTIVITY: 'pow:add_activity',
    MARK_AS_COMPLETED: 'pow:mark_as_completed',
    UNDONE: 'pow:undone',
    COMPLETE_ALL: 'pow:complete_all',
    ANY: 'pow:any',
  },
  INVOICES: {
    ...getScopesWithCommonActions(INVOICES),
    APPROVE: 'invoices:approve',
    DISPUTE: 'invoices:dispute',
    REJECT: 'invoices:reject',
    SEND: 'invoices:send',
  },
  SERVICE_CATALOG: {
    OCEAN_FREIGHT_ROUTES_CREATE: 'service_catalog:ocean_freight_routes_create',
    OCEAN_FREIGHT_ROUTES_READ: 'service_catalog:ocean_freight_routes_read',
    OCEAN_FREIGHT_ROUTES_UPDATE: 'service_catalog:ocean_freight_routes_update',
    OCEAN_FREIGHT_ROUTES_DELETE: 'service_catalog:ocean_freight_routes_delete',
    AIR_FREIGHT_ROUTES_CREATE: 'service_catalog:air_freight_routes_create',
    AIR_FREIGHT_ROUTES_READ: 'service_catalog:air_freight_routes_read',
    AIR_FREIGHT_ROUTES_UPDATE: 'service_catalog:air_freight_routes_update',
    AIR_FREIGHT_ROUTES_DELETE: 'service_catalog:air_freight_routes_delete',
    ANY: 'service_catalog:any',
  },
  FLIGHT_SCHEDULES: {
    ...getScopesWithCommonActions(FLIGHT_SCHEDULES),
  },
  VESSEL_SCHEDULES: {
    ...getScopesWithCommonActions(VESSEL_SCHEDULES),
  },
  SERVICE_DELIVERY: {
    UPLOAD: 'service_delivery:upload',
    DELETE: 'service_delivery:delete',
    READ: 'service_delivery:read',
    ANY: 'service_delivery:any',
  },
} as const;

/**
 * Checks if the user has the required scopes
 * @param user - The user to check
 * @param oneOfScopes - The scopes to check for
 * @returns - True if the user has the required scopes, false otherwise
 */
export function doesHaveOneOfScopes(userScopes: string[], oneOfScopes: string[]) {
  return checkOneOfScopes(userScopes, { oneOf: oneOfScopes });
}

/**
 * Checks if the user has all of the required scopes
 * @param user - The user to check
 * @param allOfScopes - The scopes to check for
 * @returns - True if the user has all of the required scopes, false otherwise
 */
export function doesHaveAllOfScopes(userScopes: string[], allOfScopes: string[]) {
  return checkAllOfScopes(userScopes, { allOf: allOfScopes });
}
