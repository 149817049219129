<div class="option-editor">
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="option-editor__input">
    <mat-label>{{ labelForInput ?? 'Choose an option' }}</mat-label>
    <input type="text" matInput [matAutocomplete]="auto" [(ngModel)]="selectedOption" (keydown)="onKeyDown($event)" />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionChange($event.option.value)">
      @for (option of options; track option) {
        <mat-option [value]="option">
          {{
            optionLabelGetter
              ? optionLabelGetter(params.optionType === 'object' ? option[params.optionLabel] : option)
              : params.optionType === 'object'
                ? option[params.optionLabel]
                : option
          }}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</div>
