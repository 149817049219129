<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="option-editor">
  <label>Choose days</label>
  <p>{{ message() }}</p>
  <div class="days-container">
    <label *ngFor="let day of dayOptions">
      <input
        type="checkbox"
        [value]="day"
        [disabled]="!isSelectingDaysAllowed()"
        [checked]="selectedDays().includes(day)"
        (change)="onChange($event)" />
      {{ day }}
    </label>
  </div>
  <div class="button-container">
    <button mat-flat-button color="primary" (click)="onSave()" [disabled]="!isSaveButtonEnabled()">Save</button>
  </div>
</div>
