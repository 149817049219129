@if (form()) {
  <mat-form-field appearance="outline" subscriptSizing="dynamic" [formGroup]="form()" class="port-selector">
    <mat-label>{{ label() }}</mat-label>
    <input #portInput matInput [formControlName]="controlName()" [matAutocomplete]="portAuto" />
    <mat-autocomplete #portAuto="matAutocomplete" [displayWith]="displayPortName">
      <mat-option *ngFor="let port of ports()" [value]="port">
        {{ port.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
}
