import { Component, inject, input, OnDestroy, OnInit, signal } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ContainerType } from '@shared/models/container-type.model';
import { CreateLongTermTenderBidService } from 'app/modules/bidding-wizard/services/create-long-term-tender-bid.service';
import { ContractManagementService } from 'app/modules/contract-management/services/contract-management.service';
import { Currency } from 'app/modules/organizations-manager/models/currency.model';
import { BidService } from 'app/modules/procurement/models/bid-service-data.model';
import { FCLRate } from 'app/modules/procurement/models/rate.model';
import { CreateBidState } from 'app/modules/wizard/store/create-bid/create-bid-state';
import {
  UpdateCurrentViewingServiceFCLRates,
  UpdateSelectedCurrency,
} from 'app/modules/wizard/store/create-bid/create-bid.actions';
import { filter, map, Observable, of, Subject, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'hmt-service-catalog-fcl-charging-structure',
  templateUrl: './service-catalog-fcl-charging-structure.component.html',
  styleUrls: ['./service-catalog-fcl-charging-structure.component.scss'],
})
export class ServiceCatalogFclChargingStructureComponent implements OnInit, OnDestroy {
  private readonly contractManagementService = inject(ContractManagementService);
  private readonly createLongTermTenderBidService = inject(CreateLongTermTenderBidService);
  private readonly fb = inject(FormBuilder);
  private readonly store = inject(Store);

  private readonly destroy$ = new Subject<void>();
  private readonly unitPriceChange$ = new Subject<{ index: number; value: number }>();

  currencies$ = this.createLongTermTenderBidService.getAllCurrencies().pipe(takeUntil(this.destroy$));
  selectedCurrency$: Observable<Currency> = of(null);
  curViewingService$: Observable<BidService> = of(null);
  fclRates$: Observable<FCLRate[]> = of([]);

  selectedCurrencyControl: FormControl;
  unitPriceControls: FormControl[];

  isVisible = input.required<boolean>();
  isDisabled = input<boolean>(false);
  isReadOnly = input<boolean>(false);

  form = new FormGroup({
    mainCurrency: new FormControl<string | null>(null, [Validators.required]),
    containerTypeUnitPrices: new FormArray([
      new FormGroup({
        containerType: new FormControl<string | null>(null, [Validators.required]),
        unitPrice: new FormControl<number | null>(null, [Validators.required]),
      }),
    ]),
    additionalCharges: new FormArray([
      new FormGroup({
        chargeType: new FormControl<string | null>(null, [Validators.required]),
        chargeAmount: new FormControl<number | null>(null, [Validators.required]),
      }),
    ]),
  });

  containerTypes = signal<ContainerType[]>([]);

  constructor() {
    this.selectedCurrencyControl = new FormControl('');
    this.unitPriceControls = [];
  }

  ngOnInit() {
    console.log(this.isDisabled());
    this.fetchContainerTypes();
    this.initializeCurrency();
    this.initializeUnitPriceControls();
    // this.handleUnitPriceChanges();
    this.listenToCurrencyChanges();
  }

  private initializeCurrency() {
    this.selectedCurrency$
      .pipe(
        take(1),
        tap(currency => {
          if (currency) {
            this.selectedCurrencyControl.setValue(currency.code, { emitEvent: false });
          }
        })
      )
      .subscribe();
  }

  private listenToCurrencyChanges() {
    this.selectedCurrencyControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        map(newCurrencyCode =>
          this.store.selectSnapshot(CreateBidState.currencies).find(c => c.code === newCurrencyCode)
        ),
        filter((newCurrency): newCurrency is Currency => !!newCurrency),
        tap(newCurrency => {
          this.store.dispatch(new UpdateSelectedCurrency(newCurrency));
        })
      )
      .subscribe();
  }

  // private handleUnitPriceChanges() {
  //   this.unitPriceChange$
  //     .pipe(
  //       takeUntil(this.destroy$),
  //       distinctUntilChanged((prev, curr) => prev.index === curr.index && prev.value === curr.value),
  //       switchMap(({ index, value }) => {
  //         const currentRates = this.store.selectSnapshot(CreateBidState.curViewingServiceFCLRates);
  //         return this.createBidService.applyFCLRateCard(currentRates[index], value).pipe(
  //           tap(res => {
  //             this.updateUnitPrice(index, value);
  //           })
  //         );
  //       })
  //     )
  //     .subscribe();
  // }

  onUnitPriceChange(index: number, event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const newValue = inputElement.value === '' ? null : parseFloat(inputElement.value);
    this.unitPriceControls[index].setValue(newValue);
    if (this.unitPriceControls[index].valid) {
      this.unitPriceChange$.next({ index, value: newValue });
    }
  }

  private updateUnitPrice(index: number, value: number) {
    const currentRates = this.store.selectSnapshot(CreateBidState.curViewingServiceFCLRates);
    const updatedRates = currentRates.map((rate, i) => (i === index ? { ...rate, unitPrice: value } : rate));
    this.store.dispatch(new UpdateCurrentViewingServiceFCLRates(updatedRates));
  }

  private initializeUnitPriceControls(): void {
    this.fclRates$
      .pipe(
        take(1),
        tap(rates => {
          this.unitPriceControls = rates.map(rate =>
            this.fb.control(rate.unitPrice, [Validators.required, Validators.min(0)])
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.unitPriceChange$.complete();
  }

  addContainerTypeUnitPrice() {
    this.form.controls.containerTypeUnitPrices.push(
      this.fb.group({
        containerType: new FormControl<string | null>(null, [Validators.required]),
        unitPrice: new FormControl<number | null>(null, [Validators.required]),
      })
    );
  }

  addAdditionalCharge() {
    this.form.controls.additionalCharges.push(
      this.fb.group({
        chargeType: new FormControl<string | null>(null, [Validators.required]),
        chargeAmount: new FormControl<number | null>(null, [Validators.required]),
      })
    );
  }

  removeAdditionalCharge(index: number) {
    this.form.controls.additionalCharges.removeAt(index);
  }

  removeContainerTypeUnitPrice(index: number) {
    this.form.controls.containerTypeUnitPrices.removeAt(index);
  }

  fetchContainerTypes(): void {
    this.contractManagementService
      .fetchContainerTypes()
      .pipe(
        tap(containerTypes => {
          this.containerTypes.set(containerTypes);
        })
      )
      .subscribe();
  }

  public getFormValue() {
    return this.form.valid ? this.form.value : null;
  }

  setFormValue(value: {
    mainCurrency: string;
    containerTypeUnitPrices: {
      containerType: string;
      unitPrice: number;
    }[];
    additionalCharges: {
      chargeType: string;
      chargeAmount: number;
    }[];
  }) {
    this.form.controls.mainCurrency.setValue(value.mainCurrency);
    this.form.controls.containerTypeUnitPrices.clear();
    value.containerTypeUnitPrices.forEach(containerTypeUnitPrice => {
      this.form.controls.containerTypeUnitPrices.push(
        this.fb.group({
          containerType: containerTypeUnitPrice.containerType,
          unitPrice: containerTypeUnitPrice.unitPrice,
        })
      );
    });
    this.form.controls.additionalCharges.clear();
    value.additionalCharges.forEach(additionalCharge => {
      this.form.controls.additionalCharges.push(
        this.fb.group({
          chargeType: additionalCharge.chargeType,
          chargeAmount: additionalCharge.chargeAmount,
        })
      );
    });
  }

  setDisabled(isDisabled: boolean) {
    // if (isDisabled) {
    //   this.form.controls.mainCurrency.re();
    //   this.form.controls.containerTypeUnitPrices.controls.forEach(control => control.disable());
    //   this.form.controls.additionalCharges.controls.forEach(control => control.disable());
    // }
  }
}
