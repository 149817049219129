import { gql } from 'apollo-angular';
import { FlightSchedule } from '../models/flight-schedule.model';

export const GET_FLIGHT_SCHEDULES_BY_IDS = gql`
  query GetFlightSchedulesByIds($ids: [String!]!) {
    getFlightSchedulesByIds(ids: $ids) {
      _id
      airLine
      createdAt
      createdBy
      deleted
      firstLegDepartureDays
      firstLegFrequency
      firstLegNoOfDays
      flightCutoff
      flightName
      from
      fromEta
      fromEtd
      orgId
      routingDetails {
        type
        stops
      }
      secondLegArrivalDays
      secondLegFrequency
      secondLegNoOfDays
      to
      toEta
      toEtd
      totalTransitTime
      updatedAt
      updatedBy
      versionHistory {
        _id
        changedField
        createdAt
        createdBy
        newValue
        oldValue
        orgUserId
        updatedAt
        updatedBy
      }
      rateStructure {
        _id
        contractedParty {
          _id
        }
        contractedPartyId
        createdAt
        createdBy
        operationId
        orgId
        rateCardType
        template
        updatedAt
        updatedBy
        rateCardData {
          _id
          additionalCharges {
            amount
            currencyCode
            description
          }
          clauses {
            clauseType
            name
            enabled
          }
          createdAt
          createdBy
          currencyCode
          name
          operationId
          orgId
          serviceType
          templateType
          updatedAt
          updatedBy
          data
        }
      }
    }
  }
`;

export interface GetFlightSchedulesByIdsQueryResponse {
  getFlightSchedulesByIds: FlightSchedule[];
}
