import { OnGoingJobsFilter } from '../models/on-going-jobs-filter.model';

export class FetchOnGoingJobs {
  static readonly type = '[Dashboard] Fetch onGoingJobs';
  constructor(
    public filter: OnGoingJobsFilter,
    public offset?: number,
    public limit?: number
  ) {}
}

export class SetTodos {
  static readonly type = '[dashboard] set todos';

  constructor(public assignedTo: string) {}
}

export class SetNewRequests {
  static readonly type = '[dashboard] set NewRequests';

  constructor(public orgId: string) {}
}

export class FetchTaskFeedSummary {
  static readonly type = '[dashboard] Fetch Task Feed Summary';
  constructor(public assignedTo: string) {}
}
