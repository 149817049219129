import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';
import {
  CREATE_FLIGHT_SCHEDULE_QUERY,
  CreateFlightScheduleInput,
  CreateFlightScheduleQueryResponse,
} from '../queries/create-flight-schedule.query';
import { DELETE_FLIGHT_SCHEDULE_QUERY, DeleteFlightScheduleResponse } from '../queries/delete-flight-schedule.query';
import {
  GET_FLIGHT_SCHEDULES_BY_IDS,
  GetFlightSchedulesByIdsQueryResponse,
} from '../queries/get-flight-schedules-by-ids.query';
import {
  GET_FLIGHT_SCHEDULES_BY_ORG_ID,
  GetFlightSchedulesByOrgIdInput,
  GetFlightSchedulesByOrgIdQueryResponse,
} from '../queries/get-flight-schedules-by-org-id.query';
import {
  UPDATE_FLIGHT_SCHEDULE_QUERY,
  UpdateFlightScheduleInput,
  UpdateFlightScheduleResponse,
} from '../queries/update-flight-schedule.query';

@Injectable({
  providedIn: 'root',
})
export class FlightScheduleService {
  apollo = inject(Apollo);

  createFlightSchedule(createFlightScheduleInput: CreateFlightScheduleInput) {
    return this.apollo
      .mutate<CreateFlightScheduleQueryResponse>({
        mutation: CREATE_FLIGHT_SCHEDULE_QUERY,
        variables: { createFlightScheduleInput },
      })
      .pipe(map(result => result.data.createdFlightSchedule));
  }

  updateFlightSchedule(updateFlightScheduleInput: UpdateFlightScheduleInput) {
    return this.apollo
      .mutate<UpdateFlightScheduleResponse>({
        mutation: UPDATE_FLIGHT_SCHEDULE_QUERY,
        variables: { updateFlightScheduleInput },
      })
      .pipe(map(result => result.data.updatedFlightSchedule));
  }

  getFlightSchedulesByOrgId(getFlightSchedulesByOrgIdInput: GetFlightSchedulesByOrgIdInput) {
    return this.apollo
      .query<GetFlightSchedulesByOrgIdQueryResponse>({
        query: GET_FLIGHT_SCHEDULES_BY_ORG_ID,
        variables: { getFlightSchedulesByOrgIdInput },
      })
      .pipe(map(result => result.data.getFlightSchedulesByOrgId));
  }

  deleteFlightSchedule(scheduleId: string) {
    return this.apollo
      .mutate<DeleteFlightScheduleResponse>({
        mutation: DELETE_FLIGHT_SCHEDULE_QUERY,
        variables: { scheduleId },
      })
      .pipe(map(result => result.data.deletedFlightSchedule));
  }

  getFlightSchedulesByIds(ids: string[]) {
    return this.apollo
      .query<GetFlightSchedulesByIdsQueryResponse>({
        query: GET_FLIGHT_SCHEDULES_BY_IDS,
        variables: { ids },
      })
      .pipe(map(result => result.data.getFlightSchedulesByIds));
  }
}
