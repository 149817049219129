import { gql } from 'apollo-angular';

export const GET_LOCATIONS_WITH_SEARCH_QUERY = gql`
  query GetLocationsWithSearch($getLocationsByFilterInput: GetLocationsByFilterDto!) {
    locations: getLocationsByFilter(getLocationsByFilterInput: $getLocationsByFilterInput) {
      id: _id
      name: locationName
      shortName: shortName
    }
  }
`;

export interface GetLocationsWithSearchResponse {
  locations: { id: string; name: string; shortName: string }[];
}
