import { JobContract } from 'app/modules/contract-management/models/job-contract.model';

export const JOB_CONTRACT_STATE_ACTION_PREFIX = 'JobContractView';

export class FetchJobContract {
  static readonly type = `${JOB_CONTRACT_STATE_ACTION_PREFIX} Fetch Job Contract`;
  constructor(public contractId: string) {}
}

export class DownloadJobContractPdf {
  static readonly type = `${JOB_CONTRACT_STATE_ACTION_PREFIX} Download Job Contract Pdf`;
  constructor(public contractId: string) {}
}

export class TerminateJobContract {
  static readonly type = `${JOB_CONTRACT_STATE_ACTION_PREFIX} Terminate Job Contract`;
  constructor(
    public contractId: string,
    public terminationReason: string
  ) {}
}

export class SetJobContract {
  static readonly type = `${JOB_CONTRACT_STATE_ACTION_PREFIX} Set Job Contract`;
  constructor(public jobContract: JobContract) {}
}
