@if (longTermRatesContract()) {
  <div class="contract-summary-container">
    <hmt-long-term-contract-summary
      [summary]="longTermRatesContract().ratesValidityPeriod"
      [usage]="longTermRatesContract().usage.unlimited"
      [currency]="longTermRatesContract().currency"
      [tenderName]="tenderName()" />
  </div>
  <div class="contract-page">
    <ng-container *ngIf="terminationInfo">
      <div class="termination-overlay col flex ha-c va-c">TERMINATED</div>
    </ng-container>
    <mat-divider></mat-divider>
    <div class="contract-container">
      <div class="contract-title">{{ title }}</div>

      <ng-container *ngIf="terminationInfo">
        <div class="contract-termination-container col">
          <div class="title full-width row ha-c">Contract Has Been Terminated</div>
          <div class="contract-termination-details col">
            <div class="termination-reason-title">Reason for Termination</div>
            <div class="termination-reason-text">{{ terminationInfo?.terminationReason }}</div>
          </div>
          <div class="row key-values mt-10">
            <div class="col key-value">
              <div class="key label">Date of Rejection</div>
              <div class="value">{{ terminationInfo?.terminatedAt | date: 'dd/MM/yyyy HH:mm:a' }}</div>
            </div>
            <div class="col key-value">
              <div class="key label">Terminated By</div>
              <div class="value">{{ terminationInfo?.terminatedByName || '--' }}</div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="contract-header mt-10">
        <div class="company-logo-container">
          <div class="company-logo">
            <img class="logo" [src]="customerInformation?.logo" alt="Company Logo" />
          </div>
          <div class="company-details">
            <div class="company-name">{{ customerInformation?.orgName }}</div>
            <div class="company-details-item">Reg No : {{ customerInformation?.companyReg }}</div>
            <div class="company-details-item">{{ customerInformation?.orgAddress }}</div>
          </div>
        </div>
        <div class="contract-info-container">
          <div class="contract-info">
            <div class="contract-info-item">Contract Ref No : {{ fourPLInformation?.contractRefNumber }}</div>
            <div class="contract-info-item"><strong>Contract with</strong></div>
            <div class="contract-info-item">
              <strong> {{ fourPLInformation?.orgName }}</strong>
            </div>
            <div class="contract-info-item">{{ fourPLInformation?.orgAddress }}</div>
          </div>
        </div>
      </div>
      <hmt-contract-grid [columns]="3" [justifyItems]="'grid-5'">
        <div class="contract-column">
          <div class="contract-info-item col-title"><strong>Tender Name</strong></div>
          <div class="contract-info-item">{{ customerInformation?.tenderName }}</div>
        </div>
        <!-- <div class="contract-column">
          <div class="contract-info-item"><strong>Internal Ref Number</strong></div>
          <div class="contract-info-item">{{ customerInformation?.internalRefNumber }}</div>
        </div> -->
        <div class="contract-column">
          <div class="contract-info-item"><strong>Prepared By</strong></div>
          <div class="contract-info-item">{{ customerInformation?.preparedBy }}</div>
          <div class="contract-info-item">{{ customerInformation?.preparedByDesignation }}</div>
        </div>
        <!-- <div class="contract-column">
          <div class="contract-info-item"><strong>Customer's Reference Number</strong></div>
          <div class="contract-info-item">{{ customerInformation?.customerReferenceNumber }}</div>
        </div> -->
        <div class="contract-column">
          <div class="contract-info-item"><strong>Created Date & Time</strong></div>
          <div class="contract-info-item">
            {{ customerInformation?.createdDateAndTime }}
          </div>
        </div>
        <div class="contract-column">
          <div class="contract-info-item"><strong>Validity Period</strong></div>
          <div class="contract-info-item">{{ customerInformation?.validityPeriod }}</div>
        </div>
      </hmt-contract-grid>
      <mat-divider></mat-divider>
      <div class="section-title margin-top-20">Customer Information</div>
      <hmt-contract-grid [columns]="3" [justifyItems]="''">
        <div class="contract-column">
          <div class="contract-info-item col-title">
            <strong>Company Reg: </strong><span> {{ customerOrgInformation?.companyReg }} </span>
          </div>
        </div>
        <div class="contract-column">
          <div class="contract-info-item">
            <strong>VAT: </strong><span> {{ customerOrgInformation?.vat }}</span>
          </div>
        </div>
        <div class="contract-column">
          <div class="contract-info-item">
            <strong>TIN: </strong><span> {{ customerOrgInformation?.tin }}</span>
          </div>
        </div>
        <div class="contract-column">
          <div class="contract-info-item">{{ customerOrgInformation?.address }}</div>
        </div>
        <div class="contract-column">
          <div class="contract-info-item">Phone: {{ customerOrgInformation?.principalContact?.phone }}</div>
          <div class="contract-info-item">Fax: {{ customerOrgInformation?.principalContact?.phone }}</div>
          <div class="contract-info-item">Email: {{ customerOrgInformation?.principalContact?.email }}</div>
        </div>
        <div class="contract-column">
          <div class="contract-info-item">Name: {{ customerOrgInformation?.principalContact?.name }}</div>
          <div class="contract-info-item">Mobile: {{ customerOrgInformation?.principalContact?.phone }}</div>
        </div>
      </hmt-contract-grid>
      <mat-divider></mat-divider>

      <div class="section-title margin-top-20">Fee</div>

      @for (service of services(); track $index) {
        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title> {{ serviceIdToName(service.serviceId) }} </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="location-pair-container">
              @for (locationPair of service.locationPairs; track $index) {
                <hmt-long-term-contract-location-pair
                  [locationPair]="locationPair"
                  [index]="$index"
                  [serviceId]="service.serviceId" />
              }
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      }

      <div class="payment-terms-section margin-top-20">
        <div class="section-title margin-top-20">Terms & Conditions</div>
        <mat-divider></mat-divider>
        <div class="payment-title">Payment Terms</div>
        <mat-divider></mat-divider>
        <ul class="payment-details">
          <li *ngFor="let term of paymentTerms">{{ term }}</li>
        </ul>
      </div>
      <mat-divider></mat-divider>
    </div>
  </div>
}
