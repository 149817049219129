@if (form()) {
  <mat-form-field appearance="outline" subscriptSizing="dynamic" [formGroup]="form()" class="airport-selector">
    <mat-label>{{ label() }}</mat-label>
    <input #airportInput matInput [formControlName]="controlName()" [matAutocomplete]="airportAuto" />
    <mat-autocomplete #airportAuto="matAutocomplete" [displayWith]="displayAirportName">
      <mat-option *ngFor="let airport of airports()" [value]="airport">
        {{ airport.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
}
