import { Component, ViewChild } from '@angular/core';
import { OwlDateTimeComponent } from '@danielmoncada/angular-datetime-picker';
import { EnhancedDatagridDatetimeEditorParams } from '@shared/types/enhanced-grid-types/enhanced-grid-components.type';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'hmt-enhanced-datagrid-datetime-editor',
  templateUrl: './enhanced-datagrid-datetime-editor.component.html',
  styleUrls: ['./enhanced-datagrid-datetime-editor.component.scss'],
})
export class EnhancedDatagridDatetimeEditorComponent<TData = Record<string, unknown>>
  implements ICellEditorAngularComp
{
  params!: EnhancedDatagridDatetimeEditorParams<TData>;
  min: Date | null = null;
  max: Date | null = null;
  helperMessage: string | null = null;
  public selectedDate: Date;

  @ViewChild(OwlDateTimeComponent) datepicker: OwlDateTimeComponent<Date>;

  agInit(params: EnhancedDatagridDatetimeEditorParams<TData>): void {
    this.params = params;
    this.min = params.minDate ?? null;
    this.max = params.maxDate ?? null;
    this.helperMessage = params.helperMessage ?? null;
    this.selectedDate = params.value;
  }

  getValue() {
    if (typeof this.selectedDate === 'string') {
      return this.selectedDate;
    }
    return this.selectedDate?.toISOString();
  }

  onDateChange(date: Date): void {
    this.selectedDate = date;
    this.params.stopEditing();
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key !== 'Escape') {
      return;
    }
    this.params.stopEditing();
  }
}
