<div class="general-details-container row width-full">
  <div class="col-1 width-10">
    <img src="./assets/icons/globe.svg" alt="home icon" />
  </div>
  <div class="col-2 col">
    <div class="row-1 row">
      <span>General Details</span>
      <button class="view-btn flex va-c" (click)="viewLongTermContract(contractId())">
        <span> View Long Term Contract</span>
        <img src="assets/icons/eye.svg" alt="view" />
      </button>
    </div>
    <div class="row-2 content-style">
      <div class="grid-item job-title">
        <hmt-key-value [keyValue]="jobFileName()"></hmt-key-value>
      </div>

      <div class="grid-item response-cutoff">
        <div class="count-down-container">
          <div>
            <mat-icon>schedule</mat-icon>
          </div>
          <div>
            <div class="font-12 mb-5">
              {{ isTenderClosed() ? 'Tender Closed On:' : 'Tender Closing In:' }}
            </div>
            <div class="font-16">{{ tenderClosingInTimeCounterValue() }}</div>
          </div>
        </div>
      </div>

      <div class="grid-item country-origin">
        <hmt-key-value [keyValue]="countryOfOrigin()"></hmt-key-value>
      </div>

      <div class="grid-item port-origin">
        <hmt-key-value [keyValue]="originLocation()"></hmt-key-value>
      </div>

      <div class="grid-item country-destination">
        <hmt-key-value [keyValue]="countryOfDestination()"></hmt-key-value>
      </div>

      <div class="grid-item port-destination">
        <hmt-key-value [keyValue]="destinationLocation()"></hmt-key-value>
      </div>

      <div class="grid-item incoterm">
        <hmt-key-value [keyValue]="incoterm()"></hmt-key-value>
      </div>

      <div class="grid-item mode-shipment">
        <hmt-key-value [keyValue]="shipmentMode()"></hmt-key-value>
      </div>

      <div class="grid-item cargo-date">
        <hmt-key-value [keyValue]="cargoReadyDate()"></hmt-key-value>
      </div>

      <div class="grid-item required-date">
        <hmt-key-value [keyValue]="inHouseReadyDate()"></hmt-key-value>
      </div>

      <div class="grid-item carrier">
        <hmt-key-value [keyValue]="carrier()"></hmt-key-value>
      </div>

      <div class="grid-item vessel">
        @if (serviceType() === 'OCEAN-FREIGHT') {
          <hmt-key-value [keyValue]="vessel()"></hmt-key-value>
        } @else {
          <hmt-key-value [keyValue]="flight()"></hmt-key-value>
        }
      </div>
      <div class="grid-item estimation">
        <hmt-key-value [keyValue]="estimation()"></hmt-key-value>
      </div>
    </div>
  </div>
</div>
