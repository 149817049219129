<div class="dialog-container">
  <div class="dialog-icon">
    <!-- Add your tick image or icon here -->
    <img *ngIf="success && !warning" src="assets/icons/success-check-circle.svg" alt="operation success" />
    <img *ngIf="!success && !warning" src="assets/icons/error-check-circle.svg" alt="failed operation" />
    <img *ngIf="warning" src="assets/icons/warning-circle.svg" alt="warning operation" />
  </div>
  <p class="dialog-title">{{ title }}</p>
  <p class="dialog-message">{{ message }}</p>
  <div class="dialog-actions">
    <button class="btn-purple-fill" mat-stroked-button (click)="onConfirm()">{{ confirmButtonText }}</button>
    <button class="btn-grey-outline" *ngIf="showCancel" mat-stroked-button mat-dialog-close>No</button>
  </div>
</div>
