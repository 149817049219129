import { ChangeDetectorRef, Component, inject, OnInit, viewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '@core/services/notification/notification.service';
import { ServiceTypes } from 'app/modules/wizard/enums/service-types.enum';
import { first } from 'rxjs';
import { FlightSchedule } from '../../models/flight-schedule.model';
import { UpdateFlightScheduleInput } from '../../queries/update-flight-schedule.query';
import { FlightScheduleService } from '../../services/flight-schedule.service';
import { ServiceCatalogLclChargingStructureComponent } from '../service-catalog-lcl-charging-structure/service-catalog-lcl-charging-structure.component';

@Component({
  selector: 'hmt-update-or-view-rate-structure-for-flight-schedule',
  templateUrl: './update-or-view-rate-structure-for-flight-schedule.component.html',
  styleUrls: ['./update-or-view-rate-structure-for-flight-schedule.component.scss'],
})
export class UpdateOrViewRateStructureForFlightScheduleComponent implements OnInit {
  ServiceTypes = ServiceTypes;

  dialogRef = inject(MatDialogRef);
  notificationService = inject(NotificationService);
  flightScheduleService = inject(FlightScheduleService);
  changeDetectorRef = inject(ChangeDetectorRef);

  lclForm = viewChild<ServiceCatalogLclChargingStructureComponent>('lclForm');

  dialogData = inject(MAT_DIALOG_DATA);
  existingRateStructure = this.dialogData.existingRateStructure as FlightSchedule['rateStructure'];
  flightScheduleId = this.dialogData.flightScheduleId as string;
  onSuccess = this.dialogData.onSuccess as () => void;
  isDisabled = this.dialogData.isDisabled as boolean;
  isReadOnly = this.dialogData.isReadOnly as boolean;

  initFromExistingRateStructure() {
    if (this.existingRateStructure) {
      this.lclForm().setFormValue({
        additionalCharges: this.existingRateStructure.rateCardData.additionalCharges.map(({ amount, description }) => {
          return {
            chargeAmount: amount,
            chargeType: description,
          };
        }),
        columns: this.existingRateStructure.rateCardData.data.values.volumeWeightsRates.volumeWeights.values.map(
          value => {
            return {
              comparison: value < 0 ? '<' : '>',
              value: Math.abs(value),
            };
          }
        ),
        rates: this.existingRateStructure.rateCardData.data.values.volumeWeightsRates.rates.values.map(rate => {
          return {
            rate,
          };
        }),
        mainCurrency: this.existingRateStructure.rateCardData.currencyCode,
      });
    }
  }

  ngOnInit(): void {
    if (this.existingRateStructure) {
      this.initFromExistingRateStructure();
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    const lclFormValue = this.lclForm().getFormValue();

    if (!lclFormValue) {
      this.notificationService.showError('Please fill all the required fields');
      return;
    }

    const updateFlightScheduleInput: UpdateFlightScheduleInput = {
      _id: this.flightScheduleId,
      rateStructure: {
        currencyCode: lclFormValue.mainCurrency,
        additionalCharges: lclFormValue.additionalCharges.map(({ chargeAmount, chargeType }) => {
          return {
            amount: chargeAmount,
            description: chargeType,
            currencyCode: lclFormValue.mainCurrency,
          };
        }),
        data: {
          schema: '',
          values: {
            volumeWeightsRates: {
              volumeWeights: {
                title: 'Volume Weight',
                unit: 'Kg',
                values: lclFormValue.columns.map(({ comparison, value }) => {
                  if (comparison === '<') {
                    return value * -1;
                  }
                  return value;
                }),
              },
              rates: {
                title: 'Rate per Kg',
                unit: 'Kg',
                values: lclFormValue.rates.map(({ rate }) => rate),
              },
            },
          },
        },
      },
    };

    this.flightScheduleService
      .updateFlightSchedule(updateFlightScheduleInput)
      .pipe(first())
      .subscribe({
        next: () => {
          this.notificationService.showSuccess('Flight schedule rate structure updated successfully');
          this.onSuccess();
          this.close();
        },
        error: () => {
          this.notificationService.showError('Failed to update flight schedule rate structure');
        },
      });
  }
}
