<mat-form-field [className]="props.style" [appearance]="props?.appearance">
  <mat-label>{{ props?.label }}</mat-label>
  <input
    matInput
    [owlDateTime]="dt1"
    [owlDateTimeTrigger]="dt1"
    [min]="props?.min"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [placeholder]="props.placeholder"
    [errorStateMatcher]="errorStateMatcher" />
  <mat-icon matSuffix [owlDateTimeTrigger]="dt1">calendar_month</mat-icon>
  <owl-date-time #dt1></owl-date-time>
</mat-form-field>
