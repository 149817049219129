import { Injectable } from '@angular/core';
import { GraphqlClientService } from '@core/services/graphql-client.service';
import {
  FETCH_JOB_FILE_QUERY,
  FIND_COUNTRIES_BY_NAMES_QUERY,
  FIND_COUNTRIES_QUERY,
  GENERATE_DRY_HMT_ACTIVITY_MUTATION,
  GET_COUNTRY_BY_ID_QUERY,
  GET_LOCATION_BY_ID_QUERY,
} from '@shared/gql-shared-queries';
import { Country } from '@shared/models/country.model';
import { Location } from '@shared/models/location.model';
import { Apollo } from 'apollo-angular';
import { catchError, filter, map, Observable, of, take } from 'rxjs';
import { JobFile } from '../models/job-file.model';

@Injectable({
  providedIn: 'root',
})
export class WizardService {
  constructor(
    private apollo: Apollo,
    private graphqlClient: GraphqlClientService
  ) {}

  generateDryHmtActivity(activityId: string, plannedServiceId: string, previousActivityId: string) {
    return this.apollo
      .mutate({
        mutation: GENERATE_DRY_HMT_ACTIVITY_MUTATION,
        variables: { generateDryActivityInput: { activityId, plannedServiceId, previousActivityId } },
      })
      .pipe(
        take(1),
        map(result => result.data),
        catchError(err => {
          console.error('Error generating dry HMT activity', err);
          return of(null);
        })
      );
  }

  getCountriesByShortCodes(countryCodes: string[]) {
    return this.apollo
      .query({
        query: FIND_COUNTRIES_QUERY,
        variables: { countryCodes },
      })
      .pipe(
        take(1),
        map(result => result.data['getCountriesByShortCodes'])
      );
  }

  getCountriesByNames(countryNames: string[]) {
    return this.apollo
      .query({
        query: FIND_COUNTRIES_BY_NAMES_QUERY,
        variables: { countryNames },
      })
      .pipe(
        take(1),
        map(result => result.data['getCountriesByNames'])
      );
  }

  findLocationById(locationId = ''): Observable<Location> {
    return this.graphqlClient
      .query<{ findLocationById: Location }, { locationId: string }>(GET_LOCATION_BY_ID_QUERY, {
        locationId: locationId,
      })
      .pipe(
        filter(result => !!result),
        take(1),
        map(result => result.findLocationById)
      );
  }

  findCountryById(countryId: string): Observable<Country> {
    return this.graphqlClient
      .query<{ getCountryById: Country }, { countryId: string }>(GET_COUNTRY_BY_ID_QUERY, {
        countryId: countryId,
      })
      .pipe(
        take(1),
        map(result => result.getCountryById)
      );
  }

  findJobFileByJobRefId(jobRefId: string): Observable<JobFile> {
    return this.graphqlClient
      .query<{ findByJobRefId: JobFile }, { jobRefId: string }>(FETCH_JOB_FILE_QUERY, { jobRefId })
      .pipe(
        take(1),
        map(result => result.findByJobRefId)
      );
  }
}
