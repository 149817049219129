import { inject, Injectable, signal } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { VesselSchedule } from 'app/modules/catalog-manager/models/vessel-schedule.model';
import {
  GET_VESSEL_SCHEDULES_BY_IDS,
  GetVesselSchedulesByIdsQueryResponse,
} from 'app/modules/catalog-manager/queries/get-vessel-schedules-by-ids.query';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VesselScheduleDetailsService {
  apollo = inject(Apollo);

  vesselSchedules = signal<Record<string, { schedule: VesselSchedule; timestamp: number }>>({});
  private pendingRequests = new Map<string[], Promise<VesselSchedule[]>>();

  async getVesselSchedules(ids: string[]) {
    const now = Date.now();
    const FIFTY_NINE_MINUTES = 59 * 60 * 1000;
    const cachedSchedules: VesselSchedule[] = [];
    const uncachedIds: string[] = [];

    // Check cache for each id
    ids.forEach(id => {
      const cachedSchedule = this.vesselSchedules()[id];
      if (cachedSchedule && now - cachedSchedule.timestamp < FIFTY_NINE_MINUTES) {
        cachedSchedules.push(cachedSchedule.schedule);
      } else {
        uncachedIds.push(id);
      }
    });

    // Return cached results if all found
    if (uncachedIds.length === 0) {
      return cachedSchedules;
    }

    // Check for pending request with same ids
    const pendingKey = Array.from(this.pendingRequests.keys()).find(
      key => key.length === uncachedIds.length && key.every(id => uncachedIds.includes(id))
    );
    if (pendingKey) {
      const pendingSchedules = await this.pendingRequests.get(pendingKey);
      return [...cachedSchedules, ...pendingSchedules];
    }

    // Create new request and store it
    const request = firstValueFrom(
      this.apollo.query<GetVesselSchedulesByIdsQueryResponse>({
        query: GET_VESSEL_SCHEDULES_BY_IDS,
        variables: { ids: uncachedIds },
      })
    ).then(result => result.data.getVesselSchedulesByIds);

    this.pendingRequests.set(uncachedIds, request);

    try {
      const schedules = await request;

      // Update cache
      const updatedCache = { ...this.vesselSchedules() };
      schedules.forEach(schedule => {
        updatedCache[schedule._id] = {
          schedule,
          timestamp: now,
        };
      });
      this.vesselSchedules.set(updatedCache);

      return [...cachedSchedules, ...schedules];
    } finally {
      // Clean up pending request
      this.pendingRequests.delete(uncachedIds);
    }
  }
}
