import { Component, computed, effect, inject, OnDestroy, signal } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EnhancedDatagridRoutingEditorParams } from '@shared/types/enhanced-grid-types/enhanced-grid-components.type';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { CarrierRoutingType } from 'app/modules/catalog-manager/enums/carrier-routing-type.enum';
import { CarrierRoutingStopDetailed } from 'app/modules/catalog-manager/models/carrier-routing-details.model';
import { getRoutingTypeHumanReadable } from 'app/utils/routing-type.util';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'hmt-enhanced-datagrid-routing-editor',
  templateUrl: './enhanced-datagrid-routing-editor.component.html',
  styleUrls: ['./enhanced-datagrid-routing-editor.component.scss'],
})
export class EnhancedDatagridRoutingEditorComponent implements ICellEditorAngularComp, OnDestroy {
  destroy = new Subject<void>();

  CarrierRoutingType = CarrierRoutingType;

  formBuilder = inject(FormBuilder);

  params: EnhancedDatagridRoutingEditorParams;

  for = signal<EnhancedDatagridRoutingEditorParams['for']>('vessels');

  initialRoutingType: CarrierRoutingType | null = null;
  routingType = signal<CarrierRoutingType | null>(null);
  routingTypeOptions = Object.values(CarrierRoutingType);

  initialStops: CarrierRoutingStopDetailed[] | [CarrierRoutingStopDetailed, CarrierRoutingStopDetailed] = [];
  stops = signal<CarrierRoutingStopDetailed[]>([]);

  stopsFormArray = computed(() => {
    const stops = this.stops();
    let stopsToUse = stops;

    // Ensure we always have at least 2 stops
    if (stops.length < 2) {
      stopsToUse = [...stops, ...Array(2 - stops.length).fill({ id: '', name: '', shortName: '' })];
    }

    // For DIRECT type, only use first and last stops
    if (this.routingType() === CarrierRoutingType.DIRECT) {
      stopsToUse = [stopsToUse[0], stopsToUse[stopsToUse.length - 1]];
    }

    const formArray = this.formBuilder.array<FormGroup>(stopsToUse.map(stop => this.formBuilder.group({ stop })));

    formArray.valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
      this.makeButtonDisabledOrEnabled();
    });

    return formArray;
  });

  isSaveButtonEnabled = signal(false);

  makeButtonDisabledOrEnabled() {
    const formValues = this.stopsFormArray().value as { stop: CarrierRoutingStopDetailed }[];
    const stops = formValues.filter(({ stop }) => typeof stop !== 'string').map(({ stop }) => ({ ...stop }));
    const isEveryStopCompleted = stops.every(
      stop => typeof stop !== 'string' && stop.id !== '' && stop.name !== '' && stop.shortName !== ''
    );
    const stopCount = stops.length;
    const selectedRoutingType = this.routingType();

    if (selectedRoutingType === CarrierRoutingType.DIRECT) {
      this.isSaveButtonEnabled.set(isEveryStopCompleted && stopCount === 2);
    } else {
      this.isSaveButtonEnabled.set(isEveryStopCompleted && stopCount > 2);
    }
  }

  constructor() {
    effect(
      () => {
        const _newStops = this.stops();
        this.makeButtonDisabledOrEnabled();
      },
      { allowSignalWrites: true }
    );
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  agInit(params: EnhancedDatagridRoutingEditorParams): void {
    this.params = params;
    this.for.set(params.for);
    this.initialRoutingType = params.data.routingDetails?.type ?? CarrierRoutingType.DIRECT;
    this.initialStops = params.data.routingDetails?.stops ?? [];
    this.routingType.set(params.data.routingDetails?.type ?? CarrierRoutingType.DIRECT);
    this.stops.set(
      params.data.routingDetails?.stops ?? [
        { id: '', name: '', shortName: '' },
        { id: '', name: '', shortName: '' },
      ]
    );
  }

  getValue() {
    if (!this.isSaveButtonEnabled()) {
      return {
        type: this.initialRoutingType,
        stops: this.initialStops,
      };
    } else {
      const formValues = this.stopsFormArray().value;
      return {
        type: this.routingType(),
        stops: formValues.map(({ stop }) => stop),
      };
    }
  }

  onOptionChange(option: CarrierRoutingType): void {
    this.routingType.set(option);
    const currentStops = this.stops();
    if (option === CarrierRoutingType.DIRECT && currentStops.length > 2) {
      this.stops.set([currentStops[0], currentStops[currentStops.length - 1]]);
    }
  }

  removeStop(index: number): void {
    const currentStops = this.stops();
    if (currentStops.length > 2) {
      this.stops.update(stops => stops.filter((_, i) => i !== index));
    }
  }

  addIntermediateStop(): void {
    this.stops.update(stops => {
      if (stops.length < 2) {
        return [
          { id: '', name: '', shortName: '' },
          { id: '', name: '', shortName: '' },
        ];
      }
      const lastStop = stops[stops.length - 1];
      const stopsWithoutLast = stops.slice(0, -1);
      return [...stopsWithoutLast, { id: '', name: '', shortName: '' }, lastStop];
    });
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key !== 'Escape') {
      return;
    }

    if (this.routingType() && this.stops().length >= 2) {
      this.params.stopEditing();
    }
  }

  onSave(): void {
    this.params.stopEditing();
  }

  getRoutingTypeHumanReadable(routingType: CarrierRoutingType): string {
    return getRoutingTypeHumanReadable(routingType);
  }

  getPopupPosition(): 'over' | 'under' | undefined {
    return 'over';
  }
}
