/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from 'apollo-angular';
import { LongTermRatesContract } from '../models/long-term-contract.model';

export const FIND_LONG_TERM_RATES_CONTRACT_BY_ID = gql`
  query FindLongTermRatesContractById($longTermContractId: String!) {
    findLongTermRatesContractById(longTermContractId: $longTermContractId) {
      _id
      bidId
      contractRef
      contractType
      createdAt
      createdBy
      currency
      documents {
        contentType
        heading
        id
        name
        originalFileName
        version
      }
      metaData {
        data
        metaDataType
        serviceMetaData {
          loadIds {
            loadId
          }
          locationPairs {
            _id
            accepted
            createdAt
            createdBy
            destinationLocation {
              countryId
              countryName
              locationId
              locationName
              locationType
            }
            favorite
            flightScheduleReferenceGroup {
              carrierReference {
                carrierRef
              }
              shipmentMode
            }
            originLocation {
              countryId
              countryName
              locationId
              locationName
              locationType
            }
            shipmentMethodsData
            transhipmentAllowed
            updatedAt
            updatedBy
            voteMetaData {
              noOfVotes
              votes {
                supperVote
                voted
                votedBy {
                  _id
                  email
                  firstName
                  lastName
                  mobile
                  officeMobilePhone
                  onBehalfOfOrgUser
                  orgId
                }
                voteId
              }
            }
            vesselsScheduleReferenceGroup {
              carrierReference {
                carrierRef
              }
              shipmentMode
            }
          }
          plannedService
          ratesSummary
          serviceData
          serviceId
          rateCard {
            _id
            contractedParty {
              _id
              orgName
            }
            contractedPartyId
            contractRef
            createdAt
            createdBy
            executionMethods
            operationId
            orgId
          }
        }
      }
      name
      onBehalfOfSomeOneElse
      parties {
        buyer {
          companyReg
          logo
          managedByOrgId
          onBehalfOf
          orgName
          partyId
          partyName
          partyType
          registeredAddress {
            addressLine
            city
            country
            state
          }
        }
        fourPL {
          companyReg
          logo
          managedByOrgId
          onBehalfOf
          orgName
          partyId
          partyName
          partyType
          registeredAddress {
            addressLine
            city
            country
            state
          }
        }
        others {
          companyReg
          logo
          managedByOrgId
          onBehalfOf
          orgName
          partyId
          partyName
          partyType
          registeredAddress {
            addressLine
            city
            country
            state
          }
        }
        seller {
          companyReg
          logo
          managedByOrgId
          onBehalfOf
          orgName
          partyId
          partyName
          partyType
          registeredAddress {
            addressLine
            city
            country
            state
          }
        }
      }
      paymentTerm {
        option
        paymentMethodId
        paymentTermId
        text
      }
      usage {
        unlimited
        usageCount {
          appliedCount
          remainingCount
          usableCount
        }
      }
      updatedBy

      updatedAt
      tenderType
      tenderRefNumber
      tenderId
      temporalWorkflowId
      temporalRunId
      status
      ratesData {
        data
        metaDataType
      }
      ratesValidityPeriod {
        effectiveDate
        from
        to
      }
    }
  }
`;

export interface FindLongTermRatesContractByIdResponse {
  findLongTermRatesContractById: LongTermRatesContract;
  // findLongTermRatesContractById: {
  //   _id: string;
  //   bidId: string;
  //   contractRef: string;
  //   contractType: string;
  //   createdAt: string;
  //   createdBy: string;
  //   currency: string;
  //   metaData: {
  //     data: any;
  //     metaDataType: string;
  //     serviceMetaData: Array<{
  //       loadIds: any[];
  //       locationPairs: Array<{
  //         _id: string;
  //         accepted: boolean;
  //         createdAt: string;
  //         createdBy: string | null;
  //         destinationLocation: {
  //           countryId: string;
  //           countryName: string;
  //           locationId: string;
  //           locationName: string;
  //           locationType: string;
  //         };
  //         favorite: boolean;
  //         flightScheduleReferenceGroup: {
  //           carrierReference: Array<{
  //             carrierRef: string;
  //           }>;
  //           shipmentMode: string;
  //         };
  //         originLocation: {
  //           countryId: string;
  //           countryName: string;
  //           locationId: string;
  //           locationName: string;
  //           locationType: string;
  //         };
  //         shipmentMethodsData: {
  //           LCL: {
  //             volumeWeights: number[];
  //           };
  //           FCL: {
  //             containerTypes: Array<{
  //               name: string;
  //               _id: string;
  //             }>;
  //           };
  //           rateCards: Array<{
  //             shipmentMethod: string;
  //             rateCard: Array<{
  //               shipmentMode: string;
  //               containerType: string;
  //               unitPrice: number;
  //             }>;
  //           }>;
  //         };
  //         transhipmentAllowed: boolean;
  //         updatedAt: string;
  //         updatedBy: string | null;
  //         voteMetaData: {
  //           noOfVotes: number;
  //           votes: Array<{
  //             supperVote: boolean;
  //             voted: boolean;
  //             votedBy: {
  //               _id: string;
  //               email: string | null;
  //               firstName: string;
  //               lastName: string;
  //               mobile: string | null;
  //               officeMobilePhone: string | null;
  //               onBehalfOfOrgUser: boolean;
  //               orgId: string;
  //             };
  //             voteId: string;
  //           }>;
  //         };
  //         vesselsScheduleReferenceGroup: Array<{
  //           carrierReference: Array<{
  //             carrierRef: string;
  //           }>;
  //           shipmentMode: string;
  //         }>;
  //       }>;
  //       plannedService: any;
  //       ratesSummary: any;
  //       serviceData: any;
  //       serviceId: string;
  //       rateCard: any;
  //     }>;
  //   };
  //   name: string;
  //   onBehalfOfContractId: string | null;
  //   onBehalfOfCustomerId: string | null;
  //   onBehalfOfSomeOneElse: boolean;
  //   parties: {
  //     buyer: {
  //       companyReg: string;
  //       logo: string;
  //       managedByOrgId: string | null;
  //       onBehalfOf: boolean;
  //       orgName: string;
  //       partyId: string;
  //       partyName: string | null;
  //       partyType: 'BUYER';
  //     } | null;
  //     fourPL: {
  //       companyReg: string;
  //       logo: string;
  //       managedByOrgId: string | null;
  //       onBehalfOf: boolean;
  //       orgName: string;
  //       partyId: string;
  //       partyName: string | null;
  //       partyType: '4PL';
  //     } | null;
  //     others: {
  //       companyReg: string;
  //       logo: string;
  //       managedByOrgId: string | null;
  //       onBehalfOf: boolean;
  //       orgName: string;
  //       partyId: string;
  //       partyName: string | null;
  //       partyType: 'OTHER';
  //     } | null;
  //     seller: {
  //       companyReg: string;
  //       logo: string;
  //       managedByOrgId: string | null;
  //       onBehalfOf: boolean;
  //       orgName: string;
  //       partyId: string;
  //       partyName: string | null;
  //       partyType: 'SELLER';
  //     } | null;
  //   };
  //   paymentTerm: {
  //     option: string | null;
  //     paymentMethodId: string;
  //     paymentTermId: string;
  //     text: string;
  //   };
  //   usage: {
  //     unlimited: boolean;
  //     usageCount: {
  //       appliedCount: number;
  //       remainingCount: number;
  //       usableCount: number;
  //     };
  //   };
  //   updatedBy: string;
  //   updatedAt: string;
  //   tenderType: string | null;
  //   tenderRefNumber: string | null;
  //   tenderId: string;
  //   temporalWorkflowId: string;
  //   temporalRunId: string;
  //   status: string;
  //   ratesData: {
  //     data: any;
  //     metaDataType: string;
  //   };
  //   ratesValidityPeriod: {
  //     effectiveDate: string;
  //     from: string;
  //     to: string;
  //   };
  // };
}
