import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { FlightScheduleTableRow } from 'app/modules/catalog-manager/models/flight-schedule.model';

@Component({
  selector: 'hmt-enhanced-datagrid-flight-dates-renderer',
  templateUrl: './enhanced-datagrid-flight-dates-renderer.component.html',
  styleUrls: ['./enhanced-datagrid-flight-dates-renderer.component.scss'],
})
export class EnhancedDatagridFlightDatesRendererComponent implements ICellRendererAngularComp {
  private params!: ICellRendererParams<FlightScheduleTableRow, Array<string>>;
  value!: Array<string>;

  agInit(params: ICellRendererParams<FlightScheduleTableRow, Array<string>>): void {
    this.params = params;
    this.setValue(params);
  }

  refresh(params: ICellRendererParams<FlightScheduleTableRow, Array<string>>): boolean {
    this.params = params;
    this.setValue(params);
    return true;
  }

  private setValue(params: ICellRendererParams<FlightScheduleTableRow, Array<string>>) {
    this.value = params.value;
  }

  getFormattedValue(): string {
    return this.value.join(', ');
  }
}
