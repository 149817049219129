/*
 * This file contains the utility functions for the status chip
 * Add new status return functions here
 * All the classes for the status chip are defined in the styles/abstract/_common.scss file
 * Create the new class in the _common.scss file when adding new statuses
 */

import { LongTermRatesContractStatus } from 'app/modules/contract-management/types/long-term-rates-contract-status.type';
import { TenderStates } from 'app/modules/procurement/enums/tender-status.enum';

/**
 * Get the status class for the tenders statuses through out the application
 * @param status - The tender status
 * @returns The status class
 */
export function getTenderChipStatusClass(status: string) {
  switch (status) {
    case TenderStates.PUBLISHED:
      return 'published status-published';
    case TenderStates.EVALUATING:
      return 'evaluating status-evaluating';
    case TenderStates.IN_PROGRESS:
      return 'in-progress status-in-progress';
    case TenderStates.OFFERED:
      return 'offered status-offered';
    case TenderStates.AWARDED:
      return 'awarded status-awarded';
    case TenderStates.TERMINATED:
      return 'terminated status-terminated';
    case TenderStates.EXPIRED:
      return 'expired status-expired';
    case TenderStates.ALL_BIDS_REJECTED:
      return 'rejected status-rejected';
    default:
      return 'common';
  }
}

/**
 * Get the status class for the tenders statuses through out the application
 * @param status - The tender status
 * @returns The status class
 */
export function getLongTermRatesContractChipStatusClass(status: LongTermRatesContractStatus | TenderStates) {
  switch (status) {
    case 'CONTRACTED':
      return 'offered status-offered';
    case 'AWARDED':
      return 'awarded status-awarded';
    case 'EXPIRED':
      return 'expired status-expired';
    case 'TERMINATED':
      return 'terminated status-terminated';
    case 'PENDING':
      return 'pending status-pending';
    default:
      return 'common';
  }
}
