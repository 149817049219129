import { CarrierRoutingType } from 'app/modules/catalog-manager/enums/carrier-routing-type.enum';
export function getRoutingTypeHumanReadable(routingType: CarrierRoutingType) {
  switch (routingType) {
    case CarrierRoutingType.DIRECT:
      return 'Direct';
    case CarrierRoutingType.TRANSIT:
      return 'Transit';
    default:
      return 'Unknown';
  }
}
