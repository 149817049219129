import { AfterViewInit, Component, ElementRef, inject, input, signal, viewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DEFAULT_DEBOUNCE_TIME } from '@shared/constants';
import { LocationType } from '@shared/enums/location-type.enum';
import { LocationsService } from '@shared/services/locations.service';
import { debounceTime, first, fromEvent } from 'rxjs';

@Component({
  selector: 'hmt-airport-selector',
  templateUrl: './airport-selector.component.html',
  styleUrls: ['./airport-selector.component.scss'],
})
export class AirportSelectorComponent implements AfterViewInit {
  locationsService = inject(LocationsService);

  form = input<FormGroup>();
  controlName = input<string>();
  label = input<string>();

  airportInput = viewChild.required<ElementRef<HTMLInputElement>>('airportInput');
  airports = signal<{ id: string; name: string; shortName: string }[]>([]);

  ngAfterViewInit(): void {
    fromEvent(this.airportInput()?.nativeElement, 'input')
      .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME))
      .subscribe(() => {
        const airportText = this.airportInput()?.nativeElement?.value ?? undefined;
        this.loadAirports(airportText);
      });
  }

  loadAirports(searchText = undefined) {
    this.locationsService
      .getLocationsWithSearch(LocationType.AIRPORT, searchText)
      .pipe(first())
      .subscribe(airports => {
        this.airports.set(airports);
      });
  }

  displayAirportName(airport: { id: string; name: string; shortName: string }) {
    if (airport) {
      return airport.name;
    }
    return '';
  }
}
