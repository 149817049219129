import { Component, computed, signal } from '@angular/core';
import { EnhancedDatagridFlightDatesFrequencyEditorParams } from '@shared/types/enhanced-grid-types/enhanced-grid-components.type';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { FlightFrequencyType } from 'app/modules/catalog-manager/enums/flight-frequency-type.enum';
import { flightFrequencyToHumanReadable, getFlightFrequencyFromHumanReadable } from 'app/utils/flight-frequency.util';

@Component({
  selector: 'hmt-enhanced-datagrid-flight-dates-frequency-editor',
  templateUrl: './enhanced-datagrid-flight-dates-frequency-editor.component.html',
  styleUrls: ['./enhanced-datagrid-flight-dates-frequency-editor.component.scss'],
})
export class EnhancedDatagridFlightDatesFrequencyEditorComponent implements ICellEditorAngularComp {
  params: EnhancedDatagridFlightDatesFrequencyEditorParams;
  selectedOption = signal<FlightFrequencyType>(FlightFrequencyType.DAILY);
  options = Object.values(FlightFrequencyType);
  for = signal<'firstLeg' | 'secondLeg'>('firstLeg');
  noOfDays = signal(0);
  minNoOfDays = signal(0);
  maxNoOfDays = signal(0);
  isNoOfDaysDisabled = signal(false);

  isSaveButtonEnabled = computed(() => {
    if (
      this.selectedOption() === FlightFrequencyType.PER_WEEK ||
      this.selectedOption() === FlightFrequencyType.SPECIFIC_DAYS
    ) {
      return this.noOfDays() > 0 && this.noOfDays() <= 7;
    }

    if (this.selectedOption() === FlightFrequencyType.DAILY) {
      return this.noOfDays() === 7;
    }

    return false;
  });

  labelForInput = computed(() => {
    return this.for() === 'firstLeg' ? 'Choose First Leg Frequency' : 'Choose Second Leg Frequency';
  });

  agInit(params: EnhancedDatagridFlightDatesFrequencyEditorParams): void {
    this.params = params;
    this.for.set(params.for);
    this.selectedOption.set(
      this.getFlightFrequencyFromHumanReadable(params.value as 'Daily' | 'Per Week' | 'Specific Days')
    );
    if (this.for() === 'firstLeg') {
      this.setAppropriateDefaultNoOfDaysInputParams(this.selectedOption(), params.data.firstLegNoOfDays);
    } else {
      this.setAppropriateDefaultNoOfDaysInputParams(this.selectedOption(), params.data.secondLegNoOfDays);
    }
    this.options = Object.values(FlightFrequencyType);
  }

  getValue() {
    return {
      frequency: this.selectedOption(),
      noOfDays: this.noOfDays(),
    };
  }

  onOptionChange(option: FlightFrequencyType): void {
    console.log('option', option);
    this.selectedOption.set(option);
    this.setAppropriateDefaultNoOfDaysInputParams(option);
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key !== 'Escape') {
      return;
    }

    if (this.selectedOption() && this.noOfDays()) {
      this.params.stopEditing();
    }
  }

  onSave(): void {
    this.params.stopEditing();
  }

  setAppropriateDefaultNoOfDaysInputParams(frequency: FlightFrequencyType, noOfDays?: number) {
    if (frequency === FlightFrequencyType.DAILY) {
      this.noOfDays.set(noOfDays ?? 7);
      this.minNoOfDays.set(7);
      this.maxNoOfDays.set(7);
      this.isNoOfDaysDisabled.set(true);
    }

    if (frequency === FlightFrequencyType.PER_WEEK || frequency === FlightFrequencyType.SPECIFIC_DAYS) {
      this.noOfDays.set(noOfDays ?? 1);
      this.minNoOfDays.set(1);
      this.maxNoOfDays.set(7);
      this.isNoOfDaysDisabled.set(false);
    }
  }

  flightFrequencyToHumanReadable(frequency: FlightFrequencyType): string {
    return flightFrequencyToHumanReadable(frequency);
  }

  getFlightFrequencyFromHumanReadable(humanReadable: 'Daily' | 'Per Week' | 'Specific Days'): FlightFrequencyType {
    return getFlightFrequencyFromHumanReadable(humanReadable);
  }
}
