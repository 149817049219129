import { Component, inject, input, OnInit, signal } from '@angular/core';
import { DialogHandlerService } from '@core/services/dialog-handler.service';
import { NotificationService } from '@core/services/notification/notification.service';
import { FlightScheduleDetailsService } from '@shared/services/flight-schedule-details.service';
import { LocationNamesService } from '@shared/services/location-names.service';
import { EnhancedColDef } from '@shared/types/enhanced-grid-types/enhanced-grid-common.type';
import { UpdateOrViewRateStructureForFlightScheduleComponent } from 'app/modules/catalog-manager/components/update-or-view-rate-structure-for-flight-schedule/update-or-view-rate-structure-for-flight-schedule.component';
import { FlightSchedule, FlightScheduleTableRow } from 'app/modules/catalog-manager/models/flight-schedule.model';
import { flightFrequencyToHumanReadable } from 'app/utils/flight-frequency.util';
import { EnhancedDatagridAirportRendererComponent } from '../enhanced-datagrid/components/enhanced-datagrid-airport-renderer/enhanced-datagrid-airport-renderer.component';
import { EnhancedDatagridRateStructureRendererComponent } from '../enhanced-datagrid/components/enhanced-datagrid-rate-structure-renderer/enhanced-datagrid-rate-structure-renderer.component';
import { EnhancedDatagridRoutingRendererComponent } from '../enhanced-datagrid/components/enhanced-datagrid-routing-renderer/enhanced-datagrid-routing-renderer.component';

@Component({
  selector: 'hmt-long-term-location-pair-flight-schedule-table',
  templateUrl: './long-term-location-pair-flight-schedule-table.component.html',
  styleUrl: './long-term-location-pair-flight-schedule-table.component.scss',
})
export class LongTermLocationPairFlightScheduleTableComponent implements OnInit {
  flightScheduleDetailsService = inject(FlightScheduleDetailsService);
  locationNamesService = inject(LocationNamesService);
  notificationService = inject(NotificationService);
  dialogHandlerService = inject(DialogHandlerService);

  flightScheduleGroup = input.required<{
    name: string;
    carrierReferences: {
      carrierRef: string;
    }[];
    shipmentMode: string;
  }>();

  flightSchedules = signal<FlightSchedule[]>([]);
  rowData = signal<FlightScheduleTableRow[]>([]);
  loading = signal(false);
  loadingLocationDetails = signal(false);

  async ngOnInit() {
    this.loading.set(true);
    try {
      const schedules = await this.flightScheduleDetailsService.getFlightSchedules(
        this.flightScheduleGroup().carrierReferences.map(c => c.carrierRef)
      );
      this.flightSchedules.set(schedules);
      this.loadingLocationDetails.set(true);
      const mappedFlightSchedules = await Promise.all(this.flightSchedules().map(this.flightScheduleToRow.bind(this)));
      this.rowData.set(mappedFlightSchedules as FlightScheduleTableRow[]);
      this.loadingLocationDetails.set(false);
    } catch (error) {
      console.error('Error fetching flight schedules', error);
      this.notificationService.showError('Failed to fetch flight schedules');
    } finally {
      this.loading.set(false);
    }
  }

  colDefs: EnhancedColDef<FlightScheduleTableRow>[] = [
    {
      field: 'flightName',
      headerName: 'Flight Name',
      pinned: 'left',
      colType: 'text',
      editable: false,
    },
    {
      field: 'airLine',
      headerName: 'Air Line',
      colType: 'text',
      editable: false,
    },
    {
      field: 'from',
      headerName: 'From',
      editable: false,
      valueGetter: params => params.data.from,
      colType: 'airport',
      cellRenderer: EnhancedDatagridAirportRendererComponent,
    },
    {
      field: 'fromEta',
      headerName: 'From ETA',
      editable: false,
      colType: 'datetime',
    },
    {
      field: 'fromEtd',
      headerName: 'From ETD',
      editable: false,
      colType: 'datetime',
    },
    {
      field: 'to',
      headerName: 'To',
      editable: false,
      colType: 'airport',
      valueGetter: params => params.data.to,
      cellRenderer: EnhancedDatagridAirportRendererComponent,
    },
    {
      field: 'toEta',
      headerName: 'To ETA',
      editable: false,
      colType: 'datetime',
    },
    {
      field: 'toEtd',
      headerName: 'To ETD',
      editable: false,
      colType: 'datetime',
    },
    {
      field: 'flightCutoff',
      headerName: 'Flight Cut Off',
      editable: false,
      colType: 'number',
      valueGetter: params => (params.data.flightCutoff ? `${params.data.flightCutoff} days` : undefined),
    },
    {
      field: 'firstLegFrequency',
      headerName: 'First Leg Frequency',
      colType: 'flight-dates-frequency',
      editable: false,
      valueGetter: params => flightFrequencyToHumanReadable(params.data.firstLegFrequency),
    },
    {
      field: 'firstLegDepartureDays',
      headerName: 'First Leg Departure Days',
      editable: false,
      colType: 'flight-dates',
      valueGetter: params => params.data.firstLegDepartureDays.join(', '),
    },
    {
      field: 'secondLegFrequency',
      headerName: 'Second Leg Frequency',
      colType: 'flight-dates-frequency',
      editable: false,
      valueGetter: params => flightFrequencyToHumanReadable(params.data.secondLegFrequency),
    },
    {
      field: 'secondLegArrivalDays',
      headerName: 'Second Leg Arrival Days',
      editable: false,
      colType: 'flight-dates',
      valueGetter: params => params.data.secondLegArrivalDays.join(', '),
    },
    {
      field: 'totalTransitTime',
      headerName: 'Total Transit Time',
      colType: 'number',
      editable: false,
      valueGetter: params => (params.data.totalTransitTime ? `${params.data.totalTransitTime} days` : undefined),
    },
    {
      field: 'routingDetails',
      headerName: 'Routing',
      colType: 'routing',
      editable: false,
      valueGetter: params => params.data.routingDetails?.stops ?? [],
      cellRenderer: EnhancedDatagridRoutingRendererComponent,
    },
    {
      colType: 'custom',
      headerName: 'Rate Structure',
      cellRenderer: EnhancedDatagridRateStructureRendererComponent,
      editable: false,
      cellRendererParams: params => {
        return {
          openDrawer: this.openRateStructureDrawer.bind(this, params.data),
        };
      },
    },
  ];

  async flightScheduleToRow(flightSchedule: FlightSchedule): Promise<FlightScheduleTableRow> {
    const fromName = flightSchedule.from ? await this.locationNamesService.getLocationName(flightSchedule.from) : null;
    const toName = flightSchedule.to ? await this.locationNamesService.getLocationName(flightSchedule.to) : null;
    return {
      ...flightSchedule,
      from: fromName
        ? {
            id: flightSchedule.from,
            name: fromName.name,
            shortName: fromName.shortName,
          }
        : undefined,
      to: toName
        ? {
            id: flightSchedule.to,
            name: toName.name,
            shortName: toName.shortName,
          }
        : undefined,
      routingDetails: flightSchedule.routingDetails
        ? {
            type: flightSchedule.routingDetails.type,
            stops: (await Promise.all(
              flightSchedule.routingDetails.stops.map(async stop => {
                const { name, shortName } = await this.locationNamesService.getLocationName(stop);
                return {
                  id: stop,
                  name,
                  shortName,
                };
              })
            )) as [
              {
                id: string;
                name: string;
                shortName: string;
              },
              {
                id: string;
                name: string;
                shortName: string;
              },
            ],
          }
        : null,
    };
  }
  openRateStructureDrawer(row: FlightScheduleTableRow) {
    this.dialogHandlerService.openDialog(UpdateOrViewRateStructureForFlightScheduleComponent, {
      flightScheduleId: row._id,
      existingRateStructure: row.rateStructure,
      isDisabled: true,
      isReadOnly: true,
    });
  }
}
