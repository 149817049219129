import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';
import {
  CREATE_VESSEL_SCHEDULE_QUERY,
  CreateVesselScheduleInput,
  CreateVesselScheduleResponse,
} from '../queries/create-vessel-schedule.query';
import { DELETE_VESSEL_SCHEDULE_QUERY, DeleteVesselScheduleResponse } from '../queries/delete-vessel-schedule.query';
import {
  GET_VESSEL_SCHEDULES_BY_IDS,
  GetVesselSchedulesByIdsQueryResponse,
} from '../queries/get-vessel-schedules-by-ids.query';
import {
  GET_VESSEL_SCHEDULES_BY_ORG_ID,
  GetVesselSchedulesByOrgIdInput,
  GetVesselSchedulesByOrgIdQueryResponse,
} from '../queries/get-vessel-schedules-by-org-id.query';
import {
  UPDATE_VESSEL_SCHEDULE_QUERY,
  UpdateVesselScheduleInput,
  UpdateVesselScheduleResponse,
} from '../queries/update-vessel-schedule.query';

@Injectable({
  providedIn: 'root',
})
export class VesselScheduleService {
  apollo = inject(Apollo);

  createVesselSchedule(createVesselScheduleInput: CreateVesselScheduleInput) {
    return this.apollo
      .mutate<CreateVesselScheduleResponse>({
        mutation: CREATE_VESSEL_SCHEDULE_QUERY,
        variables: { createVesselScheduleInput },
      })
      .pipe(map(result => result.data.createdVesselSchedule));
  }

  updateVesselSchedule(updateVesselScheduleInput: UpdateVesselScheduleInput) {
    return this.apollo
      .mutate<UpdateVesselScheduleResponse>({
        mutation: UPDATE_VESSEL_SCHEDULE_QUERY,
        variables: { updateVesselScheduleInput },
      })
      .pipe(map(result => result.data.updatedVesselSchedule));
  }

  getVesselSchedulesByOrgId(getVesselSchedulesByOrgIdInput: GetVesselSchedulesByOrgIdInput) {
    return this.apollo
      .query<GetVesselSchedulesByOrgIdQueryResponse>({
        query: GET_VESSEL_SCHEDULES_BY_ORG_ID,
        variables: { getVesselSchedulesByOrgIdInput },
      })
      .pipe(map(result => result.data.getVesselSchedulesByOrgId));
  }

  deleteVesselSchedule(scheduleId: string) {
    return this.apollo
      .mutate<DeleteVesselScheduleResponse>({
        mutation: DELETE_VESSEL_SCHEDULE_QUERY,
        variables: { scheduleId },
      })
      .pipe(map(result => result.data.deletedVesselSchedule));
  }

  getVesselSchedulesByIds(ids: string[]) {
    return this.apollo
      .query<GetVesselSchedulesByIdsQueryResponse>({
        query: GET_VESSEL_SCHEDULES_BY_IDS,
        variables: { ids },
      })
      .pipe(map(result => result.data.getVesselSchedulesByIds));
  }
}
