import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EnhancedDatagridPortEditorParams } from '@shared/types/enhanced-grid-types/enhanced-grid-components.type';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'hmt-enhanced-datagrid-port-editor',
  templateUrl: './enhanced-datagrid-port-editor.component.html',
  styleUrls: ['./enhanced-datagrid-port-editor.component.scss'],
})
export class EnhancedDatagridPortEditorComponent implements ICellEditorAngularComp {
  params!: EnhancedDatagridPortEditorParams;

  form = new FormGroup({
    port: new FormControl<
      | {
          id: string;
          name: string;
          shortName: string;
        }
      | string
      | null
    >(null),
  });

  agInit(params: EnhancedDatagridPortEditorParams): void {
    this.params = params;

    this.form.patchValue({
      port: params.value,
    });
  }

  getValue() {
    return this.form.value.port;
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key !== 'Escape') {
      return;
    }
    this.params.stopEditing();
  }
}
