import { gql } from 'apollo-angular';
import { AdditionalCharge } from 'app/modules/procurement/models/additional-charges.model';
import { VesselSchedule } from '../models/vessel-schedule.model';

export const UPDATE_VESSEL_SCHEDULE_QUERY = gql`
  mutation UpdateVesselSchedule($updateVesselScheduleInput: UpdateVesselScheduleInput!) {
    updatedVesselSchedule: updateVesselSchedule(updateVesselScheduleInput: $updateVesselScheduleInput) {
      _id
      cfsCutoff
      createdAt
      createdBy
      cyCutoffDate
      from
      fromEta
      fromEtd
      orgId
      previousPortOfCall
      previousPortOfCallEta
      previousPortOfCallEtd
      shippingLine
      to
      toEta
      toEtd
      totalTransitTime
      updatedAt
      updatedBy
      vesselName
      voyage
      routingDetails {
        type
        stops
      }
      versionHistory {
        _id
        changedField
        createdAt
        createdBy
        newValue
        oldValue
        orgUserId
        updatedAt
        updatedBy
      }
    }
  }
`;

export interface UpdateVesselScheduleResponse {
  updatedVesselSchedule: VesselSchedule;
}

export interface UpdateVesselScheduleInput extends Omit<Partial<VesselSchedule>, 'rateStructure'> {
  rateStructure?: {
    fcl?: {
      currencyCode: string;
      data: {
        schema: string;
        values: {
          loadRates: {
            shipmentMethod: string;
            containerTypeAndSize: string;
            unitPrice: number;
            currency: string;
          }[];
        };
      };
      additionalCharges: AdditionalCharge[];
    };

    lcl?: {
      currencyCode: string;
      data: {
        schema: string;
        values: {
          volumeWeightsRates: {
            volumeWeights: {
              title: string;
              unit: string;
              values: number[];
            };
            rates: {
              title: string;
              unit: string;
              values: number[];
            };
          };
        };
      };
      additionalCharges: AdditionalCharge[];
    };
  };
}
