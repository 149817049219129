import { gql } from 'apollo-angular';

export const FIND_LONG_TERM_CONTRACT_BY_ID = gql(`query findLongTermRatesContractById($longTermContractId: String!) {
        findLongTermRatesContractById(longTermContractId: $longTermContractId) {
          _id
          paymentTerm {
            text
          }
        }
      }`);

export interface LongTermRatesContract {
  paymentTerm: SelectedPaymentTerm;
}

export interface FindLongTermRatesContractByIdQueryResponse {
  findLongTermRatesContractById: LongTermRatesContract;
}

export interface SelectedPaymentTerm {
  paymentMethodId: string;
  paymentTermId: string;
  option?: string;
  metaData: Record<string, string>;
  text: string;
}
