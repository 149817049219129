import { Component } from '@angular/core';
import { EnhancedDatagridRateStructureRendererParams } from '@shared/types/enhanced-grid-types/enhanced-grid-components.type';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'hmt-enhanced-datagrid-rate-structure-renderer',
  templateUrl: './enhanced-datagrid-rate-structure-renderer.component.html',
  styleUrls: ['./enhanced-datagrid-rate-structure-renderer.component.scss'],
})
export class EnhancedDatagridRateStructureRendererComponent implements ICellRendererAngularComp {
  private params!: EnhancedDatagridRateStructureRendererParams;
  value!: EnhancedDatagridRateStructureRendererParams['value'];

  agInit(params: EnhancedDatagridRateStructureRendererParams): void {
    this.params = params;
    this.setValue(params);
  }

  refresh(params: EnhancedDatagridRateStructureRendererParams): boolean {
    this.params = params;
    this.setValue(params);
    return true;
  }

  private setValue(params: EnhancedDatagridRateStructureRendererParams) {
    this.value = params.value;
  }

  openDrawer() {
    this.params.openDrawer();
  }
}
