import { Injectable } from '@angular/core';
import { EntityUpdateNotification } from '@core/models/entity-update-notification.model';
import { JobUpdateNotification } from '@core/models/job-updates-notification.model';
import { AuthState } from '@core/store/auth/auth.state';
import { Store } from '@ngxs/store';
import {
  AGGREGATE_FAVORITE_BIDS_QUERY,
  AGGREGATE_LONG_TERM_BIDS_QUERY,
  GET_LONG_TERM_TENDER_BY_ID,
  GET_SPOT_TENDER_BY_ID,
} from '@shared/gql-shared-queries';
import { Apollo, gql } from 'apollo-angular';
import { SetTender } from 'app/modules/current-tender/store/current-tender/current-tender.actions';
import { TenderCommittee } from 'app/modules/tender-wizard/models/tender-committee.model';
import { Observable, map, tap } from 'rxjs';
import { TenderStates } from '../enums/tender-status.enum';
import { TenderTypes } from '../enums/tender-type.enum';
import { TenderModel } from '../models/tender.model';
import { UpdateLongTermTenderSummaryState } from '../store/long-term-tender-panel/long-term-tender-panel.actions';
import { UpdateTenderStatusAction } from '../store/procurement/procurement.actions';

@Injectable({ providedIn: 'root' })
export class TenderService {
  constructor(
    private readonly apollo: Apollo,
    private readonly store$: Store
  ) {}

  handleTenderUpdate(
    tenderNotification: JobUpdateNotification | EntityUpdateNotification,
    currentTenderId: string
  ): void {
    if (tenderNotification.entityType !== 'SPOT_TENDER' && tenderNotification.entityType !== 'LONG_TERM_TENDER') {
      return;
    }

    if (currentTenderId !== tenderNotification.data?.['data']?.['_id']) return;

    this.store$.dispatch([
      new SetTender(tenderNotification.data?.['data'] as TenderModel, 'jobUpdate'),
      new UpdateTenderStatusAction(tenderNotification.data?.['data']?.['tenderStatus'] as TenderStates),
      new UpdateLongTermTenderSummaryState(
        tenderNotification.data?.['data']?.['_id'] as string,
        tenderNotification.data?.['data']?.['tenderStatus'] as TenderStates
      ),
    ]);
  }

  loadTenderById(tenderId: string, tenderType?: TenderTypes) {
    let query = GET_SPOT_TENDER_BY_ID;
    if (tenderType && tenderType === TenderTypes.LONG_TERM_TENDER) {
      query = GET_LONG_TERM_TENDER_BY_ID;
    }

    return this.apollo
      .query<TenderModel>({
        query,
        variables: { tenderId },
      })
      .pipe(
        map(result => result.data['findTenderById']),
        tap(tender => {
          // TODO: This check needs to be done on the backend
          const isCurrentUserInTenderCommittee = this.isCurrentUserInTenderCommittee(tender.tenderCommittee);
          const updatedTender = { ...tender, canAccessTender: isCurrentUserInTenderCommittee, loading: false };
          this.store$.dispatch([
            new SetTender(updatedTender, 'gql'),
            new UpdateTenderStatusAction(tender.tenderStatus),
          ]);
        })
      );
  }

  evaluateTender(tenderId: string, tenderType: TenderTypes): Observable<boolean> {
    return this.updateTenderStatus(tenderId, TenderStates.EVALUATING).pipe(
      tap(() => this.loadTenderById(tenderId, tenderType).subscribe()),
      map(result => result)
    );
  }

  terminateTender(tenderId: string): Observable<boolean> {
    return this.updateTenderStatus(tenderId, TenderStates.TERMINATED);
  }

  updateTenderStatus(tenderId: string, tenderStatus: TenderStates): Observable<boolean> {
    const updateTenderStatusMutation = gql`
      mutation updateTenderStatus($tenderId: String!, $tenderStatus: String!) {
        updateTenderStatus(tenderId: $tenderId, tenderStatus: $tenderStatus)
      }
    `;
    return this.apollo
      .mutate<boolean>({
        mutation: updateTenderStatusMutation,
        variables: { tenderId, tenderStatus: tenderStatus },
      })
      .pipe(map(response => response.data['updateTenderStatus']));
  }

  markAsFavorite(bidId: string, bidOptionId: string) {
    const markAsFavoriteMutation = gql`
      mutation markAsFavorite($bidId: String!, $bidOptionId: String!) {
        markAsFavorite(bidId: $bidId, bidOptionId: $bidOptionId)
      }
    `;
  }

  rejectAllBids(tenderId: string, rejectionReason: string): Observable<boolean> {
    const rejectAllBidsMutation = gql`
      mutation rejectAllBids($tenderId: String!, $reason: String!) {
        rejectAllBids(tenderId: $tenderId, reason: $reason)
      }
    `;
    return this.apollo
      .mutate<boolean>({
        mutation: rejectAllBidsMutation,
        variables: { tenderId, reason: rejectionReason },
      })
      .pipe(map(response => response.data['rejectAllBids']));
  }

  aggregateFavoriteBids(tenderId: string): Observable<string> {
    return this.apollo
      .query({
        query: AGGREGATE_FAVORITE_BIDS_QUERY,
        variables: { tenderId },
      })
      .pipe(map(response => response.data['aggregateFavoriteBids'].fileUrl.presigned_url));
  }

  aggregateLongTermBids(tenderId: string): Observable<string> {
    return this.apollo
      .query({
        query: AGGREGATE_LONG_TERM_BIDS_QUERY,
        variables: { tenderId },
      })
      .pipe(map(response => response.data['aggregateLongTermBids']?.fileUrl?.presigned_url));
  }

  private isCurrentUserInTenderCommittee(committee: TenderCommittee): boolean {
    const currentUserId = this.store$.selectSnapshot(AuthState.getOrgUserId);

    if (committee.committeeLead._id === currentUserId) {
      return true;
    }

    const allCommitteeMembers = [
      ...committee.committeeReviewers,
      ...committee.financialExperts,
      ...committee.logisticsExperts,
    ];

    const isCurrentUserInCommittee = allCommitteeMembers.find(member => member._id === currentUserId);
    return !!isCurrentUserInCommittee;
  }
}
