export enum LocationType {
  WAREHOUSE = 'WAREHOUSE',
  PORT = 'PORT',
  SEAPORT = 'SEAPORT',
  AIRPORT = 'AIRPORT',
  BOI = 'BOI',
  CONTAINER_YARD = 'CONTAINER_YARD',
  EXTERNAL = 'EXTERNAL',
  THIRD_PARTY = 'THIRD_PARTY',
  CIA = 'CIA',
  FUMIGATION = 'FUMIGATION',
  STOP_OVER = 'STOP_OVER',
  IN_TRANSIT = 'IN_TRANSIT',
  ORG_PARTNER_LOCATIONS = 'ORG_PARTNER_LOCATIONS',
  FUEL_PUMP = 'FUEL_PUMP',
  UNPLANNED_STOP = 'UNPLANNED_STOP',
  CUSTOMS = 'CUSTOMS',
  GATE = 'GATE',
  FUMIGATION_CENTER = 'FUMIGATION_CENTER',
  FACILITY = 'FACILITY',
  BUILDING = 'BUILDING',
  OFFICE = 'OFFICE',
  GARAGE = 'GARAGE',
  CARGO_VERIFICATION_CENTER = 'CARGO_VERIFICATION_CENTER',
  CUSTOMS_VERIFICATION_CENTER = 'CUSTOMS_VERIFICATION_CENTER',
  REST_AREA = 'REST_AREA',
  TERMINAL = 'TERMINAL',
  YARD = 'YARD',
  VEHICLE_PARK = 'VEHICLE_PARK',
  FEEDER_BIRTH = 'FEEDER_BIRTH',
  CONTAINER_BIRTH = 'CONTAINER_BIRTH',
  BAY = 'BAY',
  PUMPING_STATION = 'PUMPING_STATION',
  FUMIGATION_LOCATION = 'FUMIGATION_LOCATION',
  EXPORT_PROCESSING_ZONE = 'EXPORT_PROCESSING_ZONE',
}
